import { httpsCallable } from '@firebase/functions'
import * as Sentry from '@sentry/vue'
import type { HttpsError } from '@firebase/functions-types'

export async function callFunction<T>(
  functionName: string,
  data?: any
): Promise<T> {
  const { $functions } = useNuxtApp()
  try {
    return (await httpsCallable<unknown, T>(
      $functions,
      functionName
    )(data)) as T
  } catch (err) {
    let error: ApiError
    const firebaseError = err as HttpsError
    try {
      error = JSON.parse(firebaseError.message)
    } catch {
      const details = firebaseError.message
      error = { details: details }
    }

    if (error.status != 404) {
      error.message = `${functionName}: ` + (error.message ?? 'unknown')
      const event: Partial<ApiError> = { ...error }
      delete event.details
      delete event.status
      Sentry.captureEvent(event, {
        captureContext: {
          extra: {
            request: data,
            response: error,
          },
        },
      })
    }
    throw error
  }
}
