import { usersRepository } from '~/utils/usersRepository'
import type { UserDetail } from '~/types/user'
import { getAuth } from 'firebase/auth'
import type { Cardholder } from '~/types/cardholder'
import type { ApiError } from '~/types/api'

export function useUser() {
  const user: Ref<UserDetail | null> = useState('user', () => null)

  const repo = usersRepository()
  const auth = getAuth()
  const localePath = useLocalePath()
  const cardHolderStore = useCardholder()
  const { addErrorNotification } = useNotifications()
  async function updateUser() {
    if (!auth.currentUser) {
      user.value = null
    }
    let error: ApiError | null = null
    const fetchedUser = ref<null | UserDetail>(null)
    try {
      fetchedUser.value = (await repo.getSelf()).data
    } catch (err) {
      error = err as ApiError
      if (error && error.status === 404) {
        user.value = null
        return
      } else if (error && error.status !== 429) {
        // TODO issue with translation, needs to figure out in the future
        addErrorNotification('Nastala neočekávaná chyba.')
      } else {
        // TODO issue with translation, needs to figure out in the future
        addErrorNotification(
          'Byli jste odhlášeni z důvodu přetížení aplikace, zkuste to prosím později.'
        )
      }
      auth.signOut()
      return
    }
    user.value = fetchedUser.value ?? null
    if (!user.value!.admin) {
      const storedCardholder = cardHolderStore.getCardholder(user.value!.id)
      let newCardholder: Cardholder | undefined = user.value!.card_holders?.[0]
      // Sets cardHolder based on stored cardHolder.id in case of a change in the stored cardHolder
      if (storedCardholder) {
        newCardholder =
          user.value!.card_holders!.find((cardholder: Cardholder) => {
            return (
              cardholder.id === storedCardholder.id ||
              (Array.isArray(cardholder.held_cards) &&
                cardholder.held_cards.some(
                  (card) => card === storedCardholder.barcode
                ))
            )
          }) ?? user.value!.card_holders?.[0]
      }
      if (newCardholder) {
        cardHolderStore.setCardholder(newCardholder)
      }
    }
  }

  function routeToUserRoot() {
    if (user.value?.admin) {
      navigateTo(localePath({ name: 'admin-users' }))
    } else if (user.value) {
      navigateTo('/')
    } else {
      navigateTo(localePath({ name: 'login' }))
    }
  }

  function checkUserRoute(route: string, isMiddleware?: boolean) {
    // Check for backlink
    let rememberUrl = ''
    try {
      rememberUrl = localStorage.getItem('remember-url') ?? ''
    } catch (err) {
      console.debug('localStorage access denied')
    }

    if (!auth.currentUser) {
      return localePath({ name: 'login' })
    } else if (user.value && user.value.admin) {
      if (route.includes('admin') || route === '/docs') {
        if (
          rememberUrl &&
          (rememberUrl.includes('admin') || route === '/docs')
        ) {
          localStorage.setItem('remember-url', '')
          return rememberUrl
        }
        return route
      } else {
        return localePath({ name: 'admin-users' })
      }
    } else if (!auth.currentUser.emailVerified) {
      return localePath({ name: 'register-verification' })
    } else if (
      !user.value ||
      !user.value?.firebase_uid ||
      user.value.card_holders!.length === 0
    ) {
      return localePath({ name: 'register' })
    } else if (
      route.includes('admin') ||
      (route === localePath({ name: 'login' }) && !isMiddleware)
    ) {
      if (!rememberUrl) {
        return '/'
      } else {
        // delete backLink
        localStorage.setItem('remember-url', '')
        return rememberUrl
      }
    }
    return route
  }

  return {
    user,
    updateUser,
    checkUserRoute,
    routeToUserRoot,
  }
}
