<script setup lang="ts">
const { notifications } = useNotifications()
</script>

<template>
  <div class="fixed bottom-0 right-0 z-50 w-full sm:w-auto">
    <TransitionGroup
      move-class="transition-all duration-100"
      enter-active-class="transition-all duration-100"
      leave-active-class="transition-all duration-100 absolute -z-10"
      enter-from-class="opacity-0 translate-y-20"
      leave-to-class="opacity-0"
      tag="div"
      class="absolute bottom-0 right-0 w-screen px-2 sm:bottom-3 sm:right-5 sm:max-w-[400px] sm:px-0"
    >
      <UiNotificationsNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        class="mb-2"
      />
    </TransitionGroup>
  </div>
</template>
