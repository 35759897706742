export default defineNuxtRouteMiddleware((to, from) => {
  const nuxtApp = useNuxtApp()
  if (import.meta.client && nuxtApp.isHydrating) {
    return
  }

  const content = document.getElementById('content')
  if (content) {
    content.scroll(0, 0)
  }
  const userStore = useUser()
  const finalRoute = userStore.checkUserRoute(to.fullPath, true)
  if (
    from.fullPath === finalRoute ||
    (userStore.user.value &&
      userStore.user.value.admin !== finalRoute.includes('admin'))
  ) {
    return abortNavigation()
  } else if (finalRoute !== to.path) {
    return navigateTo(finalRoute)
  }
})
