<script setup lang="ts">
import type { Placement, Strategy } from '@floating-ui/vue'

const props = withDefaults(
  defineProps<{
    label: string
    placement?: Placement
    strategy?: Strategy
    offsetSize?: number
    class?: string
  }>(),
  {
    placement: 'bottom',
    strategy: 'fixed',
    offsetSize: 7,
    class: '',
  }
)

const {
  anchor,
  floating: tooltip,
  floatingStyles,
} = useUiFloating({
  placement: props.placement,
  offsetSize: props.offsetSize,
  strategy: props.strategy,
})

const open = ref(false)

function onMouseEnter() {
  open.value = true
}

function onMouseLeave() {
  open.value = false
}

onDeactivated(() => {
  open.value = false
})
</script>

<template>
  <div>
    <slot v-if="label.length === 0" />
    <div
      v-else
      ref="anchor"
      class="relative"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <slot />
    </div>
    <div
      v-if="open"
      ref="tooltip"
      :style="floatingStyles"
      :class="cn('z-10 max-w-md px-2', props.class)"
    >
      <Transition
        appear
        enter-active-class="motion-safe:transition duration-300"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="motion-safe:transition duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <div
          class="left-0 top-0 max-w-[100vw] text-wrap rounded-lg bg-primary px-2 py-1 text-sm !text-black"
        >
          {{ props.label }}
        </div>
      </Transition>
    </div>
  </div>
</template>
