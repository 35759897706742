<script setup lang="ts">
const props = defineProps<{
  notification: Notification
}>()

const { removeNotification } = useNotifications()

const interval = props.notification.time || 5000

let remaining: ComputedRef<number>
let pause: () => void
let resume: () => void

resume = () => {}
pause = () => {}

if (props.notification.time) {
  const {
    remaining: r,
    pause: p,
    resume: rs,
  } = useTimer(() => {
    removeNotification(props.notification.id)
  }, interval)

  remaining = r
  pause = p
  resume = rs
}
</script>

<template>
  <UiCard
    class="w-full min-w-72 bg-black p-3 text-white md:w-auto"
    @mouseover="pause()"
    @mouseleave="resume()"
  >
    <div class="flex items-center justify-between gap-4">
      <div class="flex w-full flex-col">
        <p class="font-semibold leading-tight">
          {{ props.notification.title }}
        </p>
        <p
          v-if="props.notification.description"
          class="text-current/60 text-sm"
        >
          {{ props.notification.description }}
        </p>
      </div>

      <UiButton
        size="sm"
        class="flex h-max items-start justify-start p-1"
        type="accent"
        @click="removeNotification(props.notification.id)"
      >
        <Icon name="fa6-solid:xmark" class="h-6 w-6 sm:h-4 sm:w-4" />
      </UiButton>
    </div>
    <span
      v-if="props.notification.time || props.notification.color"
      id="timeoutBar"
      :style="{
        width: (remaining / interval) * 100 + '%',
        backgroundColor: props.notification.color,
      }"
      class="mt-2 block h-1 w-full rounded-full bg-black"
    />
  </UiCard>
</template>
