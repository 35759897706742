<script setup lang="ts"></script>

<template>
  <div
    class="flex h-screen w-screen flex-col items-center justify-center bg-black text-3xl text-primary"
  >
    <img
      class="logo h-[104px] w-[550]"
      src="/img/png/refresh-bistro-logo.png"
    />
    <span class="mt-32 uppercase">{{ $t('login.loading') }}...</span>
  </div>
</template>

<style scoped>
.logo {
  max-width: 90%;
}
</style>
