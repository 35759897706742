<script lang="ts" setup>
const props = defineProps<{
  class?: string
}>()
</script>

<template>
  <div
    :class="
      cn(
        'mb-8 border-[2px] border-primary bg-white bg-opacity-100 p-3 shadow-xl lg:p-6 ',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>
