import type { Cardholder } from '~/types/cardholder'

export function useCardholder() {
  const cardholder: Ref<Cardholder | null> = useState('cardholder', () => null)
  function getLocalStorageKey(userId: number) {
    return 'DX:cardholder:' + userId + ':selected-cardholder:v2'
  }

  function getCardholder(userId: number) {
    if (cardholder.value) {
      return cardholder.value
    }
    const storedCardholder = localStorage.getItem(getLocalStorageKey(userId))
    if (storedCardholder) {
      try {
        return JSON.parse(storedCardholder) as Cardholder
      } catch (err) {
        console.debug('failed JSON parse', storedCardholder)
        return null
      }
    } else {
      return null
    }
  }

  function setCardholder(newCardholder: Cardholder) {
    cardholder.value = newCardholder
    localStorage.setItem(
      getLocalStorageKey(newCardholder.user_id),
      JSON.stringify(newCardholder)
    )
  }
  return {
    cardholder,
    getCardholder,
    setCardholder,
  }
}
