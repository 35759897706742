import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import dialogPolyfill from 'dialog-polyfill'
import 'material-design-lite/src/mdlComponentHandler'
import 'material-design-lite/src/button/button'
import 'material-design-lite/src/progress/progress'
import 'material-design-lite/src/spinner/spinner'
import 'material-design-lite/src/textfield/textfield'
;(function () {
  ;(function () {
    var l,
      aa =
        'function' == typeof Object.create
          ? Object.create
          : function (a) {
              function b() {}
              b.prototype = a
              return new b()
            },
      ba
    if ('function' == typeof Object.setPrototypeOf) ba = Object.setPrototypeOf
    else {
      var ca
      a: {
        var da = { xb: !0 },
          ea = {}
        try {
          ea.__proto__ = da
          ca = ea.xb
          break a
        } catch (a) {}
        ca = !1
      }
      ba = ca
        ? function (a, b) {
            a.__proto__ = b
            if (a.__proto__ !== b) throw new TypeError(a + ' is not extensible')
            return a
          }
        : null
    }
    var fa = ba
    function m(a, b) {
      a.prototype = aa(b.prototype)
      a.prototype.constructor = a
      if (fa) fa(a, b)
      else
        for (var c in b)
          if ('prototype' != c)
            if (Object.defineProperties) {
              var d = Object.getOwnPropertyDescriptor(b, c)
              d && Object.defineProperty(a, c, d)
            } else a[c] = b[c]
      a.K = b.prototype
    }
    var ha =
        'function' == typeof Object.defineProperties
          ? Object.defineProperty
          : function (a, b, c) {
              a != Array.prototype && a != Object.prototype && (a[b] = c.value)
            },
      ia =
        'undefined' != typeof window && window === this
          ? this
          : 'undefined' != typeof global && null != global
            ? global
            : this
    function ja(a, b) {
      if (b) {
        var c = ia
        a = a.split('.')
        for (var d = 0; d < a.length - 1; d++) {
          var e = a[d]
          e in c || (c[e] = {})
          c = c[e]
        }
        a = a[a.length - 1]
        d = c[a]
        b = b(d)
        b != d &&
          null != b &&
          ha(c, a, { configurable: !0, writable: !0, value: b })
      }
    }
    ja('Object.is', function (a) {
      return a
        ? a
        : function (b, c) {
            return b === c ? 0 !== b || 1 / b === 1 / c : b !== b && c !== c
          }
    })
    ja('Array.prototype.includes', function (a) {
      return a
        ? a
        : function (b, c) {
            var d = this
            d instanceof String && (d = String(d))
            var e = d.length
            c = c || 0
            for (0 > c && (c = Math.max(c + e, 0)); c < e; c++) {
              var f = d[c]
              if (f === b || Object.is(f, b)) return !0
            }
            return !1
          }
    })
    ja('String.prototype.includes', function (a) {
      return a
        ? a
        : function (b, c) {
            if (null == this)
              throw new TypeError(
                "The 'this' value for String.prototype.includes must not be null or undefined"
              )
            if (b instanceof RegExp)
              throw new TypeError(
                'First argument to String.prototype.includes must not be a regular expression'
              )
            return -1 !== this.indexOf(b, c || 0)
          }
    })
    var n = this
    function ka(a) {
      return void 0 !== a
    }
    function q(a) {
      return 'string' == typeof a
    }
    var la = /^[\w+/_-]+[=]{0,2}$/,
      ma = null
    function na() {}
    function oa(a) {
      a.W = void 0
      a.Xa = function () {
        return a.W ? a.W : (a.W = new a())
      }
    }
    function pa(a) {
      var b = typeof a
      if ('object' == b)
        if (a) {
          if (a instanceof Array) return 'array'
          if (a instanceof Object) return b
          var c = Object.prototype.toString.call(a)
          if ('[object Window]' == c) return 'object'
          if (
            '[object Array]' == c ||
            ('number' == typeof a.length &&
              'undefined' != typeof a.splice &&
              'undefined' != typeof a.propertyIsEnumerable &&
              !a.propertyIsEnumerable('splice'))
          )
            return 'array'
          if (
            '[object Function]' == c ||
            ('undefined' != typeof a.call &&
              'undefined' != typeof a.propertyIsEnumerable &&
              !a.propertyIsEnumerable('call'))
          )
            return 'function'
        } else return 'null'
      else if ('function' == b && 'undefined' == typeof a.call) return 'object'
      return b
    }
    function qa(a) {
      return 'array' == pa(a)
    }
    function ra(a) {
      var b = pa(a)
      return 'array' == b || ('object' == b && 'number' == typeof a.length)
    }
    function sa(a) {
      return 'function' == pa(a)
    }
    function ta(a) {
      var b = typeof a
      return ('object' == b && null != a) || 'function' == b
    }
    var ua = 'closure_uid_' + ((1e9 * Math.random()) >>> 0),
      va = 0
    function wa(a, b, c) {
      return a.call.apply(a.bind, arguments)
    }
    function xa(a, b, c) {
      if (!a) throw Error()
      if (2 < arguments.length) {
        var d = Array.prototype.slice.call(arguments, 2)
        return function () {
          var e = Array.prototype.slice.call(arguments)
          Array.prototype.unshift.apply(e, d)
          return a.apply(b, e)
        }
      }
      return function () {
        return a.apply(b, arguments)
      }
    }
    function r(a, b, c) {
      Function.prototype.bind &&
      -1 != Function.prototype.bind.toString().indexOf('native code')
        ? (r = wa)
        : (r = xa)
      return r.apply(null, arguments)
    }
    function za(a, b) {
      var c = Array.prototype.slice.call(arguments, 1)
      return function () {
        var d = c.slice()
        d.push.apply(d, arguments)
        return a.apply(this, d)
      }
    }
    function u(a, b) {
      for (var c in b) a[c] = b[c]
    }
    var Aa =
      Date.now ||
      function () {
        return +new Date()
      }
    function v(a, b) {
      a = a.split('.')
      var c = n
      a[0] in c ||
        'undefined' == typeof c.execScript ||
        c.execScript('var ' + a[0])
      for (var d; a.length && (d = a.shift()); )
        !a.length && ka(b)
          ? (c[d] = b)
          : c[d] && c[d] !== Object.prototype[d]
            ? (c = c[d])
            : (c = c[d] = {})
    }
    function w(a, b) {
      function c() {}
      c.prototype = b.prototype
      a.K = b.prototype
      a.prototype = new c()
      a.prototype.constructor = a
      a.vc = function (d, e, f) {
        for (
          var g = Array(arguments.length - 2), h = 2;
          h < arguments.length;
          h++
        )
          g[h - 2] = arguments[h]
        return b.prototype[e].apply(d, g)
      }
    }
    function Ba(a) {
      if (Error.captureStackTrace) Error.captureStackTrace(this, Ba)
      else {
        var b = Error().stack
        b && (this.stack = b)
      }
      a && (this.message = String(a))
    }
    w(Ba, Error)
    Ba.prototype.name = 'CustomError'
    var Da
    function Ea(a, b) {
      a = a.split('%s')
      for (var c = '', d = a.length - 1, e = 0; e < d; e++)
        c += a[e] + (e < b.length ? b[e] : '%s')
      Ba.call(this, c + a[d])
    }
    w(Ea, Ba)
    Ea.prototype.name = 'AssertionError'
    function Fa(a, b) {
      throw new Ea(
        'Failure' + (a ? ': ' + a : ''),
        Array.prototype.slice.call(arguments, 1)
      )
    }
    var Ga = Array.prototype.indexOf
        ? function (a, b) {
            return Array.prototype.indexOf.call(a, b, void 0)
          }
        : function (a, b) {
            if (q(a)) return q(b) && 1 == b.length ? a.indexOf(b, 0) : -1
            for (var c = 0; c < a.length; c++)
              if (c in a && a[c] === b) return c
            return -1
          },
      Ha = Array.prototype.forEach
        ? function (a, b, c) {
            Array.prototype.forEach.call(a, b, c)
          }
        : function (a, b, c) {
            for (
              var d = a.length, e = q(a) ? a.split('') : a, f = 0;
              f < d;
              f++
            )
              f in e && b.call(c, e[f], f, a)
          }
    function Ia(a, b) {
      for (var c = q(a) ? a.split('') : a, d = a.length - 1; 0 <= d; --d)
        d in c && b.call(void 0, c[d], d, a)
    }
    var Ja = Array.prototype.filter
        ? function (a, b) {
            return Array.prototype.filter.call(a, b, void 0)
          }
        : function (a, b) {
            for (
              var c = a.length,
                d = [],
                e = 0,
                f = q(a) ? a.split('') : a,
                g = 0;
              g < c;
              g++
            )
              if (g in f) {
                var h = f[g]
                b.call(void 0, h, g, a) && (d[e++] = h)
              }
            return d
          },
      Ka = Array.prototype.map
        ? function (a, b) {
            return Array.prototype.map.call(a, b, void 0)
          }
        : function (a, b) {
            for (
              var c = a.length, d = Array(c), e = q(a) ? a.split('') : a, f = 0;
              f < c;
              f++
            )
              f in e && (d[f] = b.call(void 0, e[f], f, a))
            return d
          },
      La = Array.prototype.some
        ? function (a, b) {
            return Array.prototype.some.call(a, b, void 0)
          }
        : function (a, b) {
            for (
              var c = a.length, d = q(a) ? a.split('') : a, e = 0;
              e < c;
              e++
            )
              if (e in d && b.call(void 0, d[e], e, a)) return !0
            return !1
          }
    function Ma(a, b) {
      return 0 <= Ga(a, b)
    }
    function Na(a, b) {
      b = Ga(a, b)
      var c
      ;(c = 0 <= b) && Oa(a, b)
      return c
    }
    function Oa(a, b) {
      return 1 == Array.prototype.splice.call(a, b, 1).length
    }
    function Pa(a, b) {
      a: {
        for (var c = a.length, d = q(a) ? a.split('') : a, e = 0; e < c; e++)
          if (e in d && b.call(void 0, d[e], e, a)) {
            b = e
            break a
          }
        b = -1
      }
      0 <= b && Oa(a, b)
    }
    function Qa(a, b) {
      var c = 0
      Ia(a, function (d, e) {
        b.call(void 0, d, e, a) && Oa(a, e) && c++
      })
    }
    function Ra(a) {
      return Array.prototype.concat.apply([], arguments)
    }
    function Sa(a) {
      var b = a.length
      if (0 < b) {
        for (var c = Array(b), d = 0; d < b; d++) c[d] = a[d]
        return c
      }
      return []
    }
    function Ta(a, b, c) {
      return 2 >= arguments.length
        ? Array.prototype.slice.call(a, b)
        : Array.prototype.slice.call(a, b, c)
    }
    var Ua = String.prototype.trim
        ? function (a) {
            return a.trim()
          }
        : function (a) {
            return /^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]
          },
      Va = /&/g,
      Wa = /</g,
      Xa = />/g,
      Ya = /"/g,
      Za = /'/g,
      $a = /\x00/g,
      ab = /[\x00&<>"']/
    function bb(a, b) {
      return a < b ? -1 : a > b ? 1 : 0
    }
    function cb(a) {
      ab.test(a) &&
        (-1 != a.indexOf('&') && (a = a.replace(Va, '&amp;')),
        -1 != a.indexOf('<') && (a = a.replace(Wa, '&lt;')),
        -1 != a.indexOf('>') && (a = a.replace(Xa, '&gt;')),
        -1 != a.indexOf('"') && (a = a.replace(Ya, '&quot;')),
        -1 != a.indexOf("'") && (a = a.replace(Za, '&#39;')),
        -1 != a.indexOf('\x00') && (a = a.replace($a, '&#0;')))
      return a
    }
    function db(a, b, c) {
      for (var d in a) b.call(c, a[d], d, a)
    }
    function eb(a) {
      var b = {},
        c
      for (c in a) b[c] = a[c]
      return b
    }
    var fb =
      'constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf'.split(
        ' '
      )
    function gb(a, b) {
      for (var c, d, e = 1; e < arguments.length; e++) {
        d = arguments[e]
        for (c in d) a[c] = d[c]
        for (var f = 0; f < fb.length; f++)
          (c = fb[f]),
            Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c])
      }
    }
    var hb =
      'StopIteration' in n
        ? n.StopIteration
        : { message: 'StopIteration', stack: '' }
    function ib() {}
    ib.prototype.next = function () {
      throw hb
    }
    ib.prototype.ha = function () {
      return this
    }
    function jb(a) {
      if (a instanceof ib) return a
      if ('function' == typeof a.ha) return a.ha(!1)
      if (ra(a)) {
        var b = 0,
          c = new ib()
        c.next = function () {
          for (;;) {
            if (b >= a.length) throw hb
            if (b in a) return a[b++]
            b++
          }
        }
        return c
      }
      throw Error('Not implemented')
    }
    function kb(a, b) {
      if (ra(a))
        try {
          Ha(a, b, void 0)
        } catch (c) {
          if (c !== hb) throw c
        }
      else {
        a = jb(a)
        try {
          for (;;) b.call(void 0, a.next(), void 0, a)
        } catch (c$0) {
          if (c$0 !== hb) throw c$0
        }
      }
    }
    function lb(a) {
      if (ra(a)) return Sa(a)
      a = jb(a)
      var b = []
      kb(a, function (c) {
        b.push(c)
      })
      return b
    }
    function mb(a, b) {
      this.g = {}
      this.a = []
      this.j = this.h = 0
      var c = arguments.length
      if (1 < c) {
        if (c % 2) throw Error('Uneven number of arguments')
        for (var d = 0; d < c; d += 2) this.set(arguments[d], arguments[d + 1])
      } else if (a)
        if (a instanceof mb)
          for (c = a.ja(), d = 0; d < c.length; d++) this.set(c[d], a.get(c[d]))
        else for (d in a) this.set(d, a[d])
    }
    l = mb.prototype
    l.la = function () {
      nb(this)
      for (var a = [], b = 0; b < this.a.length; b++) a.push(this.g[this.a[b]])
      return a
    }
    l.ja = function () {
      nb(this)
      return this.a.concat()
    }
    l.clear = function () {
      this.g = {}
      this.j = this.h = this.a.length = 0
    }
    function nb(a) {
      if (a.h != a.a.length) {
        for (var b = 0, c = 0; b < a.a.length; ) {
          var d = a.a[b]
          ob(a.g, d) && (a.a[c++] = d)
          b++
        }
        a.a.length = c
      }
      if (a.h != a.a.length) {
        var e = {}
        for (c = b = 0; b < a.a.length; )
          (d = a.a[b]), ob(e, d) || ((a.a[c++] = d), (e[d] = 1)), b++
        a.a.length = c
      }
    }
    l.get = function (a, b) {
      return ob(this.g, a) ? this.g[a] : b
    }
    l.set = function (a, b) {
      ob(this.g, a) || (this.h++, this.a.push(a), this.j++)
      this.g[a] = b
    }
    l.forEach = function (a, b) {
      for (var c = this.ja(), d = 0; d < c.length; d++) {
        var e = c[d],
          f = this.get(e)
        a.call(b, f, e, this)
      }
    }
    l.ha = function (a) {
      nb(this)
      var b = 0,
        c = this.j,
        d = this,
        e = new ib()
      e.next = function () {
        if (c != d.j)
          throw Error('The map has changed since the iterator was created')
        if (b >= d.a.length) throw hb
        var f = d.a[b++]
        return a ? f : d.g[f]
      }
      return e
    }
    function ob(a, b) {
      return Object.prototype.hasOwnProperty.call(a, b)
    }
    var pb =
      /^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/
    function qb(a, b) {
      if (a) {
        a = a.split('&')
        for (var c = 0; c < a.length; c++) {
          var d = a[c].indexOf('='),
            e = null
          if (0 <= d) {
            var f = a[c].substring(0, d)
            e = a[c].substring(d + 1)
          } else f = a[c]
          b(f, e ? decodeURIComponent(e.replace(/\+/g, ' ')) : '')
        }
      }
    }
    function rb(a, b, c, d) {
      for (var e = c.length; 0 <= (b = a.indexOf(c, b)) && b < d; ) {
        var f = a.charCodeAt(b - 1)
        if (38 == f || 63 == f)
          if (((f = a.charCodeAt(b + e)), !f || 61 == f || 38 == f || 35 == f))
            return b
        b += e + 1
      }
      return -1
    }
    var sb = /#|$/
    function tb(a, b) {
      var c = a.search(sb),
        d = rb(a, 0, b, c)
      if (0 > d) return null
      var e = a.indexOf('&', d)
      if (0 > e || e > c) e = c
      d += b.length + 1
      return decodeURIComponent(a.substr(d, e - d).replace(/\+/g, ' '))
    }
    var ub = /[?&]($|#)/
    function vb(a, b) {
      this.h = this.A = this.j = ''
      this.C = null
      this.s = this.g = ''
      this.i = !1
      var c
      a instanceof vb
        ? ((this.i = ka(b) ? b : a.i),
          wb(this, a.j),
          (this.A = a.A),
          (this.h = a.h),
          xb(this, a.C),
          (this.g = a.g),
          yb(this, zb(a.a)),
          (this.s = a.s))
        : a && (c = String(a).match(pb))
          ? ((this.i = !!b),
            wb(this, c[1] || '', !0),
            (this.A = Ab(c[2] || '')),
            (this.h = Ab(c[3] || '', !0)),
            xb(this, c[4]),
            (this.g = Ab(c[5] || '', !0)),
            yb(this, c[6] || '', !0),
            (this.s = Ab(c[7] || '')))
          : ((this.i = !!b), (this.a = new Bb(null, this.i)))
    }
    vb.prototype.toString = function () {
      var a = [],
        b = this.j
      b && a.push(Cb(b, Db, !0), ':')
      var c = this.h
      if (c || 'file' == b)
        a.push('//'),
          (b = this.A) && a.push(Cb(b, Db, !0), '@'),
          a.push(
            encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g, '%$1')
          ),
          (c = this.C),
          null != c && a.push(':', String(c))
      if ((c = this.g))
        this.h && '/' != c.charAt(0) && a.push('/'),
          a.push(Cb(c, '/' == c.charAt(0) ? Eb : Fb, !0))
      ;(c = this.a.toString()) && a.push('?', c)
      ;(c = this.s) && a.push('#', Cb(c, Gb))
      return a.join('')
    }
    function wb(a, b, c) {
      a.j = c ? Ab(b, !0) : b
      a.j && (a.j = a.j.replace(/:$/, ''))
    }
    function xb(a, b) {
      if (b) {
        b = Number(b)
        if (isNaN(b) || 0 > b) throw Error('Bad port number ' + b)
        a.C = b
      } else a.C = null
    }
    function yb(a, b, c) {
      b instanceof Bb
        ? ((a.a = b), Hb(a.a, a.i))
        : (c || (b = Cb(b, Ib)), (a.a = new Bb(b, a.i)))
    }
    function Jb(a) {
      return a instanceof vb ? new vb(a) : new vb(a, void 0)
    }
    function Ab(a, b) {
      return a
        ? b
          ? decodeURI(a.replace(/%25/g, '%2525'))
          : decodeURIComponent(a)
        : ''
    }
    function Cb(a, b, c) {
      return q(a)
        ? ((a = encodeURI(a).replace(b, Kb)),
          c && (a = a.replace(/%25([0-9a-fA-F]{2})/g, '%$1')),
          a)
        : null
    }
    function Kb(a) {
      a = a.charCodeAt(0)
      return '%' + ((a >> 4) & 15).toString(16) + (a & 15).toString(16)
    }
    var Db = /[#\/\?@]/g,
      Fb = /[#\?:]/g,
      Eb = /[#\?]/g,
      Ib = /[#\?@]/g,
      Gb = /#/g
    function Bb(a, b) {
      this.g = this.a = null
      this.h = a || null
      this.j = !!b
    }
    function Lb(a) {
      a.a ||
        ((a.a = new mb()),
        (a.g = 0),
        a.h &&
          qb(a.h, function (b, c) {
            a.add(decodeURIComponent(b.replace(/\+/g, ' ')), c)
          }))
    }
    l = Bb.prototype
    l.add = function (a, b) {
      Lb(this)
      this.h = null
      a = Mb(this, a)
      var c = this.a.get(a)
      c || this.a.set(a, (c = []))
      c.push(b)
      this.g += 1
      return this
    }
    function Nb(a, b) {
      Lb(a)
      b = Mb(a, b)
      ob(a.a.g, b) &&
        ((a.h = null),
        (a.g -= a.a.get(b).length),
        (a = a.a),
        ob(a.g, b) &&
          (delete a.g[b], a.h--, a.j++, a.a.length > 2 * a.h && nb(a)))
    }
    l.clear = function () {
      this.a = this.h = null
      this.g = 0
    }
    function Ob(a, b) {
      Lb(a)
      b = Mb(a, b)
      return ob(a.a.g, b)
    }
    l.forEach = function (a, b) {
      Lb(this)
      this.a.forEach(function (c, d) {
        Ha(
          c,
          function (e) {
            a.call(b, e, d, this)
          },
          this
        )
      }, this)
    }
    l.ja = function () {
      Lb(this)
      for (
        var a = this.a.la(), b = this.a.ja(), c = [], d = 0;
        d < b.length;
        d++
      )
        for (var e = a[d], f = 0; f < e.length; f++) c.push(b[d])
      return c
    }
    l.la = function (a) {
      Lb(this)
      var b = []
      if (q(a)) Ob(this, a) && (b = Ra(b, this.a.get(Mb(this, a))))
      else {
        a = this.a.la()
        for (var c = 0; c < a.length; c++) b = Ra(b, a[c])
      }
      return b
    }
    l.set = function (a, b) {
      Lb(this)
      this.h = null
      a = Mb(this, a)
      Ob(this, a) && (this.g -= this.a.get(a).length)
      this.a.set(a, [b])
      this.g += 1
      return this
    }
    l.get = function (a, b) {
      if (!a) return b
      a = this.la(a)
      return 0 < a.length ? String(a[0]) : b
    }
    l.toString = function () {
      if (this.h) return this.h
      if (!this.a) return ''
      for (var a = [], b = this.a.ja(), c = 0; c < b.length; c++) {
        var d = b[c],
          e = encodeURIComponent(String(d))
        d = this.la(d)
        for (var f = 0; f < d.length; f++) {
          var g = e
          '' !== d[f] && (g += '=' + encodeURIComponent(String(d[f])))
          a.push(g)
        }
      }
      return (this.h = a.join('&'))
    }
    function zb(a) {
      var b = new Bb()
      b.h = a.h
      a.a && ((b.a = new mb(a.a)), (b.g = a.g))
      return b
    }
    function Mb(a, b) {
      b = String(b)
      a.j && (b = b.toLowerCase())
      return b
    }
    function Hb(a, b) {
      b &&
        !a.j &&
        (Lb(a),
        (a.h = null),
        a.a.forEach(function (c, d) {
          var e = d.toLowerCase()
          d != e &&
            (Nb(this, d),
            Nb(this, e),
            0 < c.length &&
              ((this.h = null),
              this.a.set(Mb(this, e), Sa(c)),
              (this.g += c.length)))
        }, a))
      a.j = b
    }
    function Pb(a) {
      this.a = Jb(a)
    }
    function Qb(a, b) {
      b ? a.a.a.set(x.Sa, b) : Nb(a.a.a, x.Sa)
    }
    function Rb(a, b) {
      null !== b ? a.a.a.set(x.Qa, b ? '1' : '0') : Nb(a.a.a, x.Qa)
    }
    function Sb(a) {
      return a.a.a.get(x.Pa) || null
    }
    function Tb(a, b) {
      b ? a.a.a.set(x.PROVIDER_ID, b) : Nb(a.a.a, x.PROVIDER_ID)
    }
    Pb.prototype.toString = function () {
      return this.a.toString()
    }
    var x = {
      Pa: 'ui_auid',
      lc: 'apiKey',
      Qa: 'ui_sd',
      ub: 'mode',
      $a: 'oobCode',
      PROVIDER_ID: 'ui_pid',
      Sa: 'ui_sid',
      vb: 'tenantId',
    }
    var Ub
    a: {
      var Vb = n.navigator
      if (Vb) {
        var Wb = Vb.userAgent
        if (Wb) {
          Ub = Wb
          break a
        }
      }
      Ub = ''
    }
    function y(a) {
      return -1 != Ub.indexOf(a)
    }
    function Xb() {
      return (y('Chrome') || y('CriOS')) && !y('Edge')
    }
    function Yb(a) {
      Yb[' '](a)
      return a
    }
    Yb[' '] = na
    function Zb(a, b) {
      var c = $b
      return Object.prototype.hasOwnProperty.call(c, a) ? c[a] : (c[a] = b(a))
    }
    var ac = y('Opera'),
      z = y('Trident') || y('MSIE'),
      bc = y('Edge'),
      cc = bc || z,
      dc =
        y('Gecko') &&
        !(-1 != Ub.toLowerCase().indexOf('webkit') && !y('Edge')) &&
        !(y('Trident') || y('MSIE')) &&
        !y('Edge'),
      ec = -1 != Ub.toLowerCase().indexOf('webkit') && !y('Edge'),
      fc = ec && y('Mobile'),
      gc = y('Macintosh')
    function hc() {
      var a = n.document
      return a ? a.documentMode : void 0
    }
    var ic
    a: {
      var jc = '',
        kc = (function () {
          var a = Ub
          if (dc) return /rv:([^\);]+)(\)|;)/.exec(a)
          if (bc) return /Edge\/([\d\.]+)/.exec(a)
          if (z) return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a)
          if (ec) return /WebKit\/(\S+)/.exec(a)
          if (ac) return /(?:Version)[ \/]?(\S+)/.exec(a)
        })()
      kc && (jc = kc ? kc[1] : '')
      if (z) {
        var lc = hc()
        if (null != lc && lc > parseFloat(jc)) {
          ic = String(lc)
          break a
        }
      }
      ic = jc
    }
    var $b = {}
    function mc(a) {
      return Zb(a, function () {
        for (
          var b = 0,
            c = Ua(String(ic)).split('.'),
            d = Ua(String(a)).split('.'),
            e = Math.max(c.length, d.length),
            f = 0;
          0 == b && f < e;
          f++
        ) {
          var g = c[f] || '',
            h = d[f] || ''
          do {
            g = /(\d*)(\D*)(.*)/.exec(g) || ['', '', '', '']
            h = /(\d*)(\D*)(.*)/.exec(h) || ['', '', '', '']
            if (0 == g[0].length && 0 == h[0].length) break
            b =
              bb(
                0 == g[1].length ? 0 : parseInt(g[1], 10),
                0 == h[1].length ? 0 : parseInt(h[1], 10)
              ) ||
              bb(0 == g[2].length, 0 == h[2].length) ||
              bb(g[2], h[2])
            g = g[3]
            h = h[3]
          } while (0 == b)
        }
        return 0 <= b
      })
    }
    var nc
    var oc = n.document
    nc =
      oc && z
        ? hc() || ('CSS1Compat' == oc.compatMode ? parseInt(ic, 10) : 5)
        : void 0
    function pc(a, b) {
      this.a = (a === qc && b) || ''
      this.g = rc
    }
    pc.prototype.ma = !0
    pc.prototype.ka = function () {
      return this.a
    }
    pc.prototype.toString = function () {
      return 'Const{' + this.a + '}'
    }
    var rc = {},
      qc = {}
    function sc() {
      this.a = ''
      this.h = tc
    }
    sc.prototype.ma = !0
    sc.prototype.ka = function () {
      return this.a.toString()
    }
    sc.prototype.g = function () {
      return 1
    }
    sc.prototype.toString = function () {
      return 'TrustedResourceUrl{' + this.a + '}'
    }
    function uc(a) {
      if (a instanceof sc && a.constructor === sc && a.h === tc) return a.a
      Fa(
        "expected object of type TrustedResourceUrl, got '" +
          a +
          "' of type " +
          pa(a)
      )
      return 'type_error:TrustedResourceUrl'
    }
    function vc() {
      var a = wc
      a instanceof pc && a.constructor === pc && a.g === rc
        ? (a = a.a)
        : (Fa("expected object of type Const, got '" + a + "'"),
          (a = 'type_error:Const'))
      var b = new sc()
      b.a = a
      return b
    }
    var tc = {}
    function xc() {
      this.a = ''
      this.h = yc
    }
    xc.prototype.ma = !0
    xc.prototype.ka = function () {
      return this.a.toString()
    }
    xc.prototype.g = function () {
      return 1
    }
    xc.prototype.toString = function () {
      return 'SafeUrl{' + this.a + '}'
    }
    function zc(a) {
      if (a instanceof xc && a.constructor === xc && a.h === yc) return a.a
      Fa("expected object of type SafeUrl, got '" + a + "' of type " + pa(a))
      return 'type_error:SafeUrl'
    }
    var Ac = /^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i
    function Bc(a) {
      if (a instanceof xc) return a
      a = 'object' == typeof a && a.ma ? a.ka() : String(a)
      Ac.test(a) || (a = 'about:invalid#zClosurez')
      return Cc(a)
    }
    function Dc(a) {
      if (a instanceof xc) return a
      a = 'object' == typeof a && a.ma ? a.ka() : String(a)
      Ac.test(a) || (a = 'about:invalid#zClosurez')
      return Cc(a)
    }
    var yc = {}
    function Cc(a) {
      var b = new xc()
      b.a = a
      return b
    }
    Cc('about:blank')
    function Ec() {
      this.a = ''
      this.g = Fc
    }
    Ec.prototype.ma = !0
    var Fc = {}
    Ec.prototype.ka = function () {
      return this.a
    }
    Ec.prototype.toString = function () {
      return 'SafeStyle{' + this.a + '}'
    }
    function Gc() {
      this.a = ''
      this.j = Hc
      this.h = null
    }
    Gc.prototype.g = function () {
      return this.h
    }
    Gc.prototype.ma = !0
    Gc.prototype.ka = function () {
      return this.a.toString()
    }
    Gc.prototype.toString = function () {
      return 'SafeHtml{' + this.a + '}'
    }
    function Ic(a) {
      if (a instanceof Gc && a.constructor === Gc && a.j === Hc) return a.a
      Fa("expected object of type SafeHtml, got '" + a + "' of type " + pa(a))
      return 'type_error:SafeHtml'
    }
    var Hc = {}
    function Jc(a, b) {
      var c = new Gc()
      c.a = a
      c.h = b
      return c
    }
    Jc('<!DOCTYPE html>', 0)
    var Kc = Jc('', 0)
    Jc('<br>', 0)
    var Lc = (function (a) {
      var b = !1,
        c
      return function () {
        b || ((c = a()), (b = !0))
        return c
      }
    })(function () {
      if ('undefined' === typeof document) return !1
      var a = document.createElement('div'),
        b = document.createElement('div')
      b.appendChild(document.createElement('div'))
      a.appendChild(b)
      if (!a.firstChild) return !1
      b = a.firstChild.firstChild
      a.innerHTML = Ic(Kc)
      return !b.parentElement
    })
    function Mc(a, b) {
      a.src = uc(b)
      if (null === ma)
        b: {
          b = n.document
          if (
            (b = b.querySelector && b.querySelector('script[nonce]')) &&
            (b = b.nonce || b.getAttribute('nonce')) &&
            la.test(b)
          ) {
            ma = b
            break b
          }
          ma = ''
        }
      b = ma
      b && a.setAttribute('nonce', b)
    }
    function Nc(a, b) {
      b = b instanceof xc ? b : Dc(b)
      a.assign(zc(b))
    }
    function Oc(a, b) {
      this.a = ka(a) ? a : 0
      this.g = ka(b) ? b : 0
    }
    Oc.prototype.toString = function () {
      return '(' + this.a + ', ' + this.g + ')'
    }
    Oc.prototype.ceil = function () {
      this.a = Math.ceil(this.a)
      this.g = Math.ceil(this.g)
      return this
    }
    Oc.prototype.floor = function () {
      this.a = Math.floor(this.a)
      this.g = Math.floor(this.g)
      return this
    }
    Oc.prototype.round = function () {
      this.a = Math.round(this.a)
      this.g = Math.round(this.g)
      return this
    }
    function Pc(a, b) {
      this.width = a
      this.height = b
    }
    l = Pc.prototype
    l.toString = function () {
      return '(' + this.width + ' x ' + this.height + ')'
    }
    l.aspectRatio = function () {
      return this.width / this.height
    }
    l.ceil = function () {
      this.width = Math.ceil(this.width)
      this.height = Math.ceil(this.height)
      return this
    }
    l.floor = function () {
      this.width = Math.floor(this.width)
      this.height = Math.floor(this.height)
      return this
    }
    l.round = function () {
      this.width = Math.round(this.width)
      this.height = Math.round(this.height)
      return this
    }
    function Qc(a) {
      return a ? new Rc(Sc(a)) : Da || (Da = new Rc())
    }
    function Tc(a, b) {
      var c = b || document
      return c.querySelectorAll && c.querySelector
        ? c.querySelectorAll('.' + a)
        : Uc(document, a, b)
    }
    function Vc(a, b) {
      var c = b || document
      if (c.getElementsByClassName) a = c.getElementsByClassName(a)[0]
      else {
        c = document
        var d = b || c
        a =
          d.querySelectorAll && d.querySelector && a
            ? d.querySelector(a ? '.' + a : '')
            : Uc(c, a, b)[0] || null
      }
      return a || null
    }
    function Uc(a, b, c) {
      var d
      a = c || a
      if (a.querySelectorAll && a.querySelector && b)
        return a.querySelectorAll(b ? '.' + b : '')
      if (b && a.getElementsByClassName) {
        var e = a.getElementsByClassName(b)
        return e
      }
      e = a.getElementsByTagName('*')
      if (b) {
        var f = {}
        for (c = d = 0; (a = e[c]); c++) {
          var g = a.className
          'function' == typeof g.split && Ma(g.split(/\s+/), b) && (f[d++] = a)
        }
        f.length = d
        return f
      }
      return e
    }
    function Wc(a, b) {
      db(b, function (c, d) {
        c && 'object' == typeof c && c.ma && (c = c.ka())
        'style' == d
          ? (a.style.cssText = c)
          : 'class' == d
            ? (a.className = c)
            : 'for' == d
              ? (a.htmlFor = c)
              : Xc.hasOwnProperty(d)
                ? a.setAttribute(Xc[d], c)
                : 0 == d.lastIndexOf('aria-', 0) ||
                    0 == d.lastIndexOf('data-', 0)
                  ? a.setAttribute(d, c)
                  : (a[d] = c)
      })
    }
    var Xc = {
      cellpadding: 'cellPadding',
      cellspacing: 'cellSpacing',
      colspan: 'colSpan',
      frameborder: 'frameBorder',
      height: 'height',
      maxlength: 'maxLength',
      nonce: 'nonce',
      role: 'role',
      rowspan: 'rowSpan',
      type: 'type',
      usemap: 'useMap',
      valign: 'vAlign',
      width: 'width',
    }
    function Yc(a) {
      return a.scrollingElement
        ? a.scrollingElement
        : ec || 'CSS1Compat' != a.compatMode
          ? a.body || a.documentElement
          : a.documentElement
    }
    function Zc(a) {
      a && a.parentNode && a.parentNode.removeChild(a)
    }
    function Sc(a) {
      return 9 == a.nodeType ? a : a.ownerDocument || a.document
    }
    function $c(a, b) {
      if ('textContent' in a) a.textContent = b
      else if (3 == a.nodeType) a.data = String(b)
      else if (a.firstChild && 3 == a.firstChild.nodeType) {
        for (; a.lastChild != a.firstChild; ) a.removeChild(a.lastChild)
        a.firstChild.data = String(b)
      } else {
        for (var c; (c = a.firstChild); ) a.removeChild(c)
        a.appendChild(Sc(a).createTextNode(String(b)))
      }
    }
    function ad(a, b) {
      return b
        ? bd(a, function (c) {
            return !b || (q(c.className) && Ma(c.className.split(/\s+/), b))
          })
        : null
    }
    function bd(a, b) {
      for (var c = 0; a; ) {
        if (b(a)) return a
        a = a.parentNode
        c++
      }
      return null
    }
    function Rc(a) {
      this.a = a || n.document || document
    }
    Rc.prototype.N = function () {
      return q(void 0) ? this.a.getElementById(void 0) : void 0
    }
    var cd = { Fc: !0 },
      dd = { Hc: !0 },
      ed = { Ec: !0 },
      fd = { Gc: !0 }
    function gd() {
      throw Error('Do not instantiate directly')
    }
    gd.prototype.va = null
    gd.prototype.toString = function () {
      return this.content
    }
    function hd(a, b, c, d) {
      a = a(b || id, void 0, c)
      d = (d || Qc()).a.createElement('DIV')
      a = jd(a)
      a.match(kd)
      a = Jc(a, null)
      if (Lc()) for (; d.lastChild; ) d.removeChild(d.lastChild)
      d.innerHTML = Ic(a)
      1 == d.childNodes.length &&
        ((a = d.firstChild), 1 == a.nodeType && (d = a))
      return d
    }
    function jd(a) {
      if (!ta(a)) return cb(String(a))
      if (a instanceof gd) {
        if (a.fa === cd) return a.content
        if (a.fa === fd) return cb(a.content)
      }
      Fa('Soy template output is unsafe for use as HTML: ' + a)
      return 'zSoyz'
    }
    var kd =
        /^<(body|caption|col|colgroup|head|html|tr|td|th|tbody|thead|tfoot)>/i,
      id = {}
    function nd(a) {
      if (null != a)
        switch (a.va) {
          case 1:
            return 1
          case -1:
            return -1
          case 0:
            return 0
        }
      return null
    }
    function od() {
      gd.call(this)
    }
    w(od, gd)
    od.prototype.fa = cd
    function A(a) {
      return null != a && a.fa === cd
        ? a
        : a instanceof Gc
          ? B(Ic(a).toString(), a.g())
          : B(cb(String(String(a))), nd(a))
    }
    function pd() {
      gd.call(this)
    }
    w(pd, gd)
    pd.prototype.fa = dd
    pd.prototype.va = 1
    function qd(a, b) {
      this.content = String(a)
      this.va = null != b ? b : null
    }
    w(qd, gd)
    qd.prototype.fa = fd
    function C(a) {
      return new qd(a, void 0)
    }
    var B = (function (a) {
        function b(c) {
          this.content = c
        }
        b.prototype = a.prototype
        return function (c, d) {
          c = new b(String(c))
          void 0 !== d && (c.va = d)
          return c
        }
      })(od),
      rd = (function (a) {
        function b(c) {
          this.content = c
        }
        b.prototype = a.prototype
        return function (c) {
          return new b(String(c))
        }
      })(pd)
    function sd(a) {
      function b() {}
      var c = { label: D('Nov\u00e9 heslo') }
      b.prototype = a
      a = new b()
      for (var d in c) a[d] = c[d]
      return a
    }
    function D(a) {
      return (a = String(a)) ? new qd(a, void 0) : ''
    }
    var td = (function (a) {
      function b(c) {
        this.content = c
      }
      b.prototype = a.prototype
      return function (c, d) {
        c = String(c)
        if (!c) return ''
        c = new b(c)
        void 0 !== d && (c.va = d)
        return c
      }
    })(od)
    function ud(a) {
      return null != a && a.fa === cd
        ? String(String(a.content).replace(vd, '').replace(wd, '&lt;')).replace(
            xd,
            yd
          )
        : cb(String(a))
    }
    function zd(a) {
      null != a && a.fa === dd
        ? (a = String(a).replace(Ad, Bd))
        : a instanceof xc
          ? (a = String(zc(a).toString()).replace(Ad, Bd))
          : ((a = String(a)),
            Cd.test(a)
              ? (a = a.replace(Ad, Bd))
              : (Fa('Bad value `%s` for |filterNormalizeUri', [a]),
                (a = '#zSoyz')))
      return a
    }
    function Dd(a) {
      null != a && a.fa === ed
        ? (a = a.content)
        : null == a
          ? (a = '')
          : a instanceof Ec
            ? a instanceof Ec && a.constructor === Ec && a.g === Fc
              ? (a = a.a)
              : (Fa(
                  "expected object of type SafeStyle, got '" +
                    a +
                    "' of type " +
                    pa(a)
                ),
                (a = 'type_error:SafeStyle'))
            : ((a = String(a)),
              Ed.test(a) ||
                (Fa('Bad value `%s` for |filterCssValue', [a]), (a = 'zSoyz')))
      return a
    }
    var Fd = {
      '\x00': '&#0;',
      '\t': '&#9;',
      '\n': '&#10;',
      '\x0B': '&#11;',
      '\f': '&#12;',
      '\r': '&#13;',
      ' ': '&#32;',
      '"': '&quot;',
      '&': '&amp;',
      "'": '&#39;',
      '-': '&#45;',
      '/': '&#47;',
      '<': '&lt;',
      '=': '&#61;',
      '>': '&gt;',
      '`': '&#96;',
      '\u0085': '&#133;',
      '\u00a0': '&#160;',
      '\u2028': '&#8232;',
      '\u2029': '&#8233;',
    }
    function yd(a) {
      return Fd[a]
    }
    var Gd = {
      '\x00': '%00',
      '\u0001': '%01',
      '\u0002': '%02',
      '\u0003': '%03',
      '\u0004': '%04',
      '\u0005': '%05',
      '\u0006': '%06',
      '\u0007': '%07',
      '\b': '%08',
      '\t': '%09',
      '\n': '%0A',
      '\x0B': '%0B',
      '\f': '%0C',
      '\r': '%0D',
      '\u000e': '%0E',
      '\u000f': '%0F',
      '\u0010': '%10',
      '\u0011': '%11',
      '\u0012': '%12',
      '\u0013': '%13',
      '\u0014': '%14',
      '\u0015': '%15',
      '\u0016': '%16',
      '\u0017': '%17',
      '\u0018': '%18',
      '\u0019': '%19',
      '\u001a': '%1A',
      '\u001b': '%1B',
      '\u001c': '%1C',
      '\u001d': '%1D',
      '\u001e': '%1E',
      '\u001f': '%1F',
      ' ': '%20',
      '"': '%22',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '<': '%3C',
      '>': '%3E',
      '\\': '%5C',
      '{': '%7B',
      '}': '%7D',
      '\u007f': '%7F',
      '\u0085': '%C2%85',
      '\u00a0': '%C2%A0',
      '\u2028': '%E2%80%A8',
      '\u2029': '%E2%80%A9',
      '\uff01': '%EF%BC%81',
      '\uff03': '%EF%BC%83',
      '\uff04': '%EF%BC%84',
      '\uff06': '%EF%BC%86',
      '\uff07': '%EF%BC%87',
      '\uff08': '%EF%BC%88',
      '\uff09': '%EF%BC%89',
      '\uff0a': '%EF%BC%8A',
      '\uff0b': '%EF%BC%8B',
      '\uff0c': '%EF%BC%8C',
      '\uff0f': '%EF%BC%8F',
      '\uff1a': '%EF%BC%9A',
      '\uff1b': '%EF%BC%9B',
      '\uff1d': '%EF%BC%9D',
      '\uff1f': '%EF%BC%9F',
      '\uff20': '%EF%BC%A0',
      '\uff3b': '%EF%BC%BB',
      '\uff3d': '%EF%BC%BD',
    }
    function Bd(a) {
      return Gd[a]
    }
    var xd = /[\x00\x22\x27\x3c\x3e]/g,
      Ad =
        /[\x00- \x22\x27-\x29\x3c\x3e\\\x7b\x7d\x7f\x85\xa0\u2028\u2029\uff01\uff03\uff04\uff06-\uff0c\uff0f\uff1a\uff1b\uff1d\uff1f\uff20\uff3b\uff3d]/g,
      Ed =
        /^(?!-*(?:expression|(?:moz-)?binding))(?:[.#]?-?(?:[_a-z0-9-]+)(?:-[_a-z0-9-]+)*-?|-?(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)(?:[a-z]{1,2}|%)?|!important|)$/i,
      Cd =
        /^(?![^#?]*\/(?:\.|%2E){2}(?:[\/?#]|$))(?:(?:https?|mailto):|[^&:\/?#]*(?:[\/?#]|$))/i,
      vd = /<(?:!|\/?([a-zA-Z][a-zA-Z0-9:\-]*))(?:[^>'"]|"[^"]*"|'[^']*')*>/g,
      wd = /</g
    function Hd() {
      return C('Ov\u011b\u0159ov\u00e1n\u00ed\u2026')
    }
    function Id() {
      return C('Zadejte platn\u00e9 telefonn\u00ed \u010d\u00edslo')
    }
    function Jd() {
      return C('\u0160patn\u00fd k\u00f3d. Zkuste to znovu.')
    }
    function Kd() {
      return C(
        'K\u00f3d pro obnoven\u00ed hesla se nepoda\u0159ilo odeslat na zadan\u00fd e-mail'
      )
    }
    function Ld() {
      return C('N\u011bco se nepovedlo. Zkuste to pros\u00edm znovu.')
    }
    function Md() {
      return C(
        'Tento e-mail ji\u017e existuje, ov\u0161em bez nastaven\u00e9ho zp\u016fsobu p\u0159ihl\u00e1\u0161en\u00ed. Obnovte heslo, abyste mohli \u00fa\u010det za\u010d\u00edt pou\u017e\u00edvat.'
      )
    }
    function Nd(a) {
      a = a || {}
      var b = ''
      switch (a.code) {
        case 'auth/email-already-in-use':
          b +=
            'Tuto e-mailovou adresu ji\u017e pou\u017e\u00edv\u00e1 jin\u00fd \u00fa\u010det'
          break
        case 'auth/requires-recent-login':
          b += Od()
          break
        case 'auth/too-many-requests':
          b +=
            'Provedli jste p\u0159\u00edli\u0161 mnoho neplatn\u00fdch pokus\u016f o zad\u00e1n\u00ed hesla. Opakujte akci za chv\u00edli.'
          break
        case 'auth/user-cancelled':
          b +=
            'Ud\u011blte pros\u00edm po\u017eadovan\u00e1 opr\u00e1vn\u011bn\u00ed, kter\u00e1 jsou nutn\u00e1 k p\u0159ihl\u00e1\u0161en\u00ed do aplikace'
          break
        case 'auth/user-not-found':
          b +=
            'E-mailov\u00e1 adresa neodpov\u00edd\u00e1 \u017e\u00e1dn\u00e9mu st\u00e1vaj\u00edc\u00edmu \u00fa\u010dtu.'
          break
        case 'auth/user-token-expired':
          b += Od()
          break
        case 'auth/weak-password':
          b += 'Heslo mus\u00ed m\u00edt alespo\u0148 6 znak\u016f'
          break
        case 'auth/wrong-password':
          b += 'Zadan\u00fd e-mail a heslo se neshoduj\u00ed'
          break
        case 'auth/network-request-failed':
          b += 'Do\u0161lo k chyb\u011b s\u00edt\u011b'
          break
        case 'auth/invalid-phone-number':
          b += Id()
          break
        case 'auth/invalid-verification-code':
          b += Jd()
          break
        case 'auth/code-expired':
          b += 'Platnost k\u00f3du vypr\u0161ela'
          break
        case 'auth/expired-action-code':
          b += 'Platnost tohoto k\u00f3du vypr\u0161ela.'
          break
        case 'auth/invalid-action-code':
          b +=
            'Tento k\u00f3d je neplatn\u00fd. Je z\u0159ejm\u011b \u0161patn\u011b zadan\u00fd, pro\u0161l\u00fd nebo u\u017e pou\u017eit\u00fd.'
          break
        case 'auth/password-does-not-meet-requirements':
          b += 'Missing password requirements:'
      }
      return C(b)
    }
    function Pd(a) {
      a = a || {}
      var b = ''
      switch (a.code) {
        case 'invalid-argument':
          b += 'Klient uvedl neplatn\u00fd argument.'
          break
        case 'invalid-configuration':
          b += 'Klient uvedl neplatnou konfiguraci projektu.'
          break
        case 'failed-precondition':
          b +=
            'V sou\u010dasn\u00e9m stavu syst\u00e9mu nelze po\u017eadavek prov\u00e9st.'
          break
        case 'out-of-range':
          b += 'Klient uvedl neplatn\u00fd rozsah.'
          break
        case 'unauthenticated':
          b +=
            'Po\u017eadavek nebyl ov\u011b\u0159en, proto\u017ee token OAuth chyb\u00ed, je neplatn\u00fd nebo mu vypr\u0161ela platnost.'
          break
        case 'permission-denied':
          b += 'Klient nem\u00e1 dostate\u010dn\u00e9 opr\u00e1vn\u011bn\u00ed.'
          break
        case 'not-found':
          b += 'Uveden\u00fd zdroj nebyl nalezen.'
          break
        case 'aborted':
          b +=
            'Konflikt soub\u011b\u017enosti, jako je konflikt mezi \u010dten\u00edm, \u00fapravou a psan\u00edm.'
          break
        case 'already-exists':
          b +=
            'Zdroj, kter\u00fd se klient pokou\u0161el vytvo\u0159it, ji\u017e existuje.'
          break
        case 'resource-exhausted':
          b += 'Mimo kv\u00f3tu zdroj\u016f nebo bl\u00edzko limitu sazby.'
          break
        case 'cancelled':
          b += 'Po\u017eadavek byl zru\u0161en klientem.'
          break
        case 'data-loss':
          b +=
            'Do\u0161lo ke ztr\u00e1t\u011b neobnoviteln\u00fdch dat nebo k po\u0161kozen\u00ed dat.'
          break
        case 'unknown':
          b += 'Nezn\u00e1m\u00e1 chyba serveru.'
          break
        case 'internal':
          b += 'Intern\u00ed chyba serveru.'
          break
        case 'not-implemented':
          b += 'Metoda API nebyla implementov\u00e1na serverem.'
          break
        case 'unavailable':
          b += 'Slu\u017eba nen\u00ed k dispozici.'
          break
        case 'deadline-exceeded':
          b +=
            'P\u0159ekro\u010den kone\u010dn\u00fd term\u00edn po\u017eadavku.'
          break
        case 'auth/user-disabled':
          b +=
            'U\u017eivatelsk\u00fd \u00fa\u010det byl deaktivov\u00e1n administr\u00e1torem.'
          break
        case 'auth/timeout':
          b += '\u010casov\u00fd limit pro operaci vypr\u0161el.'
          break
        case 'auth/too-many-requests':
          b +=
            'Kv\u016fli neobvykl\u00e9 aktivit\u011b jsme zablokovali v\u0161echny po\u017eadavky z tohoto za\u0159\u00edzen\u00ed. Zkuste to znovu pozd\u011bji.'
          break
        case 'auth/quota-exceeded':
          b +=
            'Kv\u00f3ta pro tuto operaci byla p\u0159ekro\u010dena. Zkuste to znovu pozd\u011bji.'
          break
        case 'auth/network-request-failed':
          b +=
            'Do\u0161lo k chyb\u011b s\u00edt\u011b. Zkuste to znovu pozd\u011bji.'
          break
        case 'restart-process':
          b +=
            'B\u011bhem ov\u011b\u0159ov\u00e1n\u00ed va\u0161\u00ed \u017e\u00e1dosti do\u0161lo k chyb\u011b. Nav\u0161tivte znovu adresu URL, kter\u00e1 v\u00e1s na tuto str\u00e1nku p\u0159esm\u011brovala, abyste proces ov\u011b\u0159ov\u00e1n\u00ed restartovali.'
          break
        case 'no-matching-tenant-for-email':
          b +=
            'Pro dan\u00fd e-mail nen\u00ed k dispozici \u017e\u00e1dn\u00fd poskytovatel p\u0159ihl\u00e1\u0161en\u00ed, zkuste jin\u00fd e-mail.'
      }
      return C(b)
    }
    function Od() {
      return C(
        'Tato operace vy\u017eaduje op\u011btovn\u00e9 p\u0159ihl\u00e1\u0161en\u00ed'
      )
    }
    function Qd(a, b, c) {
      var d = Error.call(this)
      this.message = d.message
      'stack' in d && (this.stack = d.stack)
      this.code = Rd + a
      if (!(a = b)) {
        a = ''
        switch (this.code) {
          case 'firebaseui/merge-conflict':
            a +=
              'Nepoda\u0159ilo se upgradovat st\u00e1vaj\u00edc\u00edho anonymn\u00edho u\u017eivatele. Identifika\u010dn\u00ed \u00fadaje neanonymn\u00edho u\u017eivatele jsou ji\u017e p\u0159idru\u017eeny k \u00fa\u010dtu jin\u00e9ho u\u017eivatele.'
            break
          default:
            a += Ld()
        }
        a = C(a).toString()
      }
      this.message = a || ''
      this.credential = c || null
    }
    m(Qd, Error)
    Qd.prototype.toJSON = function () {
      return { code: this.code, message: this.message }
    }
    var Rd = 'firebaseui/'
    function Sd() {
      0 != Td && (Ud[this[ua] || (this[ua] = ++va)] = this)
      this.T = this.T
      this.C = this.C
    }
    var Td = 0,
      Ud = {}
    Sd.prototype.T = !1
    Sd.prototype.m = function () {
      if (!this.T && ((this.T = !0), this.o(), 0 != Td)) {
        var a = this[ua] || (this[ua] = ++va)
        if (0 != Td && this.C && 0 < this.C.length)
          throw Error(
            this +
              " did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method."
          )
        delete Ud[a]
      }
    }
    function Vd(a, b) {
      a.T
        ? ka(void 0)
          ? b.call(void 0)
          : b()
        : (a.C || (a.C = []), a.C.push(ka(void 0) ? r(b, void 0) : b))
    }
    Sd.prototype.o = function () {
      if (this.C) for (; this.C.length; ) this.C.shift()()
    }
    function Wd(a) {
      a && 'function' == typeof a.m && a.m()
    }
    var Xd =
      Object.freeze ||
      function (a) {
        return a
      }
    var Yd = !z || 9 <= Number(nc),
      Zd = z && !mc('9'),
      $d = (function () {
        if (!n.addEventListener || !Object.defineProperty) return !1
        var a = !1,
          b = Object.defineProperty({}, 'passive', {
            get: function () {
              a = !0
            },
          })
        try {
          n.addEventListener('test', na, b),
            n.removeEventListener('test', na, b)
        } catch (c) {}
        return a
      })()
    function ae(a, b) {
      this.type = a
      this.g = this.target = b
      this.h = !1
      this.qb = !0
    }
    ae.prototype.stopPropagation = function () {
      this.h = !0
    }
    ae.prototype.preventDefault = function () {
      this.qb = !1
    }
    function be(a, b) {
      ae.call(this, a ? a.type : '')
      this.relatedTarget = this.g = this.target = null
      this.button =
        this.screenY =
        this.screenX =
        this.clientY =
        this.clientX =
          0
      this.key = ''
      this.j = this.keyCode = 0
      this.metaKey = this.shiftKey = this.altKey = this.ctrlKey = !1
      this.pointerId = 0
      this.pointerType = ''
      this.a = null
      if (a) {
        var c = (this.type = a.type),
          d =
            a.changedTouches && a.changedTouches.length
              ? a.changedTouches[0]
              : null
        this.target = a.target || a.srcElement
        this.g = b
        if ((b = a.relatedTarget)) {
          if (dc) {
            a: {
              try {
                Yb(b.nodeName)
                var e = !0
                break a
              } catch (f) {}
              e = !1
            }
            e || (b = null)
          }
        } else
          'mouseover' == c
            ? (b = a.fromElement)
            : 'mouseout' == c && (b = a.toElement)
        this.relatedTarget = b
        d
          ? ((this.clientX = void 0 !== d.clientX ? d.clientX : d.pageX),
            (this.clientY = void 0 !== d.clientY ? d.clientY : d.pageY),
            (this.screenX = d.screenX || 0),
            (this.screenY = d.screenY || 0))
          : ((this.clientX = void 0 !== a.clientX ? a.clientX : a.pageX),
            (this.clientY = void 0 !== a.clientY ? a.clientY : a.pageY),
            (this.screenX = a.screenX || 0),
            (this.screenY = a.screenY || 0))
        this.button = a.button
        this.keyCode = a.keyCode || 0
        this.key = a.key || ''
        this.j = a.charCode || ('keypress' == c ? a.keyCode : 0)
        this.ctrlKey = a.ctrlKey
        this.altKey = a.altKey
        this.shiftKey = a.shiftKey
        this.metaKey = a.metaKey
        this.pointerId = a.pointerId || 0
        this.pointerType = q(a.pointerType)
          ? a.pointerType
          : ce[a.pointerType] || ''
        this.a = a
        a.defaultPrevented && this.preventDefault()
      }
    }
    w(be, ae)
    var ce = Xd({ 2: 'touch', 3: 'pen', 4: 'mouse' })
    be.prototype.stopPropagation = function () {
      be.K.stopPropagation.call(this)
      this.a.stopPropagation
        ? this.a.stopPropagation()
        : (this.a.cancelBubble = !0)
    }
    be.prototype.preventDefault = function () {
      be.K.preventDefault.call(this)
      var a = this.a
      if (a.preventDefault) a.preventDefault()
      else if (((a.returnValue = !1), Zd))
        try {
          if (a.ctrlKey || (112 <= a.keyCode && 123 >= a.keyCode))
            a.keyCode = -1
        } catch (b) {}
    }
    var de = 'closure_listenable_' + ((1e6 * Math.random()) | 0),
      ee = 0
    function fe(a, b, c, d, e) {
      this.listener = a
      this.proxy = null
      this.src = b
      this.type = c
      this.capture = !!d
      this.La = e
      this.key = ++ee
      this.sa = this.Ia = !1
    }
    function ge(a) {
      a.sa = !0
      a.listener = null
      a.proxy = null
      a.src = null
      a.La = null
    }
    function he(a) {
      this.src = a
      this.a = {}
      this.g = 0
    }
    he.prototype.add = function (a, b, c, d, e) {
      var f = a.toString()
      a = this.a[f]
      a || ((a = this.a[f] = []), this.g++)
      var g = ie(a, b, d, e)
      ;-1 < g
        ? ((b = a[g]), c || (b.Ia = !1))
        : ((b = new fe(b, this.src, f, !!d, e)), (b.Ia = c), a.push(b))
      return b
    }
    function je(a, b) {
      var c = b.type
      c in a.a &&
        Na(a.a[c], b) &&
        (ge(b), 0 == a.a[c].length && (delete a.a[c], a.g--))
    }
    function ie(a, b, c, d) {
      for (var e = 0; e < a.length; ++e) {
        var f = a[e]
        if (!f.sa && f.listener == b && f.capture == !!c && f.La == d) return e
      }
      return -1
    }
    var ke = 'closure_lm_' + ((1e6 * Math.random()) | 0),
      le = {},
      me = 0
    function ne(a, b, c, d, e) {
      if (d && d.once) return oe(a, b, c, d, e)
      if (qa(b)) {
        for (var f = 0; f < b.length; f++) ne(a, b[f], c, d, e)
        return null
      }
      c = pe(c)
      return a && a[de]
        ? a.J.add(String(b), c, !1, ta(d) ? !!d.capture : !!d, e)
        : qe(a, b, c, !1, d, e)
    }
    function qe(a, b, c, d, e, f) {
      if (!b) throw Error('Invalid event type')
      var g = ta(e) ? !!e.capture : !!e,
        h = re(a)
      h || (a[ke] = h = new he(a))
      c = h.add(b, c, d, g, f)
      if (c.proxy) return c
      d = se()
      c.proxy = d
      d.src = a
      d.listener = c
      if (a.addEventListener)
        $d || (e = g),
          void 0 === e && (e = !1),
          a.addEventListener(b.toString(), d, e)
      else if (a.attachEvent) a.attachEvent(te(b.toString()), d)
      else if (a.addListener && a.removeListener) a.addListener(d)
      else throw Error('addEventListener and attachEvent are unavailable.')
      me++
      return c
    }
    function se() {
      var a = ue,
        b = Yd
          ? function (c) {
              return a.call(b.src, b.listener, c)
            }
          : function (c) {
              c = a.call(b.src, b.listener, c)
              if (!c) return c
            }
      return b
    }
    function oe(a, b, c, d, e) {
      if (qa(b)) {
        for (var f = 0; f < b.length; f++) oe(a, b[f], c, d, e)
        return null
      }
      c = pe(c)
      return a && a[de]
        ? a.J.add(String(b), c, !0, ta(d) ? !!d.capture : !!d, e)
        : qe(a, b, c, !0, d, e)
    }
    function ve(a, b, c, d, e) {
      if (qa(b)) for (var f = 0; f < b.length; f++) ve(a, b[f], c, d, e)
      else
        ((d = ta(d) ? !!d.capture : !!d), (c = pe(c)), a && a[de])
          ? ((a = a.J),
            (b = String(b).toString()),
            b in a.a &&
              ((f = a.a[b]),
              (c = ie(f, c, d, e)),
              -1 < c &&
                (ge(f[c]), Oa(f, c), 0 == f.length && (delete a.a[b], a.g--))))
          : a &&
            (a = re(a)) &&
            ((b = a.a[b.toString()]),
            (a = -1),
            b && (a = ie(b, c, d, e)),
            (c = -1 < a ? b[a] : null) && we(c))
    }
    function we(a) {
      if ('number' != typeof a && a && !a.sa) {
        var b = a.src
        if (b && b[de]) je(b.J, a)
        else {
          var c = a.type,
            d = a.proxy
          b.removeEventListener
            ? b.removeEventListener(c, d, a.capture)
            : b.detachEvent
              ? b.detachEvent(te(c), d)
              : b.addListener && b.removeListener && b.removeListener(d)
          me--
          ;(c = re(b))
            ? (je(c, a), 0 == c.g && ((c.src = null), (b[ke] = null)))
            : ge(a)
        }
      }
    }
    function te(a) {
      return a in le ? le[a] : (le[a] = 'on' + a)
    }
    function xe(a, b, c, d) {
      var e = !0
      if ((a = re(a)))
        if ((b = a.a[b.toString()]))
          for (b = b.concat(), a = 0; a < b.length; a++) {
            var f = b[a]
            f &&
              f.capture == c &&
              !f.sa &&
              ((f = ye(f, d)), (e = e && !1 !== f))
          }
      return e
    }
    function ye(a, b) {
      var c = a.listener,
        d = a.La || a.src
      a.Ia && we(a)
      return c.call(d, b)
    }
    function ue(a, b) {
      if (a.sa) return !0
      if (!Yd) {
        if (!b)
          a: {
            b = ['window', 'event']
            for (var c = n, d = 0; d < b.length; d++)
              if (((c = c[b[d]]), null == c)) {
                b = null
                break a
              }
            b = c
          }
        d = b
        b = new be(d, this)
        c = !0
        if (!(0 > d.keyCode || void 0 != d.returnValue)) {
          a: {
            var e = !1
            if (0 == d.keyCode)
              try {
                d.keyCode = -1
                break a
              } catch (g) {
                e = !0
              }
            if (e || void 0 == d.returnValue) d.returnValue = !0
          }
          d = []
          for (e = b.g; e; e = e.parentNode) d.push(e)
          a = a.type
          for (e = d.length - 1; !b.h && 0 <= e; e--) {
            b.g = d[e]
            var f = xe(d[e], a, !0, b)
            c = c && f
          }
          for (e = 0; !b.h && e < d.length; e++)
            (b.g = d[e]), (f = xe(d[e], a, !1, b)), (c = c && f)
        }
        return c
      }
      return ye(a, new be(b, this))
    }
    function re(a) {
      a = a[ke]
      return a instanceof he ? a : null
    }
    var ze = '__closure_events_fn_' + ((1e9 * Math.random()) >>> 0)
    function pe(a) {
      if (sa(a)) return a
      a[ze] ||
        (a[ze] = function (b) {
          return a.handleEvent(b)
        })
      return a[ze]
    }
    function E() {
      Sd.call(this)
      this.J = new he(this)
      this.wb = this
      this.Ha = null
    }
    w(E, Sd)
    E.prototype[de] = !0
    E.prototype.Za = function (a) {
      this.Ha = a
    }
    E.prototype.removeEventListener = function (a, b, c, d) {
      ve(this, a, b, c, d)
    }
    function Ae(a, b) {
      var c,
        d = a.Ha
      if (d) for (c = []; d; d = d.Ha) c.push(d)
      a = a.wb
      d = b.type || b
      if (q(b)) b = new ae(b, a)
      else if (b instanceof ae) b.target = b.target || a
      else {
        var e = b
        b = new ae(d, a)
        gb(b, e)
      }
      e = !0
      if (c)
        for (var f = c.length - 1; !b.h && 0 <= f; f--) {
          var g = (b.g = c[f])
          e = Be(g, d, !0, b) && e
        }
      b.h ||
        ((g = b.g = a),
        (e = Be(g, d, !0, b) && e),
        b.h || (e = Be(g, d, !1, b) && e))
      if (c)
        for (f = 0; !b.h && f < c.length; f++)
          (g = b.g = c[f]), (e = Be(g, d, !1, b) && e)
      return e
    }
    E.prototype.o = function () {
      E.K.o.call(this)
      if (this.J) {
        var a = this.J,
          b = 0,
          c
        for (c in a.a) {
          for (var d = a.a[c], e = 0; e < d.length; e++) ++b, ge(d[e])
          delete a.a[c]
          a.g--
        }
      }
      this.Ha = null
    }
    function Be(a, b, c, d) {
      b = a.J.a[String(b)]
      if (!b) return !0
      b = b.concat()
      for (var e = !0, f = 0; f < b.length; ++f) {
        var g = b[f]
        if (g && !g.sa && g.capture == c) {
          var h = g.listener,
            k = g.La || g.src
          g.Ia && je(a.J, g)
          e = !1 !== h.call(k, d) && e
        }
      }
      return e && 0 != d.qb
    }
    var Ce = {},
      De = 0
    function Ee(a, b) {
      if (!a) throw Error('Event target element must be provided!')
      a = Fe(a)
      if (Ce[a] && Ce[a].length)
        for (var c = 0; c < Ce[a].length; c++) Ae(Ce[a][c], b)
    }
    function Ge(a) {
      var b = Fe(a.N())
      Ce[b] &&
        Ce[b].length &&
        (Pa(Ce[b], function (c) {
          return c == a
        }),
        Ce[b].length || delete Ce[b])
    }
    function Fe(a) {
      'undefined' === typeof a.a && ((a.a = De), De++)
      return a.a
    }
    function He(a) {
      if (!a) throw Error('Event target element must be provided!')
      E.call(this)
      this.a = a
    }
    m(He, E)
    He.prototype.N = function () {
      return this.a
    }
    He.prototype.register = function () {
      var a = Fe(this.N())
      Ce[a] ? Ma(Ce[a], this) || Ce[a].push(this) : (Ce[a] = [this])
    }
    function Ie(a) {
      if (!a) return !1
      try {
        return !!a.$goog_Thenable
      } catch (b) {
        return !1
      }
    }
    function Je(a, b) {
      this.h = a
      this.j = b
      this.g = 0
      this.a = null
    }
    Je.prototype.get = function () {
      if (0 < this.g) {
        this.g--
        var a = this.a
        this.a = a.next
        a.next = null
      } else a = this.h()
      return a
    }
    function Ke(a, b) {
      a.j(b)
      100 > a.g && (a.g++, (b.next = a.a), (a.a = b))
    }
    function Le() {
      this.g = this.a = null
    }
    var Ne = new Je(
      function () {
        return new Me()
      },
      function (a) {
        a.reset()
      }
    )
    Le.prototype.add = function (a, b) {
      var c = Ne.get()
      c.set(a, b)
      this.g ? (this.g.next = c) : (this.a = c)
      this.g = c
    }
    function Oe() {
      var a = Pe,
        b = null
      a.a && ((b = a.a), (a.a = a.a.next), a.a || (a.g = null), (b.next = null))
      return b
    }
    function Me() {
      this.next = this.g = this.a = null
    }
    Me.prototype.set = function (a, b) {
      this.a = a
      this.g = b
      this.next = null
    }
    Me.prototype.reset = function () {
      this.next = this.g = this.a = null
    }
    function Qe(a) {
      n.setTimeout(function () {
        throw a
      }, 0)
    }
    var Re
    function Se() {
      var a = n.MessageChannel
      'undefined' === typeof a &&
        'undefined' !== typeof window &&
        window.postMessage &&
        window.addEventListener &&
        !y('Presto') &&
        (a = function () {
          var e = document.createElement('IFRAME')
          e.style.display = 'none'
          e.src = ''
          document.documentElement.appendChild(e)
          var f = e.contentWindow
          e = f.document
          e.open()
          e.write('')
          e.close()
          var g = 'callImmediate' + Math.random(),
            h =
              'file:' == f.location.protocol
                ? '*'
                : f.location.protocol + '//' + f.location.host
          e = r(function (k) {
            if (('*' == h || k.origin == h) && k.data == g)
              this.port1.onmessage()
          }, this)
          f.addEventListener('message', e, !1)
          this.port1 = {}
          this.port2 = {
            postMessage: function () {
              f.postMessage(g, h)
            },
          }
        })
      if ('undefined' !== typeof a && !y('Trident') && !y('MSIE')) {
        var b = new a(),
          c = {},
          d = c
        b.port1.onmessage = function () {
          if (ka(c.next)) {
            c = c.next
            var e = c.gb
            c.gb = null
            e()
          }
        }
        return function (e) {
          d.next = { gb: e }
          d = d.next
          b.port2.postMessage(0)
        }
      }
      return 'undefined' !== typeof document &&
        'onreadystatechange' in document.createElement('SCRIPT')
        ? function (e) {
            var f = document.createElement('SCRIPT')
            f.onreadystatechange = function () {
              f.onreadystatechange = null
              f.parentNode.removeChild(f)
              f = null
              e()
              e = null
            }
            document.documentElement.appendChild(f)
          }
        : function (e) {
            n.setTimeout(e, 0)
          }
    }
    function Te(a, b) {
      Ue || Ve()
      We || (Ue(), (We = !0))
      Pe.add(a, b)
    }
    var Ue
    function Ve() {
      if (n.Promise && n.Promise.resolve) {
        var a = n.Promise.resolve(void 0)
        Ue = function () {
          a.then(Xe)
        }
      } else
        Ue = function () {
          var b = Xe
          !sa(n.setImmediate) ||
          (n.Window &&
            n.Window.prototype &&
            !y('Edge') &&
            n.Window.prototype.setImmediate == n.setImmediate)
            ? (Re || (Re = Se()), Re(b))
            : n.setImmediate(b)
        }
    }
    var We = !1,
      Pe = new Le()
    function Xe() {
      for (var a; (a = Oe()); ) {
        try {
          a.a.call(a.g)
        } catch (b) {
          Qe(b)
        }
        Ke(Ne, a)
      }
      We = !1
    }
    function Ye(a) {
      this.a = Ze
      this.A = void 0
      this.j = this.g = this.h = null
      this.s = this.i = !1
      if (a != na)
        try {
          var b = this
          a.call(
            void 0,
            function (c) {
              $e(b, af, c)
            },
            function (c) {
              if (!(c instanceof bf))
                try {
                  if (c instanceof Error) throw c
                  throw Error('Promise rejected.')
                } catch (d) {}
              $e(b, cf, c)
            }
          )
        } catch (c) {
          $e(this, cf, c)
        }
    }
    var Ze = 0,
      af = 2,
      cf = 3
    function df() {
      this.next = this.j = this.g = this.s = this.a = null
      this.h = !1
    }
    df.prototype.reset = function () {
      this.j = this.g = this.s = this.a = null
      this.h = !1
    }
    var ef = new Je(
      function () {
        return new df()
      },
      function (a) {
        a.reset()
      }
    )
    function ff(a, b, c) {
      var d = ef.get()
      d.s = a
      d.g = b
      d.j = c
      return d
    }
    function F(a) {
      if (a instanceof Ye) return a
      var b = new Ye(na)
      $e(b, af, a)
      return b
    }
    function gf(a) {
      return new Ye(function (b, c) {
        c(a)
      })
    }
    Ye.prototype.then = function (a, b, c) {
      return hf(this, sa(a) ? a : null, sa(b) ? b : null, c)
    }
    Ye.prototype.$goog_Thenable = !0
    l = Ye.prototype
    l.fc = function (a, b) {
      a = ff(a, a, b)
      a.h = !0
      jf(this, a)
      return this
    }
    l.Ca = function (a, b) {
      return hf(this, null, a, b)
    }
    l.cancel = function (a) {
      this.a == Ze &&
        Te(function () {
          var b = new bf(a)
          kf(this, b)
        }, this)
    }
    function kf(a, b) {
      if (a.a == Ze)
        if (a.h) {
          var c = a.h
          if (c.g) {
            for (
              var d = 0, e = null, f = null, g = c.g;
              g && (g.h || (d++, g.a == a && (e = g), !(e && 1 < d)));
              g = g.next
            )
              e || (f = g)
            e &&
              (c.a == Ze && 1 == d
                ? kf(c, b)
                : (f
                    ? ((d = f),
                      d.next == c.j && (c.j = d),
                      (d.next = d.next.next))
                    : lf(c),
                  mf(c, e, cf, b)))
          }
          a.h = null
        } else $e(a, cf, b)
    }
    function jf(a, b) {
      a.g || (a.a != af && a.a != cf) || nf(a)
      a.j ? (a.j.next = b) : (a.g = b)
      a.j = b
    }
    function hf(a, b, c, d) {
      var e = ff(null, null, null)
      e.a = new Ye(function (f, g) {
        e.s = b
          ? function (h) {
              try {
                var k = b.call(d, h)
                f(k)
              } catch (p) {
                g(p)
              }
            }
          : f
        e.g = c
          ? function (h) {
              try {
                var k = c.call(d, h)
                !ka(k) && h instanceof bf ? g(h) : f(k)
              } catch (p) {
                g(p)
              }
            }
          : g
      })
      e.a.h = a
      jf(a, e)
      return e.a
    }
    l.hc = function (a) {
      this.a = Ze
      $e(this, af, a)
    }
    l.ic = function (a) {
      this.a = Ze
      $e(this, cf, a)
    }
    function $e(a, b, c) {
      if (a.a == Ze) {
        a === c &&
          ((b = cf), (c = new TypeError('Promise cannot resolve to itself')))
        a.a = 1
        a: {
          var d = c,
            e = a.hc,
            f = a.ic
          if (d instanceof Ye) {
            jf(d, ff(e || na, f || null, a))
            var g = !0
          } else if (Ie(d)) d.then(e, f, a), (g = !0)
          else {
            if (ta(d))
              try {
                var h = d.then
                if (sa(h)) {
                  of(d, h, e, f, a)
                  g = !0
                  break a
                }
              } catch (k) {
                f.call(a, k)
                g = !0
                break a
              }
            g = !1
          }
        }
        g ||
          ((a.A = c),
          (a.a = b),
          (a.h = null),
          nf(a),
          b != cf || c instanceof bf || pf(a, c))
      }
    }
    function of(a, b, c, d, e) {
      function f(k) {
        h || ((h = !0), d.call(e, k))
      }
      function g(k) {
        h || ((h = !0), c.call(e, k))
      }
      var h = !1
      try {
        b.call(a, g, f)
      } catch (k) {
        f(k)
      }
    }
    function nf(a) {
      a.i || ((a.i = !0), Te(a.Hb, a))
    }
    function lf(a) {
      var b = null
      a.g && ((b = a.g), (a.g = b.next), (b.next = null))
      a.g || (a.j = null)
      return b
    }
    l.Hb = function () {
      for (var a; (a = lf(this)); ) mf(this, a, this.a, this.A)
      this.i = !1
    }
    function mf(a, b, c, d) {
      if (c == cf && b.g && !b.h) for (; a && a.s; a = a.h) a.s = !1
      if (b.a) (b.a.h = null), qf(b, c, d)
      else
        try {
          b.h ? b.s.call(b.j) : qf(b, c, d)
        } catch (e) {
          rf.call(null, e)
        }
      Ke(ef, b)
    }
    function qf(a, b, c) {
      b == af ? a.s.call(a.j, c) : a.g && a.g.call(a.j, c)
    }
    function pf(a, b) {
      a.s = !0
      Te(function () {
        a.s && rf.call(null, b)
      })
    }
    var rf = Qe
    function bf(a) {
      Ba.call(this, a)
    }
    w(bf, Ba)
    bf.prototype.name = 'cancel'
    function sf(a, b, c) {
      b || (b = {})
      c = c || window
      var d =
        a instanceof xc
          ? a
          : Bc('undefined' != typeof a.href ? a.href : String(a))
      a = b.target || a.target
      var e = []
      for (f in b)
        switch (f) {
          case 'width':
          case 'height':
          case 'top':
          case 'left':
            e.push(f + '=' + b[f])
            break
          case 'target':
          case 'noopener':
          case 'noreferrer':
            break
          default:
            e.push(f + '=' + (b[f] ? 1 : 0))
        }
      var f = e.join(',')
      ;((y('iPhone') && !y('iPod') && !y('iPad')) || y('iPad') || y('iPod')) &&
      c.navigator &&
      c.navigator.standalone &&
      a &&
      '_self' != a
        ? ((f = c.document.createElement('A')),
          (d = d instanceof xc ? d : Dc(d)),
          (f.href = zc(d)),
          f.setAttribute('target', a),
          b.noreferrer && f.setAttribute('rel', 'noreferrer'),
          (b = document.createEvent('MouseEvent')),
          b.initMouseEvent('click', !0, !0, c, 1),
          f.dispatchEvent(b),
          (c = {}))
        : b.noreferrer
          ? ((c = c.open('', a, f)),
            (b = zc(d).toString()),
            c &&
              (cc &&
                -1 != b.indexOf(';') &&
                (b = "'" + b.replace(/'/g, '%27') + "'"),
              (c.opener = null),
              (b = Jc(
                '<meta name="referrer" content="no-referrer"><meta http-equiv="refresh" content="0; url=' +
                  cb(b) +
                  '">',
                null
              )),
              c.document.write(Ic(b)),
              c.document.close()))
          : (c = c.open(zc(d).toString(), a, f)) &&
            b.noopener &&
            (c.opener = null)
      return c
    }
    function tf() {
      try {
        return !(
          !window.opener ||
          !window.opener.location ||
          window.opener.location.hostname !== window.location.hostname ||
          window.opener.location.protocol !== window.location.protocol
        )
      } catch (a$1) {}
      return !1
    }
    function uf(a) {
      sf(
        a,
        {
          target:
            window.cordova && window.cordova.InAppBrowser
              ? '_system'
              : '_blank',
        },
        void 0
      )
    }
    function vf(a, b) {
      a = ta(a) && 1 == a.nodeType ? a : document.querySelector(String(a))
      if (null == a) throw Error(b || 'Cannot find element.')
      return a
    }
    function wf() {
      return window.location.href
    }
    function xf() {
      var a = null
      return new Ye(function (b) {
        'complete' == n.document.readyState
          ? b()
          : ((a = function () {
              b()
            }),
            oe(window, 'load', a))
      }).Ca(function (b) {
        ve(window, 'load', a)
        throw b
      })
    }
    function yf() {
      for (var a = 32, b = []; 0 < a; )
        b.push(
          '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(
            Math.floor(62 * Math.random())
          )
        ),
          a--
      return b.join('')
    }
    function zf(a, b, c) {
      c = void 0 === c ? {} : c
      return Object.keys(a)
        .filter(function (d) {
          return b.includes(d)
        })
        .reduce(function (d, e) {
          d[e] = a[e]
          return d
        }, c)
    }
    function Af(a) {
      var b = Bf
      this.s = []
      this.T = b
      this.O = a || null
      this.j = this.a = !1
      this.h = void 0
      this.J = this.l = this.A = !1
      this.i = 0
      this.g = null
      this.C = 0
    }
    Af.prototype.cancel = function (a) {
      if (this.a) this.h instanceof Af && this.h.cancel()
      else {
        if (this.g) {
          var b = this.g
          delete this.g
          a ? b.cancel(a) : (b.C--, 0 >= b.C && b.cancel())
        }
        this.T ? this.T.call(this.O, this) : (this.J = !0)
        this.a || ((a = new Cf(this)), Df(this), Ef(this, !1, a))
      }
    }
    Af.prototype.L = function (a, b) {
      this.A = !1
      Ef(this, a, b)
    }
    function Ef(a, b, c) {
      a.a = !0
      a.h = c
      a.j = !b
      Ff(a)
    }
    function Df(a) {
      if (a.a) {
        if (!a.J) throw new Gf(a)
        a.J = !1
      }
    }
    Af.prototype.callback = function (a) {
      Df(this)
      Ef(this, !0, a)
    }
    function Hf(a, b, c) {
      a.s.push([b, c, void 0])
      a.a && Ff(a)
    }
    Af.prototype.then = function (a, b, c) {
      var d,
        e,
        f = new Ye(function (g, h) {
          d = g
          e = h
        })
      Hf(this, d, function (g) {
        g instanceof Cf ? f.cancel() : e(g)
      })
      return f.then(a, b, c)
    }
    Af.prototype.$goog_Thenable = !0
    function If(a) {
      return La(a.s, function (b) {
        return sa(b[1])
      })
    }
    function Ff(a) {
      if (a.i && a.a && If(a)) {
        var b = a.i,
          c = Jf[b]
        c && (n.clearTimeout(c.a), delete Jf[b])
        a.i = 0
      }
      a.g && (a.g.C--, delete a.g)
      b = a.h
      for (var d = (c = !1); a.s.length && !a.A; ) {
        var e = a.s.shift(),
          f = e[0],
          g = e[1]
        e = e[2]
        if ((f = a.j ? g : f))
          try {
            var h = f.call(e || a.O, b)
            ka(h) &&
              ((a.j = a.j && (h == b || h instanceof Error)), (a.h = b = h))
            if (
              Ie(b) ||
              ('function' === typeof n.Promise && b instanceof n.Promise)
            )
              (d = !0), (a.A = !0)
          } catch (k) {
            ;(b = k), (a.j = !0), If(a) || (c = !0)
          }
      }
      a.h = b
      d &&
        ((h = r(a.L, a, !0)),
        (d = r(a.L, a, !1)),
        b instanceof Af ? (Hf(b, h, d), (b.l = !0)) : b.then(h, d))
      c && ((b = new Kf(b)), (Jf[b.a] = b), (a.i = b.a))
    }
    function Gf() {
      Ba.call(this)
    }
    w(Gf, Ba)
    Gf.prototype.message = 'Deferred has already fired'
    Gf.prototype.name = 'AlreadyCalledError'
    function Cf() {
      Ba.call(this)
    }
    w(Cf, Ba)
    Cf.prototype.message = 'Deferred was canceled'
    Cf.prototype.name = 'CanceledError'
    function Kf(a) {
      this.a = n.setTimeout(r(this.h, this), 0)
      this.g = a
    }
    Kf.prototype.h = function () {
      delete Jf[this.a]
      throw this.g
    }
    var Jf = {}
    function Lf(a) {
      var b = {},
        c = b.document || document,
        d = uc(a).toString(),
        e = document.createElement('SCRIPT'),
        f = { rb: e, sb: void 0 },
        g = new Af(f),
        h = null,
        k = null != b.timeout ? b.timeout : 5e3
      0 < k &&
        ((h = window.setTimeout(function () {
          Mf(e, !0)
          var p = new Nf(Of, 'Timeout reached for loading script ' + d)
          Df(g)
          Ef(g, !1, p)
        }, k)),
        (f.sb = h))
      e.onload = e.onreadystatechange = function () {
        ;(e.readyState &&
          'loaded' != e.readyState &&
          'complete' != e.readyState) ||
          (Mf(e, b.xc || !1, h), g.callback(null))
      }
      e.onerror = function () {
        Mf(e, !0, h)
        var p = new Nf(Pf, 'Error while loading script ' + d)
        Df(g)
        Ef(g, !1, p)
      }
      f = b.attributes || {}
      gb(f, { type: 'text/javascript', charset: 'UTF-8' })
      Wc(e, f)
      Mc(e, a)
      Qf(c).appendChild(e)
      return g
    }
    function Qf(a) {
      var b = (a || document).getElementsByTagName('HEAD')
      return b && 0 != b.length ? b[0] : a.documentElement
    }
    function Bf() {
      if (this && this.rb) {
        var a = this.rb
        a && 'SCRIPT' == a.tagName && Mf(a, !0, this.sb)
      }
    }
    function Mf(a, b, c) {
      null != c && n.clearTimeout(c)
      a.onload = na
      a.onerror = na
      a.onreadystatechange = na
      b &&
        window.setTimeout(function () {
          Zc(a)
        }, 0)
    }
    var Pf = 0,
      Of = 1
    function Nf(a, b) {
      var c = 'Jsloader error (code #' + a + ')'
      b && (c += ': ' + b)
      Ba.call(this, c)
      this.code = a
    }
    w(Nf, Ba)
    function Rf() {
      return (n.google && n.google.accounts && n.google.accounts.id) || null
    }
    function Sf(a) {
      this.a = a || Rf()
      this.h = !1
      this.g = null
    }
    Sf.prototype.cancel = function () {
      this.a && this.h && (this.g && this.g(null), this.a.cancel())
    }
    function Tf(a, b, c) {
      if (a.a && b)
        return (function () {
          a.h = !0
          return new Ye(function (e) {
            a.g = e
            a.a.initialize({ client_id: b, callback: e, auto_select: !c })
            a.a.prompt()
          })
        })()
      if (b) {
        var d = Uf.Xa()
          .load()
          .then(function () {
            a.a = Rf()
            return Tf(a, b, c)
          })
          .Ca(function () {
            return null
          })
        return F(d)
      }
      return F(null)
    }
    oa(Sf)
    var wc = new pc(qc, 'https://accounts.google.com/gsi/client')
    function Uf() {
      this.a = null
    }
    Uf.prototype.load = function () {
      var a = this
      if (this.a) return this.a
      var b = vc()
      return Rf()
        ? F()
        : (this.a = xf().then(function () {
            if (!Rf())
              return new Ye(function (c, d) {
                var e = setTimeout(function () {
                  a.a = null
                  d(Error('Network error!'))
                }, 1e4)
                n.onGoogleLibraryLoad = function () {
                  clearTimeout(e)
                  c()
                }
                F(Lf(b))
                  .then(function () {
                    Rf() && c()
                  })
                  .Ca(function (f) {
                    clearTimeout(e)
                    a.a = null
                    d(f)
                  })
              })
          }))
    }
    oa(Uf)
    function Vf(a, b) {
      this.a = a
      this.g =
        b ||
        function (c) {
          throw c
        }
    }
    Vf.prototype.confirm = function (a) {
      return F(this.a.confirm(a)).Ca(this.g)
    }
    function Wf(a, b, c) {
      this.reset(a, b, c, void 0, void 0)
    }
    Wf.prototype.a = null
    var Xf = 0
    Wf.prototype.reset = function (a, b, c, d, e) {
      'number' == typeof e || Xf++
      this.h = d || Aa()
      this.j = a
      this.s = b
      this.g = c
      delete this.a
    }
    function Yf(a) {
      this.s = a
      this.a = this.h = this.j = this.g = null
    }
    function Zf(a, b) {
      this.name = a
      this.value = b
    }
    Zf.prototype.toString = function () {
      return this.name
    }
    var $f = new Zf('SEVERE', 1e3),
      ag = new Zf('WARNING', 900),
      bg = new Zf('CONFIG', 700)
    function cg(a) {
      if (a.j) return a.j
      if (a.g) return cg(a.g)
      Fa('Root logger has no level set.')
      return null
    }
    Yf.prototype.log = function (a, b, c) {
      if (a.value >= cg(this).value)
        for (
          sa(b) && (b = b()),
            a = new Wf(a, String(b), this.s),
            c && (a.a = c),
            c = this;
          c;

        ) {
          var d = c,
            e = a
          if (d.a) for (var f = 0; (b = d.a[f]); f++) b(e)
          c = c.g
        }
    }
    var dg = {},
      eg = null
    function fg() {
      eg || ((eg = new Yf('')), (dg[''] = eg), (eg.j = bg))
    }
    function gg(a) {
      fg()
      var b
      if (!(b = dg[a])) {
        b = new Yf(a)
        var c = a.lastIndexOf('.'),
          d = a.substr(c + 1)
        c = gg(a.substr(0, c))
        c.h || (c.h = {})
        c.h[d] = b
        b.g = c
        dg[a] = b
      }
      return b
    }
    function hg() {
      this.a = Aa()
    }
    var ig = null
    hg.prototype.set = function (a) {
      this.a = a
    }
    hg.prototype.reset = function () {
      this.set(Aa())
    }
    hg.prototype.get = function () {
      return this.a
    }
    function jg(a) {
      this.j = a || ''
      ig || (ig = new hg())
      this.s = ig
    }
    jg.prototype.a = !0
    jg.prototype.g = !0
    jg.prototype.h = !1
    function kg(a) {
      return 10 > a ? '0' + a : String(a)
    }
    function lg(a, b) {
      a = (a.h - b) / 1e3
      b = a.toFixed(3)
      var c = 0
      if (1 > a) c = 2
      else for (; 100 > a; ) c++, (a *= 10)
      for (; 0 < c--; ) b = ' ' + b
      return b
    }
    function mg(a) {
      jg.call(this, a)
    }
    w(mg, jg)
    function ng(a, b) {
      var c = []
      c.push(a.j, ' ')
      if (a.g) {
        var d = new Date(b.h)
        c.push(
          '[',
          kg(d.getFullYear() - 2e3) +
            kg(d.getMonth() + 1) +
            kg(d.getDate()) +
            ' ' +
            kg(d.getHours()) +
            ':' +
            kg(d.getMinutes()) +
            ':' +
            kg(d.getSeconds()) +
            '.' +
            kg(Math.floor(d.getMilliseconds() / 10)),
          '] '
        )
      }
      c.push('[', lg(b, a.s.get()), 's] ')
      c.push('[', b.g, '] ')
      c.push(b.s)
      a.h &&
        (b = b.a) &&
        c.push('\n', b instanceof Error ? b.message : b.toString())
      a.a && c.push('\n')
      return c.join('')
    }
    function og() {
      this.s = r(this.h, this)
      this.a = new mg()
      this.a.g = !1
      this.a.h = !1
      this.g = this.a.a = !1
      this.j = {}
    }
    og.prototype.h = function (a) {
      function b(f) {
        if (f) {
          if (f.value >= $f.value) return 'error'
          if (f.value >= ag.value) return 'warn'
          if (f.value >= bg.value) return 'log'
        }
        return 'debug'
      }
      if (!this.j[a.g]) {
        var c = ng(this.a, a),
          d = pg
        if (d) {
          var e = b(a.j)
          qg(d, e, c, a.a)
        }
      }
    }
    var pg = n.console
    function qg(a, b, c, d) {
      if (a[b]) a[b](c, d || '')
      else a.log(c, d || '')
    }
    function rg(a, b) {
      var c = sg
      c && c.log($f, a, b)
    }
    var sg
    sg = gg('firebaseui')
    var tg = new og()
    if (1 != tg.g) {
      var ug
      fg()
      ug = eg
      var vg = tg.s
      ug.a || (ug.a = [])
      ug.a.push(vg)
      tg.g = !0
    }
    function wg(a) {
      var b = sg
      b && b.log(ag, a, void 0)
    }
    function yg() {
      this.a = ('undefined' == typeof document ? null : document) || {
        cookie: '',
      }
    }
    l = yg.prototype
    l.set = function (a, b, c, d, e, f) {
      if (/[;=\s]/.test(a)) throw Error('Invalid cookie name "' + a + '"')
      if (/[;\r\n]/.test(b)) throw Error('Invalid cookie value "' + b + '"')
      ka(c) || (c = -1)
      e = e ? ';domain=' + e : ''
      d = d ? ';path=' + d : ''
      f = f ? ';secure' : ''
      c =
        0 > c
          ? ''
          : 0 == c
            ? ';expires=' + new Date(1970, 1, 1).toUTCString()
            : ';expires=' + new Date(Aa() + 1e3 * c).toUTCString()
      this.a.cookie = a + '=' + b + e + d + c + f
    }
    l.get = function (a, b) {
      for (
        var c = a + '=', d = (this.a.cookie || '').split(';'), e = 0, f;
        e < d.length;
        e++
      ) {
        f = Ua(d[e])
        if (0 == f.lastIndexOf(c, 0)) return f.substr(c.length)
        if (f == a) return ''
      }
      return b
    }
    l.ja = function () {
      return zg(this).keys
    }
    l.la = function () {
      return zg(this).values
    }
    l.clear = function () {
      for (var a = zg(this).keys, b = a.length - 1; 0 <= b; b--) {
        var c = a[b]
        this.get(c)
        this.set(c, '', 0, void 0, void 0)
      }
    }
    function zg(a) {
      a = (a.a.cookie || '').split(';')
      for (var b = [], c = [], d, e, f = 0; f < a.length; f++)
        (e = Ua(a[f])),
          (d = e.indexOf('=')),
          -1 == d
            ? (b.push(''), c.push(e))
            : (b.push(e.substring(0, d)), c.push(e.substring(d + 1)))
      return { keys: b, values: c }
    }
    var Ag = new yg()
    function Bg() {}
    function Cg(a, b, c, d) {
      this.h = 'undefined' !== typeof a && null !== a ? a : -1
      this.g = b || null
      this.a = c || null
      this.j = !!d
    }
    m(Cg, Bg)
    Cg.prototype.set = function (a, b) {
      Ag.set(a, b, this.h, this.g, this.a, this.j)
    }
    Cg.prototype.get = function (a) {
      return Ag.get(a) || null
    }
    Cg.prototype.ra = function (a) {
      var b = this.g,
        c = this.a
      Ag.get(a)
      Ag.set(a, '', 0, b, c)
    }
    function Dg(a, b) {
      this.g = a
      this.a = b || null
    }
    function Eg(a) {
      return { email: a.g, credential: a.a && a.a.toJSON() }
    }
    function Fg(a) {
      if (a && a.email) {
        var b =
          a.credential && firebase.auth.AuthCredential.fromJSON(a.credential)
        return new Dg(a.email, b)
      }
      return null
    }
    function Gg(a) {
      this.a = a || null
    }
    function Hg(a) {
      for (var b = [], c = 0, d = 0; d < a.length; d++) {
        var e = a.charCodeAt(d)
        255 < e && ((b[c++] = e & 255), (e >>= 8))
        b[c++] = e
      }
      return b
    }
    function Ig(a) {
      return Ka(a, function (b) {
        b = b.toString(16)
        return 1 < b.length ? b : '0' + b
      }).join('')
    }
    function Jg(a) {
      this.i = a
      this.g = this.i.length / 4
      this.j = this.g + 6
      this.h = [[], [], [], []]
      this.s = [[], [], [], []]
      this.a = Array(Kg * (this.j + 1))
      for (a = 0; a < this.g; a++)
        this.a[a] = [
          this.i[4 * a],
          this.i[4 * a + 1],
          this.i[4 * a + 2],
          this.i[4 * a + 3],
        ]
      var b = Array(4)
      for (a = this.g; a < Kg * (this.j + 1); a++) {
        b[0] = this.a[a - 1][0]
        b[1] = this.a[a - 1][1]
        b[2] = this.a[a - 1][2]
        b[3] = this.a[a - 1][3]
        if (0 == a % this.g) {
          var c = b,
            d = c[0]
          c[0] = c[1]
          c[1] = c[2]
          c[2] = c[3]
          c[3] = d
          Lg(b)
          b[0] ^= Mg[a / this.g][0]
          b[1] ^= Mg[a / this.g][1]
          b[2] ^= Mg[a / this.g][2]
          b[3] ^= Mg[a / this.g][3]
        } else 6 < this.g && 4 == a % this.g && Lg(b)
        this.a[a] = Array(4)
        this.a[a][0] = this.a[a - this.g][0] ^ b[0]
        this.a[a][1] = this.a[a - this.g][1] ^ b[1]
        this.a[a][2] = this.a[a - this.g][2] ^ b[2]
        this.a[a][3] = this.a[a - this.g][3] ^ b[3]
      }
    }
    Jg.prototype.A = 16
    var Kg = Jg.prototype.A / 4
    function Ng(a, b) {
      for (var c, d = 0; d < Kg; d++)
        for (var e = 0; 4 > e; e++) (c = 4 * e + d), (c = b[c]), (a.h[d][e] = c)
    }
    function Og(a) {
      for (var b = [], c = 0; c < Kg; c++)
        for (var d = 0; 4 > d; d++) b[4 * d + c] = a.h[c][d]
      return b
    }
    function Pg(a, b) {
      for (var c = 0; 4 > c; c++)
        for (var d = 0; 4 > d; d++) a.h[c][d] ^= a.a[4 * b + d][c]
    }
    function Qg(a, b) {
      for (var c = 0; 4 > c; c++)
        for (var d = 0; 4 > d; d++) a.h[c][d] = b[a.h[c][d]]
    }
    function Rg(a) {
      for (var b = 1; 4 > b; b++)
        for (var c = 0; 4 > c; c++) a.s[b][c] = a.h[b][c]
      for (b = 1; 4 > b; b++)
        for (c = 0; 4 > c; c++) a.h[b][c] = a.s[b][(c + b) % Kg]
    }
    function Sg(a) {
      for (var b = 1; 4 > b; b++)
        for (var c = 0; 4 > c; c++) a.s[b][(c + b) % Kg] = a.h[b][c]
      for (b = 1; 4 > b; b++) for (c = 0; 4 > c; c++) a.h[b][c] = a.s[b][c]
    }
    function Lg(a) {
      a[0] = Tg[a[0]]
      a[1] = Tg[a[1]]
      a[2] = Tg[a[2]]
      a[3] = Tg[a[3]]
    }
    var Tg = [
        99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215, 171,
        118, 202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162, 175, 156, 164,
        114, 192, 183, 253, 147, 38, 54, 63, 247, 204, 52, 165, 229, 241, 113,
        216, 49, 21, 4, 199, 35, 195, 24, 150, 5, 154, 7, 18, 128, 226, 235, 39,
        178, 117, 9, 131, 44, 26, 27, 110, 90, 160, 82, 59, 214, 179, 41, 227,
        47, 132, 83, 209, 0, 237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76,
        88, 207, 208, 239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60,
        159, 168, 81, 163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16,
        255, 243, 210, 205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126, 61,
        100, 93, 25, 115, 96, 129, 79, 220, 34, 42, 144, 136, 70, 238, 184, 20,
        222, 94, 11, 219, 224, 50, 58, 10, 73, 6, 36, 92, 194, 211, 172, 98,
        145, 149, 228, 121, 231, 200, 55, 109, 141, 213, 78, 169, 108, 86, 244,
        234, 101, 122, 174, 8, 186, 120, 37, 46, 28, 166, 180, 198, 232, 221,
        116, 31, 75, 189, 139, 138, 112, 62, 181, 102, 72, 3, 246, 14, 97, 53,
        87, 185, 134, 193, 29, 158, 225, 248, 152, 17, 105, 217, 142, 148, 155,
        30, 135, 233, 206, 85, 40, 223, 140, 161, 137, 13, 191, 230, 66, 104,
        65, 153, 45, 15, 176, 84, 187, 22,
      ],
      Ug = [
        82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243, 215, 251,
        124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68, 196, 222, 233,
        203, 84, 123, 148, 50, 166, 194, 35, 61, 238, 76, 149, 11, 66, 250, 195,
        78, 8, 46, 161, 102, 40, 217, 36, 178, 118, 91, 162, 73, 109, 139, 209,
        37, 114, 248, 246, 100, 134, 104, 152, 22, 212, 164, 92, 204, 93, 101,
        182, 146, 108, 112, 72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167,
        141, 157, 132, 144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5,
        184, 179, 69, 6, 208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1,
        19, 138, 107, 58, 145, 17, 65, 79, 103, 220, 234, 151, 242, 207, 206,
        240, 180, 230, 115, 150, 172, 116, 34, 231, 173, 53, 133, 226, 249, 55,
        232, 28, 117, 223, 110, 71, 241, 26, 113, 29, 41, 197, 137, 111, 183,
        98, 14, 170, 24, 190, 27, 252, 86, 62, 75, 198, 210, 121, 32, 154, 219,
        192, 254, 120, 205, 90, 244, 31, 221, 168, 51, 136, 7, 199, 49, 177, 18,
        16, 89, 39, 128, 236, 95, 96, 81, 127, 169, 25, 181, 74, 13, 45, 229,
        122, 159, 147, 201, 156, 239, 160, 224, 59, 77, 174, 42, 245, 176, 200,
        235, 187, 60, 131, 83, 153, 97, 23, 43, 4, 126, 186, 119, 214, 38, 225,
        105, 20, 99, 85, 33, 12, 125,
      ],
      Mg = [
        [0, 0, 0, 0],
        [1, 0, 0, 0],
        [2, 0, 0, 0],
        [4, 0, 0, 0],
        [8, 0, 0, 0],
        [16, 0, 0, 0],
        [32, 0, 0, 0],
        [64, 0, 0, 0],
        [128, 0, 0, 0],
        [27, 0, 0, 0],
        [54, 0, 0, 0],
      ],
      Vg = [
        0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
        38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72,
        74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100, 102, 104, 106,
        108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 128, 130, 132, 134,
        136, 138, 140, 142, 144, 146, 148, 150, 152, 154, 156, 158, 160, 162,
        164, 166, 168, 170, 172, 174, 176, 178, 180, 182, 184, 186, 188, 190,
        192, 194, 196, 198, 200, 202, 204, 206, 208, 210, 212, 214, 216, 218,
        220, 222, 224, 226, 228, 230, 232, 234, 236, 238, 240, 242, 244, 246,
        248, 250, 252, 254, 27, 25, 31, 29, 19, 17, 23, 21, 11, 9, 15, 13, 3, 1,
        7, 5, 59, 57, 63, 61, 51, 49, 55, 53, 43, 41, 47, 45, 35, 33, 39, 37,
        91, 89, 95, 93, 83, 81, 87, 85, 75, 73, 79, 77, 67, 65, 71, 69, 123,
        121, 127, 125, 115, 113, 119, 117, 107, 105, 111, 109, 99, 97, 103, 101,
        155, 153, 159, 157, 147, 145, 151, 149, 139, 137, 143, 141, 131, 129,
        135, 133, 187, 185, 191, 189, 179, 177, 183, 181, 171, 169, 175, 173,
        163, 161, 167, 165, 219, 217, 223, 221, 211, 209, 215, 213, 203, 201,
        207, 205, 195, 193, 199, 197, 251, 249, 255, 253, 243, 241, 247, 245,
        235, 233, 239, 237, 227, 225, 231, 229,
      ],
      Wg = [
        0, 3, 6, 5, 12, 15, 10, 9, 24, 27, 30, 29, 20, 23, 18, 17, 48, 51, 54,
        53, 60, 63, 58, 57, 40, 43, 46, 45, 36, 39, 34, 33, 96, 99, 102, 101,
        108, 111, 106, 105, 120, 123, 126, 125, 116, 119, 114, 113, 80, 83, 86,
        85, 92, 95, 90, 89, 72, 75, 78, 77, 68, 71, 66, 65, 192, 195, 198, 197,
        204, 207, 202, 201, 216, 219, 222, 221, 212, 215, 210, 209, 240, 243,
        246, 245, 252, 255, 250, 249, 232, 235, 238, 237, 228, 231, 226, 225,
        160, 163, 166, 165, 172, 175, 170, 169, 184, 187, 190, 189, 180, 183,
        178, 177, 144, 147, 150, 149, 156, 159, 154, 153, 136, 139, 142, 141,
        132, 135, 130, 129, 155, 152, 157, 158, 151, 148, 145, 146, 131, 128,
        133, 134, 143, 140, 137, 138, 171, 168, 173, 174, 167, 164, 161, 162,
        179, 176, 181, 182, 191, 188, 185, 186, 251, 248, 253, 254, 247, 244,
        241, 242, 227, 224, 229, 230, 239, 236, 233, 234, 203, 200, 205, 206,
        199, 196, 193, 194, 211, 208, 213, 214, 223, 220, 217, 218, 91, 88, 93,
        94, 87, 84, 81, 82, 67, 64, 69, 70, 79, 76, 73, 74, 107, 104, 109, 110,
        103, 100, 97, 98, 115, 112, 117, 118, 127, 124, 121, 122, 59, 56, 61,
        62, 55, 52, 49, 50, 35, 32, 37, 38, 47, 44, 41, 42, 11, 8, 13, 14, 7, 4,
        1, 2, 19, 16, 21, 22, 31, 28, 25, 26,
      ],
      Xg = [
        0, 9, 18, 27, 36, 45, 54, 63, 72, 65, 90, 83, 108, 101, 126, 119, 144,
        153, 130, 139, 180, 189, 166, 175, 216, 209, 202, 195, 252, 245, 238,
        231, 59, 50, 41, 32, 31, 22, 13, 4, 115, 122, 97, 104, 87, 94, 69, 76,
        171, 162, 185, 176, 143, 134, 157, 148, 227, 234, 241, 248, 199, 206,
        213, 220, 118, 127, 100, 109, 82, 91, 64, 73, 62, 55, 44, 37, 26, 19, 8,
        1, 230, 239, 244, 253, 194, 203, 208, 217, 174, 167, 188, 181, 138, 131,
        152, 145, 77, 68, 95, 86, 105, 96, 123, 114, 5, 12, 23, 30, 33, 40, 51,
        58, 221, 212, 207, 198, 249, 240, 235, 226, 149, 156, 135, 142, 177,
        184, 163, 170, 236, 229, 254, 247, 200, 193, 218, 211, 164, 173, 182,
        191, 128, 137, 146, 155, 124, 117, 110, 103, 88, 81, 74, 67, 52, 61, 38,
        47, 16, 25, 2, 11, 215, 222, 197, 204, 243, 250, 225, 232, 159, 150,
        141, 132, 187, 178, 169, 160, 71, 78, 85, 92, 99, 106, 113, 120, 15, 6,
        29, 20, 43, 34, 57, 48, 154, 147, 136, 129, 190, 183, 172, 165, 210,
        219, 192, 201, 246, 255, 228, 237, 10, 3, 24, 17, 46, 39, 60, 53, 66,
        75, 80, 89, 102, 111, 116, 125, 161, 168, 179, 186, 133, 140, 151, 158,
        233, 224, 251, 242, 205, 196, 223, 214, 49, 56, 35, 42, 21, 28, 7, 14,
        121, 112, 107, 98, 93, 84, 79, 70,
      ],
      Yg = [
        0, 11, 22, 29, 44, 39, 58, 49, 88, 83, 78, 69, 116, 127, 98, 105, 176,
        187, 166, 173, 156, 151, 138, 129, 232, 227, 254, 245, 196, 207, 210,
        217, 123, 112, 109, 102, 87, 92, 65, 74, 35, 40, 53, 62, 15, 4, 25, 18,
        203, 192, 221, 214, 231, 236, 241, 250, 147, 152, 133, 142, 191, 180,
        169, 162, 246, 253, 224, 235, 218, 209, 204, 199, 174, 165, 184, 179,
        130, 137, 148, 159, 70, 77, 80, 91, 106, 97, 124, 119, 30, 21, 8, 3, 50,
        57, 36, 47, 141, 134, 155, 144, 161, 170, 183, 188, 213, 222, 195, 200,
        249, 242, 239, 228, 61, 54, 43, 32, 17, 26, 7, 12, 101, 110, 115, 120,
        73, 66, 95, 84, 247, 252, 225, 234, 219, 208, 205, 198, 175, 164, 185,
        178, 131, 136, 149, 158, 71, 76, 81, 90, 107, 96, 125, 118, 31, 20, 9,
        2, 51, 56, 37, 46, 140, 135, 154, 145, 160, 171, 182, 189, 212, 223,
        194, 201, 248, 243, 238, 229, 60, 55, 42, 33, 16, 27, 6, 13, 100, 111,
        114, 121, 72, 67, 94, 85, 1, 10, 23, 28, 45, 38, 59, 48, 89, 82, 79, 68,
        117, 126, 99, 104, 177, 186, 167, 172, 157, 150, 139, 128, 233, 226,
        255, 244, 197, 206, 211, 216, 122, 113, 108, 103, 86, 93, 64, 75, 34,
        41, 52, 63, 14, 5, 24, 19, 202, 193, 220, 215, 230, 237, 240, 251, 146,
        153, 132, 143, 190, 181, 168, 163,
      ],
      Zg = [
        0, 13, 26, 23, 52, 57, 46, 35, 104, 101, 114, 127, 92, 81, 70, 75, 208,
        221, 202, 199, 228, 233, 254, 243, 184, 181, 162, 175, 140, 129, 150,
        155, 187, 182, 161, 172, 143, 130, 149, 152, 211, 222, 201, 196, 231,
        234, 253, 240, 107, 102, 113, 124, 95, 82, 69, 72, 3, 14, 25, 20, 55,
        58, 45, 32, 109, 96, 119, 122, 89, 84, 67, 78, 5, 8, 31, 18, 49, 60, 43,
        38, 189, 176, 167, 170, 137, 132, 147, 158, 213, 216, 207, 194, 225,
        236, 251, 246, 214, 219, 204, 193, 226, 239, 248, 245, 190, 179, 164,
        169, 138, 135, 144, 157, 6, 11, 28, 17, 50, 63, 40, 37, 110, 99, 116,
        121, 90, 87, 64, 77, 218, 215, 192, 205, 238, 227, 244, 249, 178, 191,
        168, 165, 134, 139, 156, 145, 10, 7, 16, 29, 62, 51, 36, 41, 98, 111,
        120, 117, 86, 91, 76, 65, 97, 108, 123, 118, 85, 88, 79, 66, 9, 4, 19,
        30, 61, 48, 39, 42, 177, 188, 171, 166, 133, 136, 159, 146, 217, 212,
        195, 206, 237, 224, 247, 250, 183, 186, 173, 160, 131, 142, 153, 148,
        223, 210, 197, 200, 235, 230, 241, 252, 103, 106, 125, 112, 83, 94, 73,
        68, 15, 2, 21, 24, 59, 54, 33, 44, 12, 1, 22, 27, 56, 53, 34, 47, 100,
        105, 126, 115, 80, 93, 74, 71, 220, 209, 198, 203, 232, 229, 242, 255,
        180, 185, 174, 163, 128, 141, 154, 151,
      ],
      $g = [
        0, 14, 28, 18, 56, 54, 36, 42, 112, 126, 108, 98, 72, 70, 84, 90, 224,
        238, 252, 242, 216, 214, 196, 202, 144, 158, 140, 130, 168, 166, 180,
        186, 219, 213, 199, 201, 227, 237, 255, 241, 171, 165, 183, 185, 147,
        157, 143, 129, 59, 53, 39, 41, 3, 13, 31, 17, 75, 69, 87, 89, 115, 125,
        111, 97, 173, 163, 177, 191, 149, 155, 137, 135, 221, 211, 193, 207,
        229, 235, 249, 247, 77, 67, 81, 95, 117, 123, 105, 103, 61, 51, 33, 47,
        5, 11, 25, 23, 118, 120, 106, 100, 78, 64, 82, 92, 6, 8, 26, 20, 62, 48,
        34, 44, 150, 152, 138, 132, 174, 160, 178, 188, 230, 232, 250, 244, 222,
        208, 194, 204, 65, 79, 93, 83, 121, 119, 101, 107, 49, 63, 45, 35, 9, 7,
        21, 27, 161, 175, 189, 179, 153, 151, 133, 139, 209, 223, 205, 195, 233,
        231, 245, 251, 154, 148, 134, 136, 162, 172, 190, 176, 234, 228, 246,
        248, 210, 220, 206, 192, 122, 116, 102, 104, 66, 76, 94, 80, 10, 4, 22,
        24, 50, 60, 46, 32, 236, 226, 240, 254, 212, 218, 200, 198, 156, 146,
        128, 142, 164, 170, 184, 182, 12, 2, 16, 30, 52, 58, 40, 38, 124, 114,
        96, 110, 68, 74, 88, 86, 55, 57, 43, 37, 15, 1, 19, 29, 71, 73, 91, 85,
        127, 113, 99, 109, 215, 217, 203, 197, 239, 225, 243, 253, 167, 169,
        187, 181, 159, 145, 131, 141,
      ]
    function ah(a, b) {
      a = new Jg(bh(a))
      b = Hg(b)
      for (var c = b.splice(0, 16), d = '', e; c.length; ) {
        e = 16 - c.length
        for (var f = 0; f < e; f++) c.push(0)
        e = a
        Ng(e, c)
        Pg(e, 0)
        for (c = 1; c < e.j; ++c) {
          Qg(e, Tg)
          Rg(e)
          f = e.h
          for (var g = e.s[0], h = 0; 4 > h; h++)
            (g[0] = f[0][h]),
              (g[1] = f[1][h]),
              (g[2] = f[2][h]),
              (g[3] = f[3][h]),
              (f[0][h] = Vg[g[0]] ^ Wg[g[1]] ^ g[2] ^ g[3]),
              (f[1][h] = g[0] ^ Vg[g[1]] ^ Wg[g[2]] ^ g[3]),
              (f[2][h] = g[0] ^ g[1] ^ Vg[g[2]] ^ Wg[g[3]]),
              (f[3][h] = Wg[g[0]] ^ g[1] ^ g[2] ^ Vg[g[3]])
          Pg(e, c)
        }
        Qg(e, Tg)
        Rg(e)
        Pg(e, e.j)
        d += Ig(Og(e))
        c = b.splice(0, 16)
      }
      return d
    }
    function ch(a, b) {
      a = new Jg(bh(a))
      for (var c = [], d = 0; d < b.length; d += 2)
        c.push(parseInt(b.substring(d, d + 2), 16))
      var e = c.splice(0, 16)
      for (b = ''; e.length; ) {
        d = a
        Ng(d, e)
        Pg(d, d.j)
        for (e = 1; e < d.j; ++e) {
          Sg(d)
          Qg(d, Ug)
          Pg(d, d.j - e)
          for (var f = d.h, g = d.s[0], h = 0; 4 > h; h++)
            (g[0] = f[0][h]),
              (g[1] = f[1][h]),
              (g[2] = f[2][h]),
              (g[3] = f[3][h]),
              (f[0][h] = $g[g[0]] ^ Yg[g[1]] ^ Zg[g[2]] ^ Xg[g[3]]),
              (f[1][h] = Xg[g[0]] ^ $g[g[1]] ^ Yg[g[2]] ^ Zg[g[3]]),
              (f[2][h] = Zg[g[0]] ^ Xg[g[1]] ^ $g[g[2]] ^ Yg[g[3]]),
              (f[3][h] = Yg[g[0]] ^ Zg[g[1]] ^ Xg[g[2]] ^ $g[g[3]])
        }
        Sg(d)
        Qg(d, Ug)
        Pg(d, 0)
        d = Og(d)
        if (8192 >= d.length) d = String.fromCharCode.apply(null, d)
        else {
          e = ''
          for (f = 0; f < d.length; f += 8192)
            e += String.fromCharCode.apply(null, Ta(d, f, f + 8192))
          d = e
        }
        b += d
        e = c.splice(0, 16)
      }
      return b.replace(/(\x00)+$/, '')
    }
    function bh(a) {
      a = Hg(a.substring(0, 32))
      for (var b = 32 - a.length, c = 0; c < b; c++) a.push(0)
      return a
    }
    function dh(a) {
      var b = []
      eh(new fh(), a, b)
      return b.join('')
    }
    function fh() {}
    function eh(a, b, c) {
      if (null == b) c.push('null')
      else {
        if ('object' == typeof b) {
          if (qa(b)) {
            var d = b
            b = d.length
            c.push('[')
            for (var e = '', f = 0; f < b; f++)
              c.push(e), eh(a, d[f], c), (e = ',')
            c.push(']')
            return
          }
          if (
            b instanceof String ||
            b instanceof Number ||
            b instanceof Boolean
          )
            b = b.valueOf()
          else {
            c.push('{')
            e = ''
            for (d in b)
              Object.prototype.hasOwnProperty.call(b, d) &&
                ((f = b[d]),
                'function' != typeof f &&
                  (c.push(e), gh(d, c), c.push(':'), eh(a, f, c), (e = ',')))
            c.push('}')
            return
          }
        }
        switch (typeof b) {
          case 'string':
            gh(b, c)
            break
          case 'number':
            c.push(isFinite(b) && !isNaN(b) ? String(b) : 'null')
            break
          case 'boolean':
            c.push(String(b))
            break
          case 'function':
            c.push('null')
            break
          default:
            throw Error('Unknown type: ' + typeof b)
        }
      }
    }
    var hh = {
        '"': '\\"',
        '\\': '\\\\',
        '/': '\\/',
        '\b': '\\b',
        '\f': '\\f',
        '\n': '\\n',
        '\r': '\\r',
        '\t': '\\t',
        '\x0B': '\\u000b',
      },
      ih = /\uffff/.test('\uffff')
        ? /[\\"\x00-\x1f\x7f-\uffff]/g
        : /[\\"\x00-\x1f\x7f-\xff]/g
    function gh(a, b) {
      b.push(
        '"',
        a.replace(ih, function (c) {
          var d = hh[c]
          d ||
            ((d = '\\u' + (c.charCodeAt(0) | 65536).toString(16).substr(1)),
            (hh[c] = d))
          return d
        }),
        '"'
      )
    }
    function jh(a) {
      this.a = a
    }
    jh.prototype.set = function (a, b) {
      ka(b) ? this.a.set(a, dh(b)) : this.a.ra(a)
    }
    jh.prototype.get = function (a) {
      try {
        var b = this.a.get(a)
      } catch (c) {
        return
      }
      if (null !== b)
        try {
          return JSON.parse(b)
        } catch (c$2) {
          throw 'Storage: Invalid value was encountered'
        }
    }
    function kh() {}
    w(kh, Bg)
    kh.prototype.clear = function () {
      var a = lb(this.ha(!0)),
        b = this
      Ha(a, function (c) {
        b.ra(c)
      })
    }
    function lh(a) {
      this.a = a
    }
    w(lh, kh)
    function mh(a) {
      if (!a.a) return !1
      try {
        return a.a.setItem('__sak', '1'), a.a.removeItem('__sak'), !0
      } catch (b) {
        return !1
      }
    }
    l = lh.prototype
    l.set = function (a, b) {
      try {
        this.a.setItem(a, b)
      } catch (c) {
        if (0 == this.a.length) throw 'Storage mechanism: Storage disabled'
        throw 'Storage mechanism: Quota exceeded'
      }
    }
    l.get = function (a) {
      a = this.a.getItem(a)
      if (!q(a) && null !== a)
        throw 'Storage mechanism: Invalid value was encountered'
      return a
    }
    l.ra = function (a) {
      this.a.removeItem(a)
    }
    l.ha = function (a) {
      var b = 0,
        c = this.a,
        d = new ib()
      d.next = function () {
        if (b >= c.length) throw hb
        var e = c.key(b++)
        if (a) return e
        e = c.getItem(e)
        if (!q(e)) throw 'Storage mechanism: Invalid value was encountered'
        return e
      }
      return d
    }
    l.clear = function () {
      this.a.clear()
    }
    l.key = function (a) {
      return this.a.key(a)
    }
    function nh() {
      var a = null
      try {
        a = window.localStorage || null
      } catch (b) {}
      this.a = a
    }
    w(nh, lh)
    function oh() {
      var a = null
      try {
        a = window.sessionStorage || null
      } catch (b) {}
      this.a = a
    }
    w(oh, lh)
    function ph(a, b) {
      this.g = a
      this.a = b + '::'
    }
    w(ph, kh)
    ph.prototype.set = function (a, b) {
      this.g.set(this.a + a, b)
    }
    ph.prototype.get = function (a) {
      return this.g.get(this.a + a)
    }
    ph.prototype.ra = function (a) {
      this.g.ra(this.a + a)
    }
    ph.prototype.ha = function (a) {
      var b = this.g.ha(!0),
        c = this,
        d = new ib()
      d.next = function () {
        for (var e = b.next(); e.substr(0, c.a.length) != c.a; ) e = b.next()
        return a ? e.substr(c.a.length) : c.g.get(e)
      }
      return d
    }
    mh(new nh())
    var qh,
      rh = new oh()
    qh = mh(rh) ? new ph(rh, 'firebaseui') : null
    var sh = new jh(qh),
      th = { name: 'pendingEmailCredential', storage: sh },
      uh = { name: 'redirectStatus', storage: sh },
      vh = { name: 'redirectUrl', storage: sh },
      wh = { name: 'emailForSignIn', storage: new jh(new Cg(3600, '/')) },
      xh = {
        name: 'pendingEncryptedCredential',
        storage: new jh(new Cg(3600, '/')),
      }
    function yh(a, b) {
      return a.storage.get(b ? a.name + ':' + b : a.name)
    }
    function zh(a, b) {
      a.storage.a.ra(b ? a.name + ':' + b : a.name)
    }
    function Ah(a, b, c) {
      a.storage.set(c ? a.name + ':' + c : a.name, b)
    }
    function Bh(a) {
      return yh(vh, a) || null
    }
    function Ch(a) {
      a = yh(th, a) || null
      return Fg(a)
    }
    function Dh(a) {
      zh(th, a)
    }
    function Eh(a, b) {
      Ah(th, Eg(a), b)
    }
    function Fh(a) {
      return (a = yh(uh, a) || null) && 'undefined' !== typeof a.tenantId
        ? new Gg(a.tenantId)
        : null
    }
    function Gh(a, b) {
      Ah(uh, { tenantId: a.a }, b)
    }
    function Hh(a, b) {
      b = yh(wh, b)
      var c = null
      if (b)
        try {
          var d = ch(a, b),
            e = JSON.parse(d)
          c = (e && e.email) || null
        } catch (f) {}
      return c
    }
    function Ih(a, b) {
      b = yh(xh, b)
      var c = null
      if (b)
        try {
          var d = ch(a, b)
          c = JSON.parse(d)
        } catch (e) {}
      return Fg(c || null)
    }
    function Jh(a, b, c) {
      Ah(xh, ah(a, JSON.stringify(Eg(b))), c)
    }
    function Kh() {
      this.W = {}
    }
    function G(a, b, c) {
      if (b.toLowerCase() in a.W)
        throw Error('Configuration ' + b + ' has already been defined.')
      a.W[b.toLowerCase()] = c
    }
    function Lh(a, b, c) {
      if (!(b.toLowerCase() in a.W))
        throw Error('Configuration ' + b + ' is not defined.')
      a.W[b.toLowerCase()] = c
    }
    Kh.prototype.get = function (a) {
      if (!(a.toLowerCase() in this.W))
        throw Error('Configuration ' + a + ' is not defined.')
      return this.W[a.toLowerCase()]
    }
    function Mh(a, b) {
      a = a.get(b)
      if (!a) throw Error('Configuration ' + b + ' is required.')
      return a
    }
    function Nh() {
      this.g = void 0
      this.a = {}
    }
    l = Nh.prototype
    l.set = function (a, b) {
      Oh(this, a, b, !1)
    }
    l.add = function (a, b) {
      Oh(this, a, b, !0)
    }
    function Oh(a, b, c, d) {
      for (var e = 0; e < b.length; e++) {
        var f = b.charAt(e)
        a.a[f] || (a.a[f] = new Nh())
        a = a.a[f]
      }
      if (d && void 0 !== a.g)
        throw Error('The collection already contains the key "' + b + '"')
      a.g = c
    }
    l.get = function (a) {
      a: {
        for (var b = this, c = 0; c < a.length; c++)
          if (((b = b.a[a.charAt(c)]), !b)) {
            a = void 0
            break a
          }
        a = b
      }
      return a ? a.g : void 0
    }
    l.la = function () {
      var a = []
      Ph(this, a)
      return a
    }
    function Ph(a, b) {
      void 0 !== a.g && b.push(a.g)
      for (var c in a.a) Ph(a.a[c], b)
    }
    l.ja = function () {
      var a = []
      Qh(this, '', a)
      return a
    }
    function Qh(a, b, c) {
      void 0 !== a.g && c.push(b)
      for (var d in a.a) Qh(a.a[d], b + d, c)
    }
    l.clear = function () {
      this.a = {}
      this.g = void 0
    }
    function Rh(a) {
      this.a = a
      this.g = new Nh()
      for (a = 0; a < this.a.length; a++) {
        var b = this.g.get('+' + this.a[a].b)
        b ? b.push(this.a[a]) : this.g.add('+' + this.a[a].b, [this.a[a]])
      }
    }
    function Sh(a, b) {
      a = a.g
      var c = {},
        d = 0
      void 0 !== a.g && (c[d] = a.g)
      for (; d < b.length; d++) {
        var e = b.charAt(d)
        if (!(e in a.a)) break
        a = a.a[e]
        void 0 !== a.g && (c[d] = a.g)
      }
      for (var f in c) if (c.hasOwnProperty(f)) return c[f]
      return []
    }
    function Th(a) {
      for (var b = 0; b < Uh.length; b++) if (Uh[b].c === a) return Uh[b]
      return null
    }
    function Vh(a) {
      a = a.toUpperCase()
      for (var b = [], c = 0; c < Uh.length; c++) Uh[c].f === a && b.push(Uh[c])
      return b
    }
    function Wh(a) {
      if (0 < a.length && '+' == a.charAt(0)) {
        a = a.substring(1)
        for (var b = [], c = 0; c < Uh.length; c++)
          Uh[c].b == a && b.push(Uh[c])
        a = b
      } else a = Vh(a)
      return a
    }
    function Xh(a) {
      a.sort(function (b, c) {
        return b.name.localeCompare(c.name, 'cs')
      })
    }
    var Uh = [
      { name: 'Afgh\u00e1nist\u00e1n', c: '93-AF-0', b: '93', f: 'AF' },
      { name: 'Alandy', c: '358-AX-0', b: '358', f: 'AX' },
      { name: 'Alb\u00e1nie', c: '355-AL-0', b: '355', f: 'AL' },
      { name: 'Al\u017e\u00edrsko', c: '213-DZ-0', b: '213', f: 'DZ' },
      { name: 'Americk\u00e1 Samoa', c: '1-AS-0', b: '1', f: 'AS' },
      { name: 'Andora', c: '376-AD-0', b: '376', f: 'AD' },
      { name: 'Angola', c: '244-AO-0', b: '244', f: 'AO' },
      { name: 'Anguilla', c: '1-AI-0', b: '1', f: 'AI' },
      { name: 'Antigua a Barbuda', c: '1-AG-0', b: '1', f: 'AG' },
      { name: 'Argentina', c: '54-AR-0', b: '54', f: 'AR' },
      { name: 'Arm\u00e9nie', c: '374-AM-0', b: '374', f: 'AM' },
      { name: 'Aruba', c: '297-AW-0', b: '297', f: 'AW' },
      { name: 'Ascension', c: '247-AC-0', b: '247', f: 'AC' },
      { name: 'Austr\u00e1lie', c: '61-AU-0', b: '61', f: 'AU' },
      { name: 'Rakousko', c: '43-AT-0', b: '43', f: 'AT' },
      {
        name: '\u00c1zerb\u00e1jd\u017e\u00e1n',
        c: '994-AZ-0',
        b: '994',
        f: 'AZ',
      },
      { name: 'Bahamy', c: '1-BS-0', b: '1', f: 'BS' },
      { name: 'Bahrajn', c: '973-BH-0', b: '973', f: 'BH' },
      { name: 'Banglad\u00e9\u0161', c: '880-BD-0', b: '880', f: 'BD' },
      { name: 'Barbados', c: '1-BB-0', b: '1', f: 'BB' },
      { name: 'B\u011blorusko', c: '375-BY-0', b: '375', f: 'BY' },
      { name: 'Belgie', c: '32-BE-0', b: '32', f: 'BE' },
      { name: 'Belize', c: '501-BZ-0', b: '501', f: 'BZ' },
      { name: 'Benin', c: '229-BJ-0', b: '229', f: 'BJ' },
      { name: 'Bermudy', c: '1-BM-0', b: '1', f: 'BM' },
      { name: 'Bh\u00fat\u00e1n', c: '975-BT-0', b: '975', f: 'BT' },
      { name: 'Bol\u00edvie', c: '591-BO-0', b: '591', f: 'BO' },
      { name: 'Bosna a Hercegovina', c: '387-BA-0', b: '387', f: 'BA' },
      { name: 'Botswana', c: '267-BW-0', b: '267', f: 'BW' },
      { name: 'Braz\u00edlie', c: '55-BR-0', b: '55', f: 'BR' },
      {
        name: 'Britsk\u00e9 indickooce\u00e1nsk\u00e9 \u00fazem\u00ed',
        c: '246-IO-0',
        b: '246',
        f: 'IO',
      },
      {
        name: 'Britsk\u00e9 Panensk\u00e9 ostrovy',
        c: '1-VG-0',
        b: '1',
        f: 'VG',
      },
      { name: 'Brunej', c: '673-BN-0', b: '673', f: 'BN' },
      { name: 'Bulharsko', c: '359-BG-0', b: '359', f: 'BG' },
      { name: 'Burkina Faso', c: '226-BF-0', b: '226', f: 'BF' },
      { name: 'Burundi', c: '257-BI-0', b: '257', f: 'BI' },
      { name: 'Kambod\u017ea', c: '855-KH-0', b: '855', f: 'KH' },
      { name: 'Kamerun', c: '237-CM-0', b: '237', f: 'CM' },
      { name: 'Kanada', c: '1-CA-0', b: '1', f: 'CA' },
      { name: 'Kapverdy', c: '238-CV-0', b: '238', f: 'CV' },
      { name: 'Karibsk\u00e9 Nizozemsko', c: '599-BQ-0', b: '599', f: 'BQ' },
      { name: 'Kajmansk\u00e9 ostrovy', c: '1-KY-0', b: '1', f: 'KY' },
      {
        name: 'St\u0159edoafrick\u00e1 republika',
        c: '236-CF-0',
        b: '236',
        f: 'CF',
      },
      { name: '\u010cad', c: '235-TD-0', b: '235', f: 'TD' },
      { name: 'Chile', c: '56-CL-0', b: '56', f: 'CL' },
      { name: '\u010c\u00edna', c: '86-CN-0', b: '86', f: 'CN' },
      { name: 'V\u00e1no\u010dn\u00ed ostrov', c: '61-CX-0', b: '61', f: 'CX' },
      { name: 'Kokosov\u00e9 ostrovy', c: '61-CC-0', b: '61', f: 'CC' },
      { name: 'Kolumbie', c: '57-CO-0', b: '57', f: 'CO' },
      { name: 'Komory', c: '269-KM-0', b: '269', f: 'KM' },
      {
        name: 'Demokratick\u00e1 republika Kongo',
        c: '243-CD-0',
        b: '243',
        f: 'CD',
      },
      { name: 'Kon\u017esk\u00e1 republika', c: '242-CG-0', b: '242', f: 'CG' },
      { name: 'Cookovy ostrovy', c: '682-CK-0', b: '682', f: 'CK' },
      { name: 'Kostarika', c: '506-CR-0', b: '506', f: 'CR' },
      {
        name: 'Pob\u0159e\u017e\u00ed slonoviny',
        c: '225-CI-0',
        b: '225',
        f: 'CI',
      },
      { name: 'Chorvatsko', c: '385-HR-0', b: '385', f: 'HR' },
      { name: 'Kuba', c: '53-CU-0', b: '53', f: 'CU' },
      { name: 'Cura\u00e7ao', c: '599-CW-0', b: '599', f: 'CW' },
      { name: 'Kypr', c: '357-CY-0', b: '357', f: 'CY' },
      { name: '\u010cesko', c: '420-CZ-0', b: '420', f: 'CZ' },
      { name: 'D\u00e1nsko', c: '45-DK-0', b: '45', f: 'DK' },
      { name: 'D\u017eibutsko', c: '253-DJ-0', b: '253', f: 'DJ' },
      { name: 'Dominika', c: '1-DM-0', b: '1', f: 'DM' },
      {
        name: 'Dominik\u00e1nsk\u00e1 republika',
        c: '1-DO-0',
        b: '1',
        f: 'DO',
      },
      { name: 'V\u00fdchodn\u00ed Timor', c: '670-TL-0', b: '670', f: 'TL' },
      { name: 'Ekv\u00e1dor', c: '593-EC-0', b: '593', f: 'EC' },
      { name: 'Egypt', c: '20-EG-0', b: '20', f: 'EG' },
      { name: 'Salvador', c: '503-SV-0', b: '503', f: 'SV' },
      { name: 'Rovn\u00edkov\u00e1 Guinea', c: '240-GQ-0', b: '240', f: 'GQ' },
      { name: 'Eritrea', c: '291-ER-0', b: '291', f: 'ER' },
      { name: 'Estonsko', c: '372-EE-0', b: '372', f: 'EE' },
      { name: 'Etiopie', c: '251-ET-0', b: '251', f: 'ET' },
      {
        name: 'Falklandsk\u00e9 ostrovy (Malv\u00edny)',
        c: '500-FK-0',
        b: '500',
        f: 'FK',
      },
      { name: 'Faersk\u00e9 ostrovy', c: '298-FO-0', b: '298', f: 'FO' },
      { name: 'Fid\u017ei', c: '679-FJ-0', b: '679', f: 'FJ' },
      { name: 'Finsko', c: '358-FI-0', b: '358', f: 'FI' },
      { name: 'Francie', c: '33-FR-0', b: '33', f: 'FR' },
      { name: 'Francouzsk\u00e1 Guyana', c: '594-GF-0', b: '594', f: 'GF' },
      {
        name: 'Francouzsk\u00e1 Polyn\u00e9sie',
        c: '689-PF-0',
        b: '689',
        f: 'PF',
      },
      { name: 'Gabun', c: '241-GA-0', b: '241', f: 'GA' },
      { name: 'Gambie', c: '220-GM-0', b: '220', f: 'GM' },
      { name: 'Gruzie', c: '995-GE-0', b: '995', f: 'GE' },
      { name: 'N\u011bmecko', c: '49-DE-0', b: '49', f: 'DE' },
      { name: 'Ghana', c: '233-GH-0', b: '233', f: 'GH' },
      { name: 'Gibraltar', c: '350-GI-0', b: '350', f: 'GI' },
      { name: '\u0158ecko', c: '30-GR-0', b: '30', f: 'GR' },
      { name: 'Gr\u00f3nsko', c: '299-GL-0', b: '299', f: 'GL' },
      { name: 'Grenada', c: '1-GD-0', b: '1', f: 'GD' },
      { name: 'Guadeloupe', c: '590-GP-0', b: '590', f: 'GP' },
      { name: 'Guam', c: '1-GU-0', b: '1', f: 'GU' },
      { name: 'Guatemala', c: '502-GT-0', b: '502', f: 'GT' },
      { name: 'Guernsey', c: '44-GG-0', b: '44', f: 'GG' },
      { name: 'Conakry, Guinea', c: '224-GN-0', b: '224', f: 'GN' },
      { name: 'Guinea Bissau', c: '245-GW-0', b: '245', f: 'GW' },
      { name: 'Guayana', c: '592-GY-0', b: '592', f: 'GY' },
      { name: 'Haiti', c: '509-HT-0', b: '509', f: 'HT' },
      {
        name: 'Heard\u016fv ostrov a McDonaldovy ostrovy',
        c: '672-HM-0',
        b: '672',
        f: 'HM',
      },
      { name: 'Honduras', c: '504-HN-0', b: '504', f: 'HN' },
      { name: 'Hongkong', c: '852-HK-0', b: '852', f: 'HK' },
      { name: 'Ma\u010farsko', c: '36-HU-0', b: '36', f: 'HU' },
      { name: 'Island', c: '354-IS-0', b: '354', f: 'IS' },
      { name: 'Indie', c: '91-IN-0', b: '91', f: 'IN' },
      { name: 'Indon\u00e9sie', c: '62-ID-0', b: '62', f: 'ID' },
      { name: '\u00cdr\u00e1n', c: '98-IR-0', b: '98', f: 'IR' },
      { name: 'Ir\u00e1k', c: '964-IQ-0', b: '964', f: 'IQ' },
      { name: 'Irsko', c: '353-IE-0', b: '353', f: 'IE' },
      { name: 'Ostrov Man', c: '44-IM-0', b: '44', f: 'IM' },
      { name: 'Izrael', c: '972-IL-0', b: '972', f: 'IL' },
      { name: 'It\u00e1lie', c: '39-IT-0', b: '39', f: 'IT' },
      { name: 'Jamajka', c: '1-JM-0', b: '1', f: 'JM' },
      { name: 'Japonsko', c: '81-JP-0', b: '81', f: 'JP' },
      { name: 'Jersey', c: '44-JE-0', b: '44', f: 'JE' },
      { name: 'Jord\u00e1nsko', c: '962-JO-0', b: '962', f: 'JO' },
      { name: 'Kazachst\u00e1n', c: '7-KZ-0', b: '7', f: 'KZ' },
      { name: 'Ke\u0148a', c: '254-KE-0', b: '254', f: 'KE' },
      { name: 'Kiribati', c: '686-KI-0', b: '686', f: 'KI' },
      { name: 'Kosovo', c: '377-XK-0', b: '377', f: 'XK' },
      { name: 'Kosovo', c: '381-XK-0', b: '381', f: 'XK' },
      { name: 'Kosovo', c: '386-XK-0', b: '386', f: 'XK' },
      { name: 'Kuvajt', c: '965-KW-0', b: '965', f: 'KW' },
      { name: 'Kyrgyzst\u00e1n', c: '996-KG-0', b: '996', f: 'KG' },
      { name: 'Laos', c: '856-LA-0', b: '856', f: 'LA' },
      { name: 'Loty\u0161sko', c: '371-LV-0', b: '371', f: 'LV' },
      { name: 'Libanon', c: '961-LB-0', b: '961', f: 'LB' },
      { name: 'Lesotho', c: '266-LS-0', b: '266', f: 'LS' },
      { name: 'Lib\u00e9rie', c: '231-LR-0', b: '231', f: 'LR' },
      { name: 'Libye', c: '218-LY-0', b: '218', f: 'LY' },
      { name: 'Lichten\u0161tejnsko', c: '423-LI-0', b: '423', f: 'LI' },
      { name: 'Litva', c: '370-LT-0', b: '370', f: 'LT' },
      { name: 'Lucembursko', c: '352-LU-0', b: '352', f: 'LU' },
      { name: 'Macao', c: '853-MO-0', b: '853', f: 'MO' },
      { name: 'Makedonie', c: '389-MK-0', b: '389', f: 'MK' },
      { name: 'Madagaskar', c: '261-MG-0', b: '261', f: 'MG' },
      { name: 'Malawi', c: '265-MW-0', b: '265', f: 'MW' },
      { name: 'Malajsie', c: '60-MY-0', b: '60', f: 'MY' },
      { name: 'Maledivy', c: '960-MV-0', b: '960', f: 'MV' },
      { name: 'Mali', c: '223-ML-0', b: '223', f: 'ML' },
      { name: 'Malta', c: '356-MT-0', b: '356', f: 'MT' },
      { name: 'Marshallovy ostrovy', c: '692-MH-0', b: '692', f: 'MH' },
      { name: 'Martinik', c: '596-MQ-0', b: '596', f: 'MQ' },
      { name: 'Maurit\u00e1nie', c: '222-MR-0', b: '222', f: 'MR' },
      { name: 'Mauricius', c: '230-MU-0', b: '230', f: 'MU' },
      { name: 'Mayotte', c: '262-YT-0', b: '262', f: 'YT' },
      { name: 'Mexiko', c: '52-MX-0', b: '52', f: 'MX' },
      { name: 'Mikron\u00e9sie', c: '691-FM-0', b: '691', f: 'FM' },
      { name: 'Moldavsko', c: '373-MD-0', b: '373', f: 'MD' },
      { name: 'Monako', c: '377-MC-0', b: '377', f: 'MC' },
      { name: 'Mongolsko', c: '976-MN-0', b: '976', f: 'MN' },
      { name: '\u010cern\u00e1 Hora', c: '382-ME-0', b: '382', f: 'ME' },
      { name: 'Montserrat', c: '1-MS-0', b: '1', f: 'MS' },
      { name: 'Maroko', c: '212-MA-0', b: '212', f: 'MA' },
      { name: 'Mosambik', c: '258-MZ-0', b: '258', f: 'MZ' },
      { name: 'Myanmar', c: '95-MM-0', b: '95', f: 'MM' },
      { name: 'Namibie', c: '264-NA-0', b: '264', f: 'NA' },
      { name: 'Nauru', c: '674-NR-0', b: '674', f: 'NR' },
      { name: 'Nep\u00e1l', c: '977-NP-0', b: '977', f: 'NP' },
      { name: 'Nizozemsko', c: '31-NL-0', b: '31', f: 'NL' },
      { name: 'Nov\u00e1 Kaledonie', c: '687-NC-0', b: '687', f: 'NC' },
      { name: 'Nov\u00fd Z\u00e9land', c: '64-NZ-0', b: '64', f: 'NZ' },
      { name: 'Nikaragua', c: '505-NI-0', b: '505', f: 'NI' },
      { name: 'Niger', c: '227-NE-0', b: '227', f: 'NE' },
      { name: 'Nig\u00e9rie', c: '234-NG-0', b: '234', f: 'NG' },
      { name: 'Niue', c: '683-NU-0', b: '683', f: 'NU' },
      { name: 'Norfolk', c: '672-NF-0', b: '672', f: 'NF' },
      { name: 'Severn\u00ed Korea', c: '850-KP-0', b: '850', f: 'KP' },
      {
        name: 'Severn\u00ed Mari\u00e1nsk\u00e9 ostrovy',
        c: '1-MP-0',
        b: '1',
        f: 'MP',
      },
      { name: 'Norsko', c: '47-NO-0', b: '47', f: 'NO' },
      { name: 'Om\u00e1n', c: '968-OM-0', b: '968', f: 'OM' },
      { name: 'P\u00e1kist\u00e1n', c: '92-PK-0', b: '92', f: 'PK' },
      { name: 'Palau', c: '680-PW-0', b: '680', f: 'PW' },
      {
        name: 'Palestinsk\u00e1 \u00fazem\u00ed',
        c: '970-PS-0',
        b: '970',
        f: 'PS',
      },
      { name: 'Panama', c: '507-PA-0', b: '507', f: 'PA' },
      { name: 'Papua Nov\u00e1 Guinea', c: '675-PG-0', b: '675', f: 'PG' },
      { name: 'Paraguay', c: '595-PY-0', b: '595', f: 'PY' },
      { name: 'Peru', c: '51-PE-0', b: '51', f: 'PE' },
      { name: 'Filip\u00edny', c: '63-PH-0', b: '63', f: 'PH' },
      { name: 'Polsko', c: '48-PL-0', b: '48', f: 'PL' },
      { name: 'Portugalsko', c: '351-PT-0', b: '351', f: 'PT' },
      { name: 'Portoriko', c: '1-PR-0', b: '1', f: 'PR' },
      { name: 'Katar', c: '974-QA-0', b: '974', f: 'QA' },
      { name: 'R\u00e9union', c: '262-RE-0', b: '262', f: 'RE' },
      { name: 'Rumunsko', c: '40-RO-0', b: '40', f: 'RO' },
      { name: 'Rusko', c: '7-RU-0', b: '7', f: 'RU' },
      { name: 'Rwanda', c: '250-RW-0', b: '250', f: 'RW' },
      { name: 'Svat\u00fd Bartolom\u011bj', c: '590-BL-0', b: '590', f: 'BL' },
      { name: 'Svat\u00e1 Helena', c: '290-SH-0', b: '290', f: 'SH' },
      { name: 'Sv. Kitts', c: '1-KN-0', b: '1', f: 'KN' },
      { name: 'Svat\u00e1 Lucie', c: '1-LC-0', b: '1', f: 'LC' },
      { name: 'Svat\u00fd Martin', c: '590-MF-0', b: '590', f: 'MF' },
      { name: 'Saint Pierre a Miquelon', c: '508-PM-0', b: '508', f: 'PM' },
      { name: 'Svat\u00fd Vincenc', c: '1-VC-0', b: '1', f: 'VC' },
      { name: 'Samoa', c: '685-WS-0', b: '685', f: 'WS' },
      { name: 'San Marino', c: '378-SM-0', b: '378', f: 'SM' },
      {
        name: 'Svat\u00fd Tom\u00e1\u0161 a Princ\u016fv ostrov',
        c: '239-ST-0',
        b: '239',
        f: 'ST',
      },
      {
        name: 'Sa\u00fadsk\u00e1 Ar\u00e1bie',
        c: '966-SA-0',
        b: '966',
        f: 'SA',
      },
      { name: 'Senegal', c: '221-SN-0', b: '221', f: 'SN' },
      { name: 'Srbsko', c: '381-RS-0', b: '381', f: 'RS' },
      { name: 'Seychelly', c: '248-SC-0', b: '248', f: 'SC' },
      { name: 'Sierra Leone', c: '232-SL-0', b: '232', f: 'SL' },
      { name: 'Singapur', c: '65-SG-0', b: '65', f: 'SG' },
      { name: 'Svat\u00fd Martin', c: '1-SX-0', b: '1', f: 'SX' },
      { name: 'Slovensko', c: '421-SK-0', b: '421', f: 'SK' },
      { name: 'Slovinsko', c: '386-SI-0', b: '386', f: 'SI' },
      { name: '\u0160alamounovy ostrovy', c: '677-SB-0', b: '677', f: 'SB' },
      { name: 'Som\u00e1lsko', c: '252-SO-0', b: '252', f: 'SO' },
      { name: 'Jihoafrick\u00e1 republika', c: '27-ZA-0', b: '27', f: 'ZA' },
      {
        name: 'Ji\u017en\u00ed Georgie a Ji\u017en\u00ed Sandwichovy ostrovy',
        c: '500-GS-0',
        b: '500',
        f: 'GS',
      },
      { name: 'Ji\u017en\u00ed Korea', c: '82-KR-0', b: '82', f: 'KR' },
      {
        name: 'Ji\u017en\u00ed S\u00fad\u00e1n',
        c: '211-SS-0',
        b: '211',
        f: 'SS',
      },
      { name: '\u0160pan\u011blsko', c: '34-ES-0', b: '34', f: 'ES' },
      { name: 'Sr\u00ed Lanka', c: '94-LK-0', b: '94', f: 'LK' },
      { name: 'S\u00fad\u00e1n', c: '249-SD-0', b: '249', f: 'SD' },
      { name: 'Surinam', c: '597-SR-0', b: '597', f: 'SR' },
      {
        name: '\u0160picberky a ostrov Jan Mayen',
        c: '47-SJ-0',
        b: '47',
        f: 'SJ',
      },
      { name: 'Svazijsko', c: '268-SZ-0', b: '268', f: 'SZ' },
      { name: '\u0160v\u00e9dsko', c: '46-SE-0', b: '46', f: 'SE' },
      { name: '\u0160v\u00fdcarsko', c: '41-CH-0', b: '41', f: 'CH' },
      { name: 'S\u00fdrie', c: '963-SY-0', b: '963', f: 'SY' },
      { name: 'Tchaj-wan', c: '886-TW-0', b: '886', f: 'TW' },
      { name: 'T\u00e1d\u017eikist\u00e1n', c: '992-TJ-0', b: '992', f: 'TJ' },
      { name: 'Tanz\u00e1nie', c: '255-TZ-0', b: '255', f: 'TZ' },
      { name: 'Thajsko', c: '66-TH-0', b: '66', f: 'TH' },
      { name: 'Togo', c: '228-TG-0', b: '228', f: 'TG' },
      { name: 'Tokelau', c: '690-TK-0', b: '690', f: 'TK' },
      { name: 'Tonga', c: '676-TO-0', b: '676', f: 'TO' },
      { name: 'Trinidad a Tobago', c: '1-TT-0', b: '1', f: 'TT' },
      { name: 'Tunisko', c: '216-TN-0', b: '216', f: 'TN' },
      { name: 'Turecko', c: '90-TR-0', b: '90', f: 'TR' },
      { name: 'Turkmenist\u00e1n', c: '993-TM-0', b: '993', f: 'TM' },
      { name: 'Turks a Caicos', c: '1-TC-0', b: '1', f: 'TC' },
      { name: 'Tuvalu', c: '688-TV-0', b: '688', f: 'TV' },
      {
        name: 'Americk\u00e9 Panensk\u00e9 ostrovy',
        c: '1-VI-0',
        b: '1',
        f: 'VI',
      },
      { name: 'Uganda', c: '256-UG-0', b: '256', f: 'UG' },
      { name: 'Ukrajina', c: '380-UA-0', b: '380', f: 'UA' },
      {
        name: 'Spojen\u00e9 Arabsk\u00e9 Emir\u00e1ty',
        c: '971-AE-0',
        b: '971',
        f: 'AE',
      },
      {
        name: 'Spojen\u00e9 kr\u00e1lovstv\u00ed',
        c: '44-GB-0',
        b: '44',
        f: 'GB',
      },
      {
        name: 'Spojen\u00e9 st\u00e1ty americk\u00e9',
        c: '1-US-0',
        b: '1',
        f: 'US',
      },
      { name: 'Uruguay', c: '598-UY-0', b: '598', f: 'UY' },
      { name: 'Uzbekist\u00e1n', c: '998-UZ-0', b: '998', f: 'UZ' },
      { name: 'Vanuatu', c: '678-VU-0', b: '678', f: 'VU' },
      { name: 'Vatik\u00e1n', c: '379-VA-0', b: '379', f: 'VA' },
      { name: 'Venezuela', c: '58-VE-0', b: '58', f: 'VE' },
      { name: 'Vietnam', c: '84-VN-0', b: '84', f: 'VN' },
      { name: 'Wallis a Futuna', c: '681-WF-0', b: '681', f: 'WF' },
      { name: 'Z\u00e1padn\u00ed Sahara', c: '212-EH-0', b: '212', f: 'EH' },
      { name: 'Jemen', c: '967-YE-0', b: '967', f: 'YE' },
      { name: 'Zambie', c: '260-ZM-0', b: '260', f: 'ZM' },
      { name: 'Zimbabwe', c: '263-ZW-0', b: '263', f: 'ZW' },
    ]
    Xh(Uh)
    var Yh = new Rh(Uh)
    function Zh(a, b) {
      this.a = a
      this.Aa = b
    }
    function $h(a) {
      a = Ua(a)
      var b = Sh(Yh, a)
      return 0 < b.length
        ? new Zh(
            '1' == b[0].b ? '1-US-0' : b[0].c,
            Ua(a.substr(b[0].b.length + 1))
          )
        : null
    }
    function ai(a) {
      var b = Th(a.a)
      if (!b) throw Error('Country ID ' + a.a + ' not found.')
      return '+' + b.b + a.Aa
    }
    function bi(a, b) {
      for (var c = 0; c < a.length; c++)
        if (!Ma(ci, a[c]) && ((null !== di && a[c] in di) || Ma(b, a[c])))
          return a[c]
      return null
    }
    var ci = ['emailLink', 'password', 'phone'],
      di = {
        'facebook.com': 'FacebookAuthProvider',
        'github.com': 'GithubAuthProvider',
        'google.com': 'GoogleAuthProvider',
        password: 'EmailAuthProvider',
        'twitter.com': 'TwitterAuthProvider',
        phone: 'PhoneAuthProvider',
      }
    function ei() {
      this.a = new Kh()
      G(this.a, 'autoUpgradeAnonymousUsers')
      G(this.a, 'callbacks')
      G(this.a, 'credentialHelper', fi)
      G(this.a, 'immediateFederatedRedirect', !1)
      G(this.a, 'popupMode', !1)
      G(this.a, 'privacyPolicyUrl')
      G(this.a, 'queryParameterForSignInSuccessUrl', 'signInSuccessUrl')
      G(this.a, 'queryParameterForWidgetMode', 'mode')
      G(this.a, 'signInFlow')
      G(this.a, 'signInOptions')
      G(this.a, 'signInSuccessUrl')
      G(this.a, 'siteName')
      G(this.a, 'tosUrl')
      G(this.a, 'widgetUrl')
      G(this.a, 'adminRestrictedOperation')
    }
    function gi(a) {
      var b = !!a.a.get('autoUpgradeAnonymousUsers')
      b &&
        !hi(a) &&
        rg(
          'Missing "signInFailure" callback: "signInFailure" callback needs to be provided when "autoUpgradeAnonymousUsers" is set to true.',
          void 0
        )
      return b
    }
    function ii(a) {
      a = a.a.get('signInOptions') || []
      for (var b = [], c = 0; c < a.length; c++) {
        var d = a[c]
        d = ta(d) ? d : { provider: d }
        d.provider && b.push(d)
      }
      return b
    }
    function ji(a, b) {
      a = ii(a)
      for (var c = 0; c < a.length; c++) if (a[c].provider === b) return a[c]
      return null
    }
    function ki(a) {
      return ii(a).map(function (b) {
        return b.provider
      })
    }
    function li(a, b) {
      a = mi(a)
      for (var c = 0; c < a.length; c++) if (a[c].providerId === b) return a[c]
      return null
    }
    function mi(a) {
      return ii(a).map(function (b) {
        if (di[b.provider] || Ma(ni, b.provider)) {
          b = {
            providerId: b.provider,
            S: b.providerName || null,
            V: b.fullLabel || null,
            ta: b.buttonColor || null,
            za: b.iconUrl ? zc(Bc(b.iconUrl)).toString() : null,
          }
          for (var c in b) null === b[c] && delete b[c]
          return b
        }
        return {
          providerId: b.provider,
          S: b.providerName || null,
          V: b.fullLabel || null,
          ta: b.buttonColor || null,
          za: b.iconUrl ? zc(Bc(b.iconUrl)).toString() : null,
          Ob: b.loginHintKey || null,
        }
      })
    }
    function oi(a) {
      var b = ji(a, firebase.auth.GoogleAuthProvider.PROVIDER_ID),
        c
      if ((c = b && b.clientId)) {
        a: {
          if (
            'http:' === (window.location && window.location.protocol) ||
            'https:' === (window.location && window.location.protocol)
          )
            for (d in ((a = a.a.get('credentialHelper')), pi))
              if (pi[d] === a) {
                var d = pi[d]
                break a
              }
          d = fi
        }
        c = d === qi
      }
      return c ? b.clientId || null : null
    }
    function ri(a) {
      a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)
      return !!(a && a.disableSignUp && a.disableSignUp.status)
    }
    function si(a) {
      a = a.a.get('adminRestrictedOperation') || null
      return !(!a || !a.status)
    }
    function ti(a) {
      var b = null
      ii(a).forEach(function (d) {
        d.provider == firebase.auth.PhoneAuthProvider.PROVIDER_ID &&
          ta(d.recaptchaParameters) &&
          !Array.isArray(d.recaptchaParameters) &&
          (b = eb(d.recaptchaParameters))
      })
      if (b) {
        var c = []
        ui.forEach(function (d) {
          'undefined' !== typeof b[d] && (c.push(d), delete b[d])
        })
        c.length &&
          wg(
            'The following provided "recaptchaParameters" keys are not allowed: ' +
              c.join(', ')
          )
      }
      return b
    }
    function vi(a) {
      return (a = a.a.get('adminRestrictedOperation')) && a.adminEmail
        ? a.adminEmail
        : null
    }
    function wi(a) {
      if ((a = a.a.get('adminRestrictedOperation') || null)) {
        var b = a.helpLink || null
        if (b && 'string' === typeof b)
          return function () {
            uf(b)
          }
      }
      return null
    }
    function xi(a) {
      return (
        ((a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) &&
          a.disableSignUp &&
          a.disableSignUp.adminEmail) ||
        null
      )
    }
    function yi(a) {
      if (
        (a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) &&
        a.disableSignUp
      ) {
        var b = a.disableSignUp.helpLink || null
        if (b && 'string' === typeof b)
          return function () {
            uf(b)
          }
      }
      return null
    }
    function zi(a, b) {
      a = (a = ji(a, b)) && a.scopes
      return Array.isArray(a) ? a : []
    }
    function Ai(a, b) {
      a = (a = ji(a, b)) && a.customParameters
      return ta(a)
        ? ((a = eb(a)),
          b === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
            delete a.login_hint,
          b === firebase.auth.GithubAuthProvider.PROVIDER_ID && delete a.login,
          a)
        : null
    }
    function Bi(a) {
      a = ji(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID)
      var b = null
      a && 'string' === typeof a.loginHint && (b = $h(a.loginHint))
      return (a && a.defaultNationalNumber) || (b && b.Aa) || null
    }
    function Ci(a) {
      var b =
        ((a = ji(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID)) &&
          a.defaultCountry) ||
        null
      b = b && Vh(b)
      var c = null
      a && 'string' === typeof a.loginHint && (c = $h(a.loginHint))
      return (b && b[0]) || (c && Th(c.a)) || null
    }
    function Di(a) {
      a = ji(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID)
      if (!a) return null
      var b = a.whitelistedCountries,
        c = a.blacklistedCountries
      if ('undefined' !== typeof b && (!Array.isArray(b) || 0 == b.length))
        throw Error('WhitelistedCountries must be a non-empty array.')
      if ('undefined' !== typeof c && !Array.isArray(c))
        throw Error('BlacklistedCountries must be an array.')
      if (b && c)
        throw Error(
          'Both whitelistedCountries and blacklistedCountries are provided.'
        )
      if (!b && !c) return Uh
      a = []
      if (b) {
        c = {}
        for (var d = 0; d < b.length; d++) {
          var e = Wh(b[d])
          for (var f = 0; f < e.length; f++) c[e[f].c] = e[f]
        }
        for (var g in c) c.hasOwnProperty(g) && a.push(c[g])
      } else {
        g = {}
        for (b = 0; b < c.length; b++)
          for (e = Wh(c[b]), d = 0; d < e.length; d++) g[e[d].c] = e[d]
        for (e = 0; e < Uh.length; e++)
          (null !== g && Uh[e].c in g) || a.push(Uh[e])
      }
      return a
    }
    function Ei(a) {
      return Mh(a.a, 'queryParameterForWidgetMode')
    }
    function H(a) {
      var b = a.a.get('tosUrl') || null
      a = a.a.get('privacyPolicyUrl') || null
      b &&
        !a &&
        wg('Privacy Policy URL is missing, the link will not be displayed.')
      if (b && a) {
        if ('function' === typeof b) return b
        if ('string' === typeof b)
          return function () {
            uf(b)
          }
      }
      return null
    }
    function I(a) {
      var b = a.a.get('tosUrl') || null,
        c = a.a.get('privacyPolicyUrl') || null
      c &&
        !b &&
        wg('Term of Service URL is missing, the link will not be displayed.')
      if (b && c) {
        if ('function' === typeof c) return c
        if ('string' === typeof c)
          return function () {
            uf(c)
          }
      }
      return null
    }
    function Fi(a) {
      return (a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) &&
        'undefined' !== typeof a.requireDisplayName
        ? !!a.requireDisplayName
        : !0
    }
    function Gi(a) {
      a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)
      return !(
        !a ||
        a.signInMethod !==
          firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      )
    }
    function Hi(a) {
      a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)
      return !(!a || !a.forceSameDevice)
    }
    function Ii(a) {
      if (Gi(a)) {
        var b = { url: wf(), handleCodeInApp: !0 }
        ;(a = ji(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) &&
          'function' === typeof a.emailLinkSignIn &&
          gb(b, a.emailLinkSignIn())
        a = b.url
        var c = wf()
        c instanceof vb || (c = Jb(c))
        a instanceof vb || (a = Jb(a))
        var d = c
        c = new vb(d)
        var e = !!a.j
        e ? wb(c, a.j) : (e = !!a.A)
        e ? (c.A = a.A) : (e = !!a.h)
        e ? (c.h = a.h) : (e = null != a.C)
        var f = a.g
        if (e) xb(c, a.C)
        else if ((e = !!a.g))
          if (
            ('/' != f.charAt(0) &&
              (d.h && !d.g
                ? (f = '/' + f)
                : ((d = c.g.lastIndexOf('/')),
                  -1 != d && (f = c.g.substr(0, d + 1) + f))),
            '..' == f || '.' == f)
          )
            f = ''
          else if (-1 != f.indexOf('./') || -1 != f.indexOf('/.')) {
            d = 0 == f.lastIndexOf('/', 0)
            f = f.split('/')
            for (var g = [], h = 0; h < f.length; ) {
              var k = f[h++]
              '.' == k
                ? d && h == f.length && g.push('')
                : '..' == k
                  ? ((1 < g.length || (1 == g.length && '' != g[0])) && g.pop(),
                    d && h == f.length && g.push(''))
                  : (g.push(k), (d = !0))
            }
            f = g.join('/')
          }
        e ? (c.g = f) : (e = '' !== a.a.toString())
        e ? yb(c, zb(a.a)) : (e = !!a.s)
        e && (c.s = a.s)
        b.url = c.toString()
        return b
      }
      return null
    }
    function Ji(a) {
      var b = !!a.a.get('immediateFederatedRedirect'),
        c = ki(a)
      a = Ki(a)
      return b && 1 == c.length && !Ma(ci, c[0]) && a == Li
    }
    function Ki(a) {
      a = a.a.get('signInFlow')
      for (var b in Mi) if (Mi[b] == a) return Mi[b]
      return Li
    }
    function Ni(a) {
      return Oi(a).signInSuccess || null
    }
    function Pi(a) {
      return Oi(a).signInSuccessWithAuthResult || null
    }
    function hi(a) {
      return Oi(a).signInFailure || null
    }
    function Oi(a) {
      return a.a.get('callbacks') || {}
    }
    var qi = 'googleyolo',
      fi = 'none',
      pi = { nc: qi, NONE: fi },
      Li = 'redirect',
      Mi = { qc: 'popup', rc: Li },
      Qi = {
        mc: 'callback',
        RECOVER_EMAIL: 'recoverEmail',
        sc: 'resetPassword',
        REVERT_SECOND_FACTOR_ADDITION: 'revertSecondFactorAddition',
        tc: 'select',
        uc: 'signIn',
        VERIFY_AND_CHANGE_EMAIL: 'verifyAndChangeEmail',
        VERIFY_EMAIL: 'verifyEmail',
      },
      ni = ['anonymous'],
      ui = ['sitekey', 'tabindex', 'callback', 'expired-callback']
    var Ri,
      Si,
      Ti,
      Ui,
      K = {}
    function L(a, b, c, d) {
      K[a].apply(null, Array.prototype.slice.call(arguments, 1))
    }
    function Vi(a) {
      if (a.classList) return a.classList
      a = a.className
      return (q(a) && a.match(/\S+/g)) || []
    }
    function Wi(a, b) {
      return a.classList ? a.classList.contains(b) : Ma(Vi(a), b)
    }
    function Xi(a, b) {
      a.classList
        ? a.classList.add(b)
        : Wi(a, b) || (a.className += 0 < a.className.length ? ' ' + b : b)
    }
    function Yi(a, b) {
      a.classList
        ? a.classList.remove(b)
        : Wi(a, b) &&
          (a.className = Ja(Vi(a), function (c) {
            return c != b
          }).join(' '))
    }
    function Zi(a) {
      var b = a.type
      switch (q(b) && b.toLowerCase()) {
        case 'checkbox':
        case 'radio':
          return a.checked ? a.value : null
        case 'select-one':
          return (b = a.selectedIndex), 0 <= b ? a.options[b].value : null
        case 'select-multiple':
          b = []
          for (var c, d = 0; (c = a.options[d]); d++)
            c.selected && b.push(c.value)
          return b.length ? b : null
        default:
          return null != a.value ? a.value : null
      }
    }
    function $i(a, b) {
      var c = a.type
      switch (q(c) && c.toLowerCase()) {
        case 'checkbox':
        case 'radio':
          a.checked = b
          break
        case 'select-one':
          a.selectedIndex = -1
          if (q(b))
            for (var d = 0; (c = a.options[d]); d++)
              if (c.value == b) {
                c.selected = !0
                break
              }
          break
        case 'select-multiple':
          q(b) && (b = [b])
          for (d = 0; (c = a.options[d]); d++)
            if (((c.selected = !1), b))
              for (var e, f = 0; (e = b[f]); f++)
                c.value == e && (c.selected = !0)
          break
        default:
          a.value = null != b ? b : ''
      }
    }
    function aj(a) {
      if (
        (a.altKey && !a.ctrlKey) ||
        a.metaKey ||
        (112 <= a.keyCode && 123 >= a.keyCode)
      )
        return !1
      if (bj(a.keyCode)) return !0
      switch (a.keyCode) {
        case 18:
        case 20:
        case 93:
        case 17:
        case 40:
        case 35:
        case 27:
        case 36:
        case 45:
        case 37:
        case 224:
        case 91:
        case 144:
        case 12:
        case 34:
        case 33:
        case 19:
        case 255:
        case 44:
        case 39:
        case 145:
        case 16:
        case 38:
        case 252:
        case 224:
        case 92:
          return !1
        case 0:
          return !dc
        default:
          return 166 > a.keyCode || 183 < a.keyCode
      }
    }
    function cj(a, b, c, d, e, f) {
      if (ec && !mc('525')) return !0
      if (gc && e) return bj(a)
      if (e && !d) return !1
      if (!dc) {
        'number' == typeof b && (b = dj(b))
        var g = 17 == b || 18 == b || (gc && 91 == b)
        if (((!c || gc) && g) || (gc && 16 == b && (d || f))) return !1
      }
      if ((ec || bc) && d && c)
        switch (a) {
          case 220:
          case 219:
          case 221:
          case 192:
          case 186:
          case 189:
          case 187:
          case 188:
          case 190:
          case 191:
          case 192:
          case 222:
            return !1
        }
      if (z && d && b == a) return !1
      switch (a) {
        case 13:
          return dc ? (f || e ? !1 : !(c && d)) : !0
        case 27:
          return !(ec || bc || dc)
      }
      return dc && (d || e || f) ? !1 : bj(a)
    }
    function bj(a) {
      if (
        (48 <= a && 57 >= a) ||
        (96 <= a && 106 >= a) ||
        (65 <= a && 90 >= a) ||
        ((ec || bc) && 0 == a)
      )
        return !0
      switch (a) {
        case 32:
        case 43:
        case 63:
        case 64:
        case 107:
        case 109:
        case 110:
        case 111:
        case 186:
        case 59:
        case 189:
        case 187:
        case 61:
        case 188:
        case 190:
        case 191:
        case 192:
        case 222:
        case 219:
        case 220:
        case 221:
        case 163:
          return !0
        case 173:
          return dc
        default:
          return !1
      }
    }
    function dj(a) {
      if (dc) a = ej(a)
      else if (gc && ec)
        switch (a) {
          case 93:
            a = 91
        }
      return a
    }
    function ej(a) {
      switch (a) {
        case 61:
          return 187
        case 59:
          return 186
        case 173:
          return 189
        case 224:
          return 91
        case 0:
          return 224
        default:
          return a
      }
    }
    function fj(a) {
      E.call(this)
      this.a = a
      ne(a, 'keydown', this.g, !1, this)
      ne(a, 'click', this.h, !1, this)
    }
    w(fj, E)
    fj.prototype.g = function (a) {
      ;(13 == a.keyCode || (ec && 3 == a.keyCode)) && gj(this, a)
    }
    fj.prototype.h = function (a) {
      gj(this, a)
    }
    function gj(a, b) {
      var c = new hj(b)
      if (Ae(a, c)) {
        c = new ij(b)
        try {
          Ae(a, c)
        } finally {
          b.stopPropagation()
        }
      }
    }
    fj.prototype.o = function () {
      fj.K.o.call(this)
      ve(this.a, 'keydown', this.g, !1, this)
      ve(this.a, 'click', this.h, !1, this)
      delete this.a
    }
    function ij(a) {
      be.call(this, a.a)
      this.type = 'action'
    }
    w(ij, be)
    function hj(a) {
      be.call(this, a.a)
      this.type = 'beforeaction'
    }
    w(hj, be)
    function jj(a) {
      E.call(this)
      this.a = a
      a = z ? 'focusout' : 'blur'
      this.g = ne(this.a, z ? 'focusin' : 'focus', this, !z)
      this.h = ne(this.a, a, this, !z)
    }
    w(jj, E)
    jj.prototype.handleEvent = function (a) {
      var b = new be(a.a)
      b.type = 'focusin' == a.type || 'focus' == a.type ? 'focusin' : 'focusout'
      Ae(this, b)
    }
    jj.prototype.o = function () {
      jj.K.o.call(this)
      we(this.g)
      we(this.h)
      delete this.a
    }
    function kj(a, b) {
      E.call(this)
      this.g = a || 1
      this.a = b || n
      this.h = r(this.gc, this)
      this.j = Aa()
    }
    w(kj, E)
    l = kj.prototype
    l.Ka = !1
    l.aa = null
    l.gc = function () {
      if (this.Ka) {
        var a = Aa() - this.j
        0 < a && a < 0.8 * this.g
          ? (this.aa = this.a.setTimeout(this.h, this.g - a))
          : (this.aa && (this.a.clearTimeout(this.aa), (this.aa = null)),
            Ae(this, 'tick'),
            this.Ka && (lj(this), this.start()))
      }
    }
    l.start = function () {
      this.Ka = !0
      this.aa ||
        ((this.aa = this.a.setTimeout(this.h, this.g)), (this.j = Aa()))
    }
    function lj(a) {
      a.Ka = !1
      a.aa && (a.a.clearTimeout(a.aa), (a.aa = null))
    }
    l.o = function () {
      kj.K.o.call(this)
      lj(this)
      delete this.a
    }
    function mj(a, b) {
      if (sa(a)) b && (a = r(a, b))
      else if (a && 'function' == typeof a.handleEvent) a = r(a.handleEvent, a)
      else throw Error('Invalid listener argument')
      return 2147483647 < Number(0) ? -1 : n.setTimeout(a, 0)
    }
    function nj(a) {
      Sd.call(this)
      this.g = a
      this.a = {}
    }
    w(nj, Sd)
    var oj = []
    function pj(a, b, c, d) {
      qa(c) || (c && (oj[0] = c.toString()), (c = oj))
      for (var e = 0; e < c.length; e++) {
        var f = ne(b, c[e], d || a.handleEvent, !1, a.g || a)
        if (!f) break
        a.a[f.key] = f
      }
    }
    function qj(a) {
      db(
        a.a,
        function (b, c) {
          this.a.hasOwnProperty(c) && we(b)
        },
        a
      )
      a.a = {}
    }
    nj.prototype.o = function () {
      nj.K.o.call(this)
      qj(this)
    }
    nj.prototype.handleEvent = function () {
      throw Error('EventHandler.handleEvent not implemented')
    }
    function rj(a) {
      E.call(this)
      this.a = null
      this.g = a
      a = z || bc || (ec && !mc('531') && 'TEXTAREA' == a.tagName)
      this.h = new nj(this)
      pj(
        this.h,
        this.g,
        a ? ['keydown', 'paste', 'cut', 'drop', 'input'] : 'input',
        this
      )
    }
    w(rj, E)
    rj.prototype.handleEvent = function (a) {
      if ('input' == a.type)
        (z && mc(10) && 0 == a.keyCode && 0 == a.j) ||
          (sj(this), Ae(this, tj(a)))
      else if ('keydown' != a.type || aj(a)) {
        var b = 'keydown' == a.type ? this.g.value : null
        z && 229 == a.keyCode && (b = null)
        var c = tj(a)
        sj(this)
        this.a = mj(function () {
          this.a = null
          this.g.value != b && Ae(this, c)
        }, this)
      }
    }
    function sj(a) {
      null != a.a && (n.clearTimeout(a.a), (a.a = null))
    }
    function tj(a) {
      a = new be(a.a)
      a.type = 'input'
      return a
    }
    rj.prototype.o = function () {
      rj.K.o.call(this)
      this.h.m()
      sj(this)
      delete this.g
    }
    function uj(a, b) {
      E.call(this)
      a &&
        (this.Oa && vj(this),
        (this.qa = a),
        (this.Na = ne(this.qa, 'keypress', this, b)),
        (this.Ya = ne(this.qa, 'keydown', this.Jb, b, this)),
        (this.Oa = ne(this.qa, 'keyup', this.Kb, b, this)))
    }
    w(uj, E)
    l = uj.prototype
    l.qa = null
    l.Na = null
    l.Ya = null
    l.Oa = null
    l.R = -1
    l.X = -1
    l.Ua = !1
    var wj = {
        3: 13,
        12: 144,
        63232: 38,
        63233: 40,
        63234: 37,
        63235: 39,
        63236: 112,
        63237: 113,
        63238: 114,
        63239: 115,
        63240: 116,
        63241: 117,
        63242: 118,
        63243: 119,
        63244: 120,
        63245: 121,
        63246: 122,
        63247: 123,
        63248: 44,
        63272: 46,
        63273: 36,
        63275: 35,
        63276: 33,
        63277: 34,
        63289: 144,
        63302: 45,
      },
      xj = {
        Up: 38,
        Down: 40,
        Left: 37,
        Right: 39,
        Enter: 13,
        F1: 112,
        F2: 113,
        F3: 114,
        F4: 115,
        F5: 116,
        F6: 117,
        F7: 118,
        F8: 119,
        F9: 120,
        F10: 121,
        F11: 122,
        F12: 123,
        'U+007F': 46,
        Home: 36,
        End: 35,
        PageUp: 33,
        PageDown: 34,
        Insert: 45,
      },
      yj = !ec || mc('525'),
      zj = gc && dc
    l = uj.prototype
    l.Jb = function (a) {
      if (ec || bc)
        if (
          (17 == this.R && !a.ctrlKey) ||
          (18 == this.R && !a.altKey) ||
          (gc && 91 == this.R && !a.metaKey)
        )
          this.X = this.R = -1
      ;-1 == this.R &&
        (a.ctrlKey && 17 != a.keyCode
          ? (this.R = 17)
          : a.altKey && 18 != a.keyCode
            ? (this.R = 18)
            : a.metaKey && 91 != a.keyCode && (this.R = 91))
      yj && !cj(a.keyCode, this.R, a.shiftKey, a.ctrlKey, a.altKey, a.metaKey)
        ? this.handleEvent(a)
        : ((this.X = dj(a.keyCode)), zj && (this.Ua = a.altKey))
    }
    l.Kb = function (a) {
      this.X = this.R = -1
      this.Ua = a.altKey
    }
    l.handleEvent = function (a) {
      var b = a.a,
        c = b.altKey
      if (z && 'keypress' == a.type) {
        var d = this.X
        var e = 13 != d && 27 != d ? b.keyCode : 0
      } else
        (ec || bc) && 'keypress' == a.type
          ? ((d = this.X),
            (e =
              0 <= b.charCode && 63232 > b.charCode && bj(d) ? b.charCode : 0))
          : ac && !ec
            ? ((d = this.X), (e = bj(d) ? b.keyCode : 0))
            : ('keypress' == a.type
                ? (zj && (c = this.Ua),
                  b.keyCode == b.charCode
                    ? 32 > b.keyCode
                      ? ((d = b.keyCode), (e = 0))
                      : ((d = this.X), (e = b.charCode))
                    : ((d = b.keyCode || this.X), (e = b.charCode || 0)))
                : ((d = b.keyCode || this.X), (e = b.charCode || 0)),
              gc && 63 == e && 224 == d && (d = 191))
      var f = (d = dj(d))
      d
        ? 63232 <= d && d in wj
          ? (f = wj[d])
          : 25 == d && a.shiftKey && (f = 9)
        : b.keyIdentifier && b.keyIdentifier in xj && (f = xj[b.keyIdentifier])
      ;(dc &&
        yj &&
        'keypress' == a.type &&
        !cj(f, this.R, a.shiftKey, a.ctrlKey, c, a.metaKey)) ||
        ((a = f == this.R),
        (this.R = f),
        (b = new Aj(f, e, a, b)),
        (b.altKey = c),
        Ae(this, b))
    }
    l.N = function () {
      return this.qa
    }
    function vj(a) {
      a.Na &&
        (we(a.Na),
        we(a.Ya),
        we(a.Oa),
        (a.Na = null),
        (a.Ya = null),
        (a.Oa = null))
      a.qa = null
      a.R = -1
      a.X = -1
    }
    l.o = function () {
      uj.K.o.call(this)
      vj(this)
    }
    function Aj(a, b, c, d) {
      be.call(this, d)
      this.type = 'key'
      this.keyCode = a
      this.j = b
      this.repeat = c
    }
    w(Aj, be)
    function Bj(a, b, c, d) {
      this.top = a
      this.right = b
      this.bottom = c
      this.left = d
    }
    Bj.prototype.toString = function () {
      return (
        '(' +
        this.top +
        't, ' +
        this.right +
        'r, ' +
        this.bottom +
        'b, ' +
        this.left +
        'l)'
      )
    }
    Bj.prototype.ceil = function () {
      this.top = Math.ceil(this.top)
      this.right = Math.ceil(this.right)
      this.bottom = Math.ceil(this.bottom)
      this.left = Math.ceil(this.left)
      return this
    }
    Bj.prototype.floor = function () {
      this.top = Math.floor(this.top)
      this.right = Math.floor(this.right)
      this.bottom = Math.floor(this.bottom)
      this.left = Math.floor(this.left)
      return this
    }
    Bj.prototype.round = function () {
      this.top = Math.round(this.top)
      this.right = Math.round(this.right)
      this.bottom = Math.round(this.bottom)
      this.left = Math.round(this.left)
      return this
    }
    function Cj(a, b) {
      var c = Sc(a)
      return c.defaultView &&
        c.defaultView.getComputedStyle &&
        (a = c.defaultView.getComputedStyle(a, null))
        ? a[b] || a.getPropertyValue(b) || ''
        : ''
    }
    function Dj(a) {
      try {
        var b = a.getBoundingClientRect()
      } catch (c) {
        return { left: 0, top: 0, right: 0, bottom: 0 }
      }
      z &&
        a.ownerDocument.body &&
        ((a = a.ownerDocument),
        (b.left -= a.documentElement.clientLeft + a.body.clientLeft),
        (b.top -= a.documentElement.clientTop + a.body.clientTop))
      return b
    }
    function Ej(a, b) {
      b = b || Yc(document)
      var c = b || Yc(document)
      var d = Fj(a),
        e = Fj(c)
      if (!z || 9 <= Number(nc)) {
        g = Cj(c, 'borderLeftWidth')
        var f = Cj(c, 'borderRightWidth')
        h = Cj(c, 'borderTopWidth')
        k = Cj(c, 'borderBottomWidth')
        f = new Bj(parseFloat(h), parseFloat(f), parseFloat(k), parseFloat(g))
      } else {
        var g = Gj(c, 'borderLeft')
        f = Gj(c, 'borderRight')
        var h = Gj(c, 'borderTop'),
          k = Gj(c, 'borderBottom')
        f = new Bj(h, f, k, g)
      }
      c == Yc(document)
        ? ((g = d.a - c.scrollLeft),
          (d = d.g - c.scrollTop),
          !z || 10 <= Number(nc) || ((g += f.left), (d += f.top)))
        : ((g = d.a - e.a - f.left), (d = d.g - e.g - f.top))
      e = a.offsetWidth
      f = a.offsetHeight
      h = ec && !e && !f
      ;(ka(e) && !h) || !a.getBoundingClientRect
        ? (a = new Pc(e, f))
        : ((a = Dj(a)), (a = new Pc(a.right - a.left, a.bottom - a.top)))
      e = c.clientHeight - a.height
      f = c.scrollLeft
      h = c.scrollTop
      f += Math.min(g, Math.max(g - (c.clientWidth - a.width), 0))
      h += Math.min(d, Math.max(d - e, 0))
      c = new Oc(f, h)
      b.scrollLeft = c.a
      b.scrollTop = c.g
    }
    function Fj(a) {
      var b = Sc(a),
        c = new Oc(0, 0)
      var d = b ? Sc(b) : document
      d =
        !z || 9 <= Number(nc) || 'CSS1Compat' == Qc(d).a.compatMode
          ? d.documentElement
          : d.body
      if (a == d) return c
      a = Dj(a)
      d = Qc(b).a
      b = Yc(d)
      d = d.parentWindow || d.defaultView
      b =
        z && mc('10') && d.pageYOffset != b.scrollTop
          ? new Oc(b.scrollLeft, b.scrollTop)
          : new Oc(d.pageXOffset || b.scrollLeft, d.pageYOffset || b.scrollTop)
      c.a = a.left + b.a
      c.g = a.top + b.g
      return c
    }
    var Hj = { thin: 2, medium: 4, thick: 6 }
    function Gj(a, b) {
      if ('none' == (a.currentStyle ? a.currentStyle[b + 'Style'] : null))
        return 0
      var c = a.currentStyle ? a.currentStyle[b + 'Width'] : null
      if (c in Hj) a = Hj[c]
      else if (/^\d+px?$/.test(c)) a = parseInt(c, 10)
      else {
        b = a.style.left
        var d = a.runtimeStyle.left
        a.runtimeStyle.left = a.currentStyle.left
        a.style.left = c
        c = a.style.pixelLeft
        a.style.left = b
        a.runtimeStyle.left = d
        a = +c
      }
      return a
    }
    function Ij() {}
    oa(Ij)
    Ij.prototype.a = 0
    function Jj(a) {
      E.call(this)
      this.s = a || Qc()
      this.cb = null
      this.na = !1
      this.g = null
      this.L = void 0
      this.oa = this.Ea = this.Y = null
    }
    w(Jj, E)
    l = Jj.prototype
    l.Lb = Ij.Xa()
    l.N = function () {
      return this.g
    }
    function M(a, b) {
      return a.g ? Vc(b, a.g || a.s.a) : null
    }
    function Kj(a) {
      a.L || (a.L = new nj(a))
      return a.L
    }
    l.Za = function (a) {
      if (this.Y && this.Y != a) throw Error('Method not supported')
      Jj.K.Za.call(this, a)
    }
    l.kb = function () {
      this.g = this.s.a.createElement('DIV')
    }
    l.render = function (a) {
      if (this.na) throw Error('Component already rendered')
      this.g || this.kb()
      a ? a.insertBefore(this.g, null) : this.s.a.body.appendChild(this.g)
      ;(this.Y && !this.Y.na) || this.v()
    }
    l.v = function () {
      this.na = !0
      Lj(this, function (a) {
        !a.na && a.N() && a.v()
      })
    }
    l.ya = function () {
      Lj(this, function (a) {
        a.na && a.ya()
      })
      this.L && qj(this.L)
      this.na = !1
    }
    l.o = function () {
      this.na && this.ya()
      this.L && (this.L.m(), delete this.L)
      Lj(this, function (a) {
        a.m()
      })
      this.g && Zc(this.g)
      this.Y = this.g = this.oa = this.Ea = null
      Jj.K.o.call(this)
    }
    function Lj(a, b) {
      a.Ea && Ha(a.Ea, b, void 0)
    }
    l.removeChild = function (a, b) {
      if (a) {
        var c = q(a) ? a : a.cb || (a.cb = ':' + (a.Lb.a++).toString(36))
        this.oa && c
          ? ((a = this.oa),
            (a = (null !== a && c in a ? a[c] : void 0) || null))
          : (a = null)
        if (c && a) {
          var d = this.oa
          c in d && delete d[c]
          Na(this.Ea, a)
          b && (a.ya(), a.g && Zc(a.g))
          b = a
          if (null == b) throw Error('Unable to set parent component')
          b.Y = null
          Jj.K.Za.call(b, null)
        }
      }
      if (!a) throw Error('Child is not in parent component')
      return a
    }
    function N(a, b) {
      var c = ad(a, 'firebaseui-textfield')
      b
        ? (Yi(a, 'firebaseui-input-invalid'),
          Xi(a, 'firebaseui-input'),
          c && Yi(c, 'firebaseui-textfield-invalid'))
        : (Yi(a, 'firebaseui-input'),
          Xi(a, 'firebaseui-input-invalid'),
          c && Xi(c, 'firebaseui-textfield-invalid'))
    }
    function Mj(a, b, c) {
      b = new rj(b)
      Vd(a, za(Wd, b))
      pj(Kj(a), b, 'input', c)
    }
    function Nj(a, b, c) {
      b = new uj(b)
      Vd(a, za(Wd, b))
      pj(Kj(a), b, 'key', function (d) {
        13 == d.keyCode && (d.stopPropagation(), d.preventDefault(), c(d))
      })
    }
    function Oj(a, b, c) {
      b = new jj(b)
      Vd(a, za(Wd, b))
      pj(Kj(a), b, 'focusin', c)
    }
    function Pj(a, b, c) {
      b = new jj(b)
      Vd(a, za(Wd, b))
      pj(Kj(a), b, 'focusout', c)
    }
    function O(a, b, c) {
      b = new fj(b)
      Vd(a, za(Wd, b))
      pj(Kj(a), b, 'action', function (d) {
        d.stopPropagation()
        d.preventDefault()
        c(d)
      })
    }
    function Qj(a) {
      Xi(a, 'firebaseui-hidden')
    }
    function Rj(a, b) {
      b && $c(a, b)
      Yi(a, 'firebaseui-hidden')
    }
    function Sj(a) {
      return !Wi(a, 'firebaseui-hidden') && 'none' != a.style.display
    }
    function Tj(a) {
      a = a || {}
      var b = a.email,
        c = a.disabled,
        d =
          '<div class="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-email-input">'
      d = a.wc ? d + 'Zadejte novou e-mailovou adresu' : d + 'E-mail'
      d +=
        '</label><input type="email" name="email" id="ui-sign-in-email-input" autocomplete="username" class="mdl-textfield__input firebaseui-input firebaseui-id-email" value="' +
        ud(null != b ? b : '') +
        '"' +
        (c ? 'disabled' : '') +
        '></div><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-email-error"></p></div>'
      return B(d)
    }
    function Uj(a) {
      a = a || {}
      a = a.label
      var b =
        '<button type="submit" class="firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored">'
      b = a ? b + A(a) : b + 'Dal\u0161\u00ed'
      return B(b + '</button>')
    }
    function Vj() {
      var a = '' + Uj({ label: D('P\u0159ihl\u00e1sit se') })
      return B(a)
    }
    function Wj() {
      var a = '' + Uj({ label: D('Ulo\u017eit') })
      return B(a)
    }
    function Xj() {
      var a = '' + Uj({ label: D('Pokra\u010dovat') })
      return B(a)
    }
    function Yj(a) {
      a = a || {}
      a = a.label
      var b =
        '<div class="firebaseui-new-password-component"><div class="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-new-password-input">'
      b = a ? b + A(a) : b + 'Zvolte heslo.'
      return B(
        b +
          '</label><input type="password" name="newPassword" id="ui-sign-in-new-password-input" autocomplete="new-password" class="mdl-textfield__input firebaseui-input firebaseui-id-new-password"></div><a href="javascript:void(0)" class="firebaseui-input-floating-button firebaseui-id-password-toggle firebaseui-input-toggle-on firebaseui-input-toggle-blur"></a><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-new-password-error"></p></div></div>'
      )
    }
    function Zj() {
      var a = {}
      var b =
        '<div class="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-password-input">'
      b = a.current ? b + 'Aktu\u00e1ln\u00ed heslo' : b + 'Heslo'
      return B(
        b +
          '</label><input type="password" name="password" id="ui-sign-in-password-input" autocomplete="current-password" class="mdl-textfield__input firebaseui-input firebaseui-id-password"></div><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-password-error"></p></div>'
      )
    }
    function ak() {
      return B(
        '<a class="firebaseui-link firebaseui-id-secondary-link" href="javascript:void(0)">M\u00e1te pot\u00ed\u017ee s p\u0159ihl\u00e1\u0161en\u00edm?</a>'
      )
    }
    function bk(a) {
      a = a || {}
      a = a.label
      var b =
        '<button class="firebaseui-id-secondary-link firebaseui-button mdl-button mdl-js-button mdl-button--primary">'
      b = a ? b + A(a) : b + 'Zru\u0161it'
      return B(b + '</button>')
    }
    function ck(a) {
      var b = ''
      a.F &&
        a.D &&
        (b +=
          '<ul class="firebaseui-tos-list firebaseui-tos"><li class="firebaseui-inline-list-item"><a href="javascript:void(0)" class="firebaseui-link firebaseui-tos-link" target="_blank">Smluvn\u00ed podm\u00ednky</a></li><li class="firebaseui-inline-list-item"><a href="javascript:void(0)" class="firebaseui-link firebaseui-pp-link" target="_blank">Z\u00e1sady ochrany soukrom\u00ed</a></li></ul>')
      return B(b)
    }
    function dk(a) {
      var b = ''
      a.F &&
        a.D &&
        (b +=
          '<p class="firebaseui-tos firebaseui-tospp-full-message">Budete-li pokra\u010dovat, vyjad\u0159ujete sv\u016fj souhlas s na\u0161imi <a href="javascript:void(0)" class="firebaseui-link firebaseui-tos-link" target="_blank">smluvn\u00edmi podm\u00ednkami</a> a <a href="javascript:void(0)" class="firebaseui-link firebaseui-pp-link" target="_blank">z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f</a>.</p>')
      return B(b)
    }
    function ek(a) {
      a =
        '<div class="firebaseui-info-bar firebaseui-id-info-bar"><p class="firebaseui-info-bar-message">' +
        A(a.message) +
        '&nbsp;&nbsp;<a href="javascript:void(0)" class="firebaseui-link firebaseui-id-dismiss-info-bar">Zru\u0161it</a></p></div>'
      return B(a)
    }
    ek.a = 'firebaseui.auth.soy2.element.infoBar'
    function fk(a) {
      var b = a.content
      a = a.Ab
      return B(
        '<dialog class="mdl-dialog firebaseui-dialog firebaseui-id-dialog' +
          (a ? ' ' + ud(a) : '') +
          '">' +
          A(b) +
          '</dialog>'
      )
    }
    function gk(a) {
      var b = a.message
      return B(
        fk({
          content: td(
            '<div class="firebaseui-dialog-icon-wrapper"><div class="' +
              ud(a.Ma) +
              ' firebaseui-dialog-icon"></div></div><div class="firebaseui-progress-dialog-message">' +
              A(b) +
              '</div>'
          ),
        })
      )
    }
    gk.a = 'firebaseui.auth.soy2.element.progressDialog'
    function hk(a) {
      var b = '<div class="firebaseui-list-box-actions">'
      a = a.items
      for (var c = a.length, d = 0; d < c; d++) {
        var e = a[d]
        b +=
          '<button type="button" data-listboxid="' +
          ud(e.id) +
          '" class="mdl-button firebaseui-id-list-box-dialog-button firebaseui-list-box-dialog-button">' +
          (e.Ma
            ? '<div class="firebaseui-list-box-icon-wrapper"><div class="firebaseui-list-box-icon ' +
              ud(e.Ma) +
              '"></div></div>'
            : '') +
          '<div class="firebaseui-list-box-label-wrapper">' +
          A(e.label) +
          '</div></button>'
      }
      b =
        '' +
        fk({ Ab: D('firebaseui-list-box-dialog'), content: td(b + '</div>') })
      return B(b)
    }
    hk.a = 'firebaseui.auth.soy2.element.listBoxDialog'
    function ik(a) {
      a = a || {}
      return B(
        a.tb
          ? '<div class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-busy-indicator firebaseui-id-busy-indicator"></div>'
          : '<div class="mdl-progress mdl-js-progress mdl-progress__indeterminate firebaseui-busy-indicator firebaseui-id-busy-indicator"></div>'
      )
    }
    ik.a = 'firebaseui.auth.soy2.element.busyIndicator'
    function jk(a, b) {
      a = a || {}
      a = a.ga
      return C(
        a.S
          ? a.S
          : b.hb[a.providerId]
            ? '' + b.hb[a.providerId]
            : a.providerId && 0 == a.providerId.indexOf('saml.')
              ? a.providerId.substring(5)
              : a.providerId && 0 == a.providerId.indexOf('oidc.')
                ? a.providerId.substring(5)
                : '' + a.providerId
      )
    }
    function kk(a) {
      lk(a, 'upgradeElement')
    }
    function mk(a) {
      lk(a, 'downgradeElements')
    }
    var nk = [
      'mdl-js-textfield',
      'mdl-js-progress',
      'mdl-js-spinner',
      'mdl-js-button',
    ]
    function lk(a, b) {
      a &&
        window.componentHandler &&
        window.componentHandler[b] &&
        nk.forEach(function (c) {
          if (Wi(a, c)) window.componentHandler[b](a)
          Ha(Tc(c, a), function (d) {
            window.componentHandler[b](d)
          })
        })
    }
    function ok(a, b, c) {
      pk.call(this)
      document.body.appendChild(a)
      a.showModal || window.dialogPolyfill.registerDialog(a)
      a.showModal()
      kk(a)
      b &&
        O(this, a, function (f) {
          var g = a.getBoundingClientRect()
          ;(f.clientX < g.left ||
            g.left + g.width < f.clientX ||
            f.clientY < g.top ||
            g.top + g.height < f.clientY) &&
            pk.call(this)
        })
      if (!c) {
        var d = this.N().parentElement || this.N().parentNode
        if (d) {
          var e = this
          this.da = function () {
            if (a.open) {
              var f = a.getBoundingClientRect().height,
                g = d.getBoundingClientRect().height,
                h =
                  d.getBoundingClientRect().top -
                  document.body.getBoundingClientRect().top,
                k =
                  d.getBoundingClientRect().left -
                  document.body.getBoundingClientRect().left,
                p = a.getBoundingClientRect().width,
                t = d.getBoundingClientRect().width
              a.style.top = (h + (g - f) / 2).toString() + 'px'
              f = k + (t - p) / 2
              a.style.left = f.toString() + 'px'
              a.style.right =
                (
                  document.body.getBoundingClientRect().width -
                  f -
                  p
                ).toString() + 'px'
            } else window.removeEventListener('resize', e.da)
          }
          this.da()
          window.addEventListener('resize', this.da, !1)
        }
      }
    }
    function pk() {
      var a = qk.call(this)
      a &&
        (mk(a),
        a.open && a.close(),
        Zc(a),
        this.da && window.removeEventListener('resize', this.da))
    }
    function qk() {
      return Vc('firebaseui-id-dialog')
    }
    function rk() {
      Zc(sk.call(this))
    }
    function sk() {
      return M(this, 'firebaseui-id-info-bar')
    }
    function tk() {
      return M(this, 'firebaseui-id-dismiss-info-bar')
    }
    var uk = {
      xa: {
        'google.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg',
        'github.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/github.svg',
        'facebook.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg',
        'twitter.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg',
        password:
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg',
        phone:
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg',
        anonymous:
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/anonymous.png',
        'microsoft.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg',
        'yahoo.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/yahoo.svg',
        'apple.com':
          'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/apple.png',
        saml: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/saml.svg',
        oidc: 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/oidc.svg',
      },
      wa: {
        'google.com': '#ffffff',
        'github.com': '#333333',
        'facebook.com': '#3b5998',
        'twitter.com': '#55acee',
        password: '#db4437',
        phone: '#02bd7e',
        anonymous: '#f4b400',
        'microsoft.com': '#2F2F2F',
        'yahoo.com': '#720E9E',
        'apple.com': '#000000',
        saml: '#007bff',
        oidc: '#007bff',
      },
      hb: {
        'google.com': 'Google',
        'github.com': 'GitHub',
        'facebook.com': 'Facebook',
        'twitter.com': 'Twitter',
        password: 'Password',
        phone: 'Phone',
        anonymous: 'Guest',
        'microsoft.com': 'Microsoft',
        'yahoo.com': 'Yahoo',
        'apple.com': 'Apple',
      },
    }
    function vk(a, b, c) {
      ae.call(this, a, b)
      for (var d in c) this[d] = c[d]
    }
    w(vk, ae)
    function P(a, b, c, d, e) {
      Jj.call(this, c)
      this.fb = a
      this.eb = b
      this.Fa = !1
      this.Ga = d || null
      this.A = this.ca = null
      this.Z = eb(uk)
      gb(this.Z, e || {})
    }
    w(P, Jj)
    l = P.prototype
    l.kb = function () {
      var a = hd(this.fb, this.eb, this.Z, this.s)
      kk(a)
      this.g = a
    }
    l.v = function () {
      P.K.v.call(this)
      Ee(Q(this), new vk('pageEnter', Q(this), { pageId: this.Ga }))
      if (this.bb() && this.Z.F) {
        var a = this.Z.F
        O(this, this.bb(), function () {
          a()
        })
      }
      if (this.ab() && this.Z.D) {
        var b = this.Z.D
        O(this, this.ab(), function () {
          b()
        })
      }
    }
    l.ya = function () {
      Ee(Q(this), new vk('pageExit', Q(this), { pageId: this.Ga }))
      P.K.ya.call(this)
    }
    l.o = function () {
      window.clearTimeout(this.ca)
      this.eb = this.fb = this.ca = null
      this.Fa = !1
      this.A = null
      mk(this.N())
      P.K.o.call(this)
    }
    function wk(a) {
      a.Fa = !0
      var b = Wi(a.N(), 'firebaseui-use-spinner')
      a.ca = window.setTimeout(function () {
        a.N() &&
          null === a.A &&
          ((a.A = hd(ik, { tb: b }, null, a.s)),
          a.N().appendChild(a.A),
          kk(a.A))
      }, 500)
    }
    l.I = function (a, b, c, d) {
      function e() {
        if (f.T) return null
        f.Fa = !1
        window.clearTimeout(f.ca)
        f.ca = null
        f.A && (mk(f.A), Zc(f.A), (f.A = null))
      }
      var f = this
      if (f.Fa) return null
      wk(f)
      return a.apply(null, b).then(c, d).then(e, e)
    }
    function Q(a) {
      return a.N().parentElement || a.N().parentNode
    }
    function xk(a, b, c) {
      Nj(a, b, function () {
        c.focus()
      })
    }
    function yk(a, b, c) {
      Nj(a, b, function () {
        c()
      })
    }
    u(P.prototype, {
      a: function (a) {
        rk.call(this)
        var b = hd(ek, { message: a }, null, this.s)
        this.N().appendChild(b)
        O(this, tk.call(this), function () {
          Zc(b)
        })
      },
      yc: rk,
      Ac: sk,
      zc: tk,
      $: function (a, b) {
        a = hd(gk, { Ma: a, message: b }, null, this.s)
        ok.call(this, a)
      },
      h: pk,
      Cb: qk,
      Cc: function () {
        return M(this, 'firebaseui-tos')
      },
      bb: function () {
        return M(this, 'firebaseui-tos-link')
      },
      ab: function () {
        return M(this, 'firebaseui-pp-link')
      },
      Dc: function () {
        return M(this, 'firebaseui-tos-list')
      },
    })
    function zk(a, b, c) {
      a = a || {}
      b = a.Va
      var d = a.ia
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-in"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Nejprve zadejte svůj e-mail</h1></div><div class="firebaseui-card-content"><div class="firebaseui-relative-wrapper">' +
        Tj(a) +
        '</div></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        (b ? bk(null) : '') +
        Uj(null) +
        '</div></div><div class="firebaseui-card-footer">' +
        (d ? dk(c) : ck(c)) +
        '</div></form></div>'
      return B(a)
    }
    zk.a = 'firebaseui.auth.soy2.page.signIn'
    function Ak(a, b, c) {
      a = a || {}
      b = a.ia
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-sign-in"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content">' +
        Tj(a) +
        Zj() +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-links">' +
        ak() +
        '</div><div class="firebaseui-form-actions">' +
        Vj() +
        '</div></div><div class="firebaseui-card-footer">' +
        (b ? dk(c) : ck(c)) +
        '</div></form></div>'
      return B(a)
    }
    Ak.a = 'firebaseui.auth.soy2.page.passwordSignIn'
    function Bk(a, b, c) {
      a = a || {}
      var d = a.Tb
      b = a.Ta
      var e = a.ia,
        f =
          '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-sign-up"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Tvorba nového \u00fa\u010dtu</h1></div><div class="firebaseui-card-content">' +
          Tj(a)
      d
        ? ((a = a || {}),
          (a = a.name),
          (a =
            '<div class="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-name-input">Jm\u00e9no a p\u0159\u00edjmen\u00ed</label><input type="text" name="name" id="ui-sign-in-name-input" autocomplete="name" class="mdl-textfield__input firebaseui-input firebaseui-id-name" value="' +
            ud(null != a ? a : '') +
            '"></div><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-name-error"></p></div>'),
          (a = B(a)))
        : (a = '')
      c =
        f +
        a +
        Yj(null) +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        (b ? bk(null) : '') +
        Wj() +
        '</div></div><div class="firebaseui-card-footer">' +
        (e ? dk(c) : ck(c)) +
        '</div></form></div>'
      return B(c)
    }
    Bk.a = 'firebaseui.auth.soy2.page.passwordSignUp'
    function Ck(a, b, c) {
      a = a || {}
      b = a.Ta
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Obnoven\u00ed hesla</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Na tento e-mail v\u00e1m za\u0161leme pokyny, jak heslo obnovit</p>' +
        Tj(a) +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        (b ? bk(null) : '') +
        Uj({ label: D('Odeslat') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(a)
    }
    Ck.a = 'firebaseui.auth.soy2.page.passwordRecovery'
    function Dk(a, b, c) {
      b = a.G
      var d = ''
      a =
        'Obnovte heslo podle pokyn\u016f zaslan\u00fdch na adresu <strong>' +
        (A(a.email) + '</strong>')
      d +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery-email-sent"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Kontrola e-mailu</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        a +
        '</p></div><div class="firebaseui-card-actions">'
      b &&
        (d +=
          '<div class="firebaseui-form-actions">' +
          Uj({ label: D('Hotovo') }) +
          '</div>')
      d += '</div><div class="firebaseui-card-footer">' + ck(c) + '</div></div>'
      return B(d)
    }
    Dk.a = 'firebaseui.auth.soy2.page.passwordRecoveryEmailSent'
    function Ek(a, b, c) {
      return B(
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-callback"><div class="firebaseui-callback-indicator-container">' +
          ik(null, null, c) +
          '</div></div>'
      )
    }
    Ek.a = 'firebaseui.auth.soy2.page.callback'
    function Fk(a, b, c) {
      return B(
        '<div class="firebaseui-container firebaseui-id-page-spinner">' +
          ik({ tb: !0 }, null, c) +
          '</div>'
      )
    }
    Fk.a = 'firebaseui.auth.soy2.page.spinner'
    function Gk() {
      return B(
        '<div class="firebaseui-container firebaseui-id-page-blank firebaseui-use-spinner"></div>'
      )
    }
    Gk.a = 'firebaseui.auth.soy2.page.blank'
    function Hk(a, b, c) {
      b = ''
      a =
        'Na adresu <strong>' +
        (A(a.email) +
          '</strong> byl odesl\u00e1n p\u0159ihla\u0161ovac\u00ed e-mail s dal\u0161\u00edmi pokyny. Dokon\u010dete p\u0159ihl\u00e1\u0161en\u00ed podle instrukc\u00ed v e-mailu.')
      var d = B(
        '<a class="firebaseui-link firebaseui-id-trouble-getting-email-link" href="javascript:void(0)">Nep\u0159i\u0161el v\u00e1m e-mail?</a>'
      )
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-sent"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihla\u0161ovac\u00ed e-mail odesl\u00e1n</h1></div><div class="firebaseui-card-content"><div class="firebaseui-email-sent"></div><p class="firebaseui-text">' +
        a +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-links">' +
        d +
        '</div><div class="firebaseui-form-actions">' +
        bk({ label: D('Zp\u011bt') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Hk.a = 'firebaseui.auth.soy2.page.emailLinkSignInSent'
    function Ik(a, b, c) {
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-not-received"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Nep\u0159i\u0161el v\u00e1m e-mail?</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Vyzkou\u0161ejte tato b\u011b\u017en\u00e1 \u0159e\u0161en\u00ed:<ul><li>Zkontrolujte, jestli e-mail nebyl ozna\u010den jako spam nebo nebyl odstran\u011bn jin\u00fdm filtrem.</li><li>Zkontrolujte p\u0159ipojen\u00ed k internetu.</li><li>Zkontrolujte, zda jste adresu e-mailu napsali spr\u00e1vn\u011b.</li><li>Zkontrolujte, jestli nem\u00e1te plnou schr\u00e1nku p\u0159\u00edchoz\u00edch spr\u00e1v nebo nedo\u0161lo k n\u011bjak\u00e9mu jin\u00e9ho probl\u00e9mu se schr\u00e1nkou.</li></ul></p><p class="firebaseui-text">Pokud \u017e\u00e1dn\u00e9 z uveden\u00fdch \u0159e\u0161en\u00ed nepomohlo, m\u016f\u017eete si e-mail nechat zaslat znovu. Odkaz v prvn\u00edm e-mailu pak bude deaktivov\u00e1n.</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-links">' +
        B(
          '<a class="firebaseui-link firebaseui-id-resend-email-link" href="javascript:void(0)">Znovu odeslat</a>'
        ) +
        '</div><div class="firebaseui-form-actions">' +
        bk({ label: D('Zp\u011bt') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(a)
    }
    Ik.a = 'firebaseui.auth.soy2.page.emailNotReceived'
    function Jk(a, b, c) {
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-confirmation"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Potvrzen\u00ed\u00b7e-mailu</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Dokon\u010dete p\u0159ihl\u00e1\u0161en\u00ed potvrzn\u00edm e-mailov\u00e9 adresy</p><div class="firebaseui-relative-wrapper">' +
        Tj(a) +
        '</div></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk(null) +
        Uj(null) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(a)
    }
    Jk.a = 'firebaseui.auth.soy2.page.emailLinkSignInConfirmation'
    function Kk() {
      var a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-different-device-error"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Bylo rozpozn\u00e1no nov\u00e9 za\u0159\u00edzen\u00ed nebo prohl\u00ed\u017ee\u010d</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Otev\u0159ete odkaz na stejn\u00e9m za\u0159\u00edzen\u00ed nebo ve stejn\u00e9m prohl\u00ed\u017ee\u010di, kde jste proces p\u0159ihl\u00e1\u0161en\u00ed zapo\u010dali.</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk({ label: D('Zru\u0161it') }) +
        '</div></div></div>'
      return B(a)
    }
    Kk.a = 'firebaseui.auth.soy2.page.differentDeviceError'
    function Lk() {
      var a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-anonymous-user-mismatch"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Relace byla ukon\u010dena</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Relace p\u0159idru\u017een\u00e1 k t\u00e9to \u017e\u00e1dosti o p\u0159ihl\u00e1\u0161en\u00ed bu\u010f vypr\u0161ela, nebo byla vymaz\u00e1na.</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk({ label: D('Zru\u0161it') }) +
        '</div></div></div>'
      return B(a)
    }
    Lk.a = 'firebaseui.auth.soy2.page.anonymousUserMismatch'
    function Mk(a, b, c) {
      b = ''
      a =
        'K p\u0159ihl\u00e1\u0161en\u00ed jste ji\u017e pou\u017eili adresu <strong>' +
        (A(a.email) +
          '</strong>. Zadejte p\u0159\u00edslu\u0161n\u00e9 heslo k \u00fa\u010dtu.')
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-linking"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><h2 class="firebaseui-subtitle">Ji\u017e m\u00e1te \u00fa\u010det</h2><p class="firebaseui-text">' +
        a +
        '</p>' +
        Zj() +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-links">' +
        ak() +
        '</div><div class="firebaseui-form-actions">' +
        Vj() +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Mk.a = 'firebaseui.auth.soy2.page.passwordLinking'
    function Nk(a, b, c) {
      var d = a.email
      b = ''
      a = '' + jk(a, c)
      a = D(a)
      d =
        'Metodu p\u0159ihl\u00e1\u0161en\u00ed p\u0159es <strong>' +
        (A(d) +
          ('</strong> u\u017e jste pou\u017eili. \u00da\u010det <strong>' +
            (A(a) +
              ('</strong> m\u016f\u017eete propojit se slu\u017ebou <strong>' +
                (A(d) +
                  '</strong> p\u0159ihl\u00e1\u0161en\u00edm prost\u0159ednictv\u00edm e-mailov\u00e9ho odkazu n\u00ed\u017ee.')))))
      a =
        'Aby do\u0161lo k \u00fasp\u011b\u0161n\u00e9mu propojen\u00ed \u00fa\u010dtu ' +
        (A(a) +
          ' s touto e-mailovou adresou, mus\u00edte odkaz otev\u0159\u00edt na stejn\u00e9m za\u0159\u00edzen\u00ed nebo ve stejn\u00e9m prohl\u00ed\u017ee\u010di.')
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-linking"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><h2 class="firebaseui-subtitle">Ji\u017e m\u00e1te \u00fa\u010det</h2><p class="firebaseui-text firebaseui-text-justify">' +
        d +
        '<p class="firebaseui-text firebaseui-text-justify">' +
        a +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        Vj() +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Nk.a = 'firebaseui.auth.soy2.page.emailLinkSignInLinking'
    function Ok(a, b, c) {
      b = ''
      var d = '' + jk(a, c)
      d = D(d)
      a =
        'P\u016fvodn\u011b jste cht\u011bli p\u0159ipojit <strong>' +
        (A(d) +
          '</strong> ke sv\u00e9mu e-mailov\u00e9mu \u00fa\u010dtu, ale otev\u0159eli jste odkaz na jin\u00e9m za\u0159\u00edzen\u00ed, na kter\u00e9m nejste p\u0159ihl\u00e1\u0161eni.')
      d =
        'Pokud st\u00e1le chcete p\u0159ipojit sv\u016fj \u00fa\u010det <strong>' +
        (A(d) +
          '</strong>, otev\u0159ete odkaz na za\u0159\u00edzen\u00ed, na kter\u00e9m jste s p\u0159ihl\u00e1\u0161en\u00edm za\u010dali. Jinak klepn\u011bte na Pokra\u010dovat a p\u0159ihlaste se na tomto za\u0159\u00edzen\u00ed.')
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-linking-different-device"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text firebaseui-text-justify">' +
        a +
        '</p><p class="firebaseui-text firebaseui-text-justify">' +
        d +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        Xj() +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Ok.a = 'firebaseui.auth.soy2.page.emailLinkSignInLinkingDifferentDevice'
    function Pk(a, b, c) {
      var d = a.email
      b = ''
      a = '' + jk(a, c)
      a = D(a)
      d =
        'K p\u0159ihl\u00e1\u0161en\u00ed jste ji\u017e pou\u017eili adresu <strong>' +
        (A(d) +
          ('</strong>. Chcete-li pokra\u010dovat, p\u0159ihlaste se p\u0159es ' +
            (A(a) + '.')))
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-federated-linking"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><h2 class="firebaseui-subtitle">Ji\u017e m\u00e1te \u00fa\u010det</h2><p class="firebaseui-text">' +
        d +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        Uj({ label: D('P\u0159ihl\u00e1\u0161en\u00ed p\u0159es ' + a) }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Pk.a = 'firebaseui.auth.soy2.page.federatedLinking'
    function Qk(a, b, c) {
      a = a || {}
      var d = a.kc
      b = a.yb
      a = a.Eb
      var e =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unauthorized-user"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Nem\u00e1te povolen\u00ed</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">'
      d
        ? ((d =
            '\u00da\u010det <strong>' +
            (A(d) +
              '</strong> nem\u00e1 opr\u00e1vn\u011bn\u00ed zobrazit po\u017eadovanou str\u00e1nku.')),
          (e += d))
        : (e += 'User is not authorized to view the requested page.')
      e += '</p>'
      b &&
        ((b =
          'Pokud chcete z\u00edskat povolen\u00ed, napi\u0161te na <strong>' +
          (A(b) + '</strong>.')),
        (e +=
          '<p class="firebaseui-text firebaseui-id-unauthorized-user-admin-email">' +
          b +
          '</p>'))
      e +=
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-links">'
      a &&
        (e +=
          '<a class="firebaseui-link firebaseui-id-unauthorized-user-help-link" href="javascript:void(0)" target="_blank">Dal\u0161\u00ed informace</a>')
      e +=
        '</div><div class="firebaseui-form-actions">' +
        bk({ label: D('Zp\u011bt') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(e)
    }
    Qk.a = 'firebaseui.auth.soy2.page.unauthorizedUser'
    function Rk(a, b, c) {
      b = ''
      a =
        'Abyste na tomto za\u0159\u00edzen\u00ed z\u016fstali p\u0159ihl\u00e1\u0161eni pomoc\u00ed e-mailu <strong>' +
        (A(a.email) + '</strong>, mus\u00edte si obnovit heslo.')
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unsupported-provider"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        a +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk(null) +
        Uj({ label: D('Obnoven\u00ed hesla') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    Rk.a = 'firebaseui.auth.soy2.page.unsupportedProvider'
    function Sk(a) {
      var b = '',
        c =
          '<p class="firebaseui-text">k \u00fa\u010dtu <strong>' +
          (A(a.email) + '</strong></p>')
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Obnoven\u00ed hesla</h1></div><div class="firebaseui-card-content">' +
        c +
        Yj(sd(a)) +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        Wj() +
        '</div></div></form></div>'
      return B(b)
    }
    Sk.a = 'firebaseui.auth.soy2.page.passwordReset'
    function Tk(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset-success"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Heslo bylo zm\u011bn\u011bno</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Nyn\u00ed se m\u016f\u017eete p\u0159ihl\u00e1sit pomoc\u00ed nov\u00e9ho hesla</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    Tk.a = 'firebaseui.auth.soy2.page.passwordResetSuccess'
    function Uk(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset-failure"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Zkuste heslo znovu obnovit</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Platnost va\u0161\u00ed \u017e\u00e1dosti o obnoven\u00ed hesla vypr\u0161ela nebo byl odkaz ji\u017e pou\u017eit</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    Uk.a = 'firebaseui.auth.soy2.page.passwordResetFailure'
    function Vk(a) {
      var b = a.G,
        c = ''
      a =
        'Va\u0161e p\u0159ihla\u0161ovac\u00ed e-mailov\u00e1 adresa byla zm\u011bn\u011bna zp\u011bt na <strong>' +
        (A(a.email) + '</strong>.')
      c +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-change-revoke-success"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">E-mailov\u00e1 adresa byla zm\u011bn\u011bna</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        a +
        '</p><p class="firebaseui-text">Pokud jste ne\u017e\u00e1dali o zm\u011bnu p\u0159ihla\u0161ovac\u00edho e-mailu, je mo\u017en\u00e9, \u017ee se n\u011bkdo sna\u017e\u00ed z\u00edskat p\u0159\u00edstup k va\u0161emu \u00fa\u010dtu. <a class="firebaseui-link firebaseui-id-reset-password-link" href="javascript:void(0)">Co nejd\u0159\u00edve si proto zm\u011b\u0148te heslo</a>.</p></div><div class="firebaseui-card-actions">' +
        (b ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></form></div>'
      return B(c)
    }
    Vk.a = 'firebaseui.auth.soy2.page.emailChangeRevokeSuccess'
    function Wk(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-change-revoke-failure"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Nepoda\u0159ilo se zm\u011bnit e-mailovou adresu</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Zm\u011bnu e-mailov\u00e9 adresy na p\u016fvodn\u00ed adresu se nepoda\u0159ilo prov\u00e9st.</p><p class="firebaseui-text">Pokud se obnoven\u00ed adresy nezda\u0159\u00ed ani p\u0159i dal\u0161\u00edm pokusu, po\u017e\u00e1dejte o pomoc sv\u00e9ho spr\u00e1vce.</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    Wk.a = 'firebaseui.auth.soy2.page.emailChangeRevokeFailure'
    function Xk(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-verification-success"><div class="firebaseui-card-header"><h1 class="firebaseui-title">V\u00e1\u0161 e-mail byl ov\u011b\u0159en</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Nyn\u00ed se m\u016f\u017eete p\u0159ihl\u00e1sit prost\u0159ednictv\u00edm nov\u00e9ho \u00fa\u010dtu</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    Xk.a = 'firebaseui.auth.soy2.page.emailVerificationSuccess'
    function Yk(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-verification-failure"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Zkuste e-mail ov\u011b\u0159it znovu</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Platnost va\u0161\u00ed \u017e\u00e1dosti o ov\u011b\u0159en\u00ed e-mailu vypr\u0161ela nebo byl odkaz ji\u017e pou\u017eit</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    Yk.a = 'firebaseui.auth.soy2.page.emailVerificationFailure'
    function $k(a) {
      var b = a.G,
        c = ''
      a =
        'Nyn\u00ed se m\u016f\u017eete p\u0159ihl\u00e1sit prost\u0159ednictv\u00edm nov\u00e9ho \u00fa\u010dtu <strong>' +
        (A(a.email) + '</strong>.')
      c +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-verify-and-change-email-success"><div class="firebaseui-card-header"><h1 class="firebaseui-title">V\u00e1s e-mail byl ov\u011bren a zm\u011bn\u011bn</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        a +
        '</p></div><div class="firebaseui-card-actions">' +
        (b ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(c)
    }
    $k.a = 'firebaseui.auth.soy2.page.verifyAndChangeEmailSuccess'
    function al(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-verify-and-change-email-failure"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Zkuste e-mail znovu aktualizovat</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Platnost va\u0161\u00ed \u017e\u00e1dosti o ov\u011b\u0159en\u00ed a aktualizaci e-mailu vypr\u0161ela nebo byl odkaz ji\u017e pou\u017eit.</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    al.a = 'firebaseui.auth.soy2.page.verifyAndChangeEmailFailure'
    function bl(a) {
      var b = a.factorId,
        c = a.phoneNumber
      a = a.G
      var d =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-revert-second-factor-addition-success"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Druh\u00fd faktor odebr\u00e1n</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">'
      switch (b) {
        case 'phone':
          b =
            'Odebr\u00e1na druh\u00e1 f\u00e1ze ov\u011b\u0159en\u00ed: <strong>' +
            (A(b) + (' ' + (A(c) + '</strong>.')))
          d += b
          break
        default:
          d +=
            'Za\u0159\u00edzen\u00ed nebo aplikace byla z druh\u00e9 f\u00e1ze ov\u011b\u0159en\u00ed odebr\u00e1na.'
      }
      d +=
        '</p><p class="firebaseui-text">Pokud toto za\u0159\u00edzen\u00ed nepozn\u00e1v\u00e1te, n\u011bkdo se mo\u017en\u00e1 sna\u017e\u00ed z\u00edskat p\u0159\u00edstup k va\u0161emu \u00fa\u010dtu. Zva\u017ete <a class="firebaseui-link firebaseui-id-reset-password-link" href="javascript:void(0)">okam\u017eitou zm\u011bnu hesla</a>.</p></div><div class="firebaseui-card-actions">' +
        (a ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></form></div>'
      return B(d)
    }
    bl.a = 'firebaseui.auth.soy2.page.revertSecondFactorAdditionSuccess'
    function cl(a) {
      a = a || {}
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-revert-second-factor-addition-failure"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Druh\u00fd faktor nelze odebrat</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">N\u011bco se pokazilo p\u0159i odeb\u00edr\u00e1n\u00ed druh\u00e9ho faktoru.</p><p class="firebaseui-text">Zkuste ho odebrat znovu. Pokud tento postup nefunguje, kontaktujte podporu.</p></div><div class="firebaseui-card-actions">' +
        (a.G ? '<div class="firebaseui-form-actions">' + Xj() + '</div>' : '') +
        '</div></div>'
      return B(a)
    }
    cl.a = 'firebaseui.auth.soy2.page.revertSecondFactorAdditionFailure'
    function dl(a) {
      var b = a.zb
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-recoverable-error"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Do\u0161lo k chyb\u011b</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        A(a.errorMessage) +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">'
      b && (a += Uj({ label: D('Znovu') }))
      return B(a + '</div></div></div>')
    }
    dl.a = 'firebaseui.auth.soy2.page.recoverableError'
    function el(a) {
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unrecoverable-error"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Do\u0161lo k chyb\u011b</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        A(a.errorMessage) +
        '</p></div></div>'
      return B(a)
    }
    el.a = 'firebaseui.auth.soy2.page.unrecoverableError'
    function fl(a, b, c) {
      var d = a.Qb
      b = ''
      a = 'Pokra\u010dovat s adresou ' + (A(a.jc) + '?')
      d =
        'P\u016fvodn\u011b jste se cht\u011bli p\u0159ihl\u00e1sit prost\u0159ednictv\u00edm adresy ' +
        A(d)
      b +=
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-mismatch"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><h2 class="firebaseui-subtitle">' +
        a +
        '</h2><p class="firebaseui-text">' +
        d +
        '</p></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk(null) +
        Uj({ label: D('Pokra\u010dovat') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form></div>'
      return B(b)
    }
    fl.a = 'firebaseui.auth.soy2.page.emailMismatch'
    function gl(a, b, c) {
      var d =
        '<div class="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner"><div class="firebaseui-card-content"><form onsubmit="return false;"><ul class="firebaseui-idp-list">'
      a = a.Sb
      b = a.length
      for (var e = 0; e < b; e++) {
        var f = { ga: a[e] },
          g = c
        f = f || {}
        var h = f.ga
        var k = f
        k = k || {}
        var p = ''
        switch (k.ga.providerId) {
          case 'google.com':
            p += 'firebaseui-idp-google'
            break
          case 'github.com':
            p += 'firebaseui-idp-github'
            break
          case 'facebook.com':
            p += 'firebaseui-idp-facebook'
            break
          case 'twitter.com':
            p += 'firebaseui-idp-twitter'
            break
          case 'phone':
            p += 'firebaseui-idp-phone'
            break
          case 'anonymous':
            p += 'firebaseui-idp-anonymous'
            break
          case 'password':
            p += 'firebaseui-idp-password'
            break
          default:
            p += 'firebaseui-idp-generic'
        }
        k =
          '<button class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised ' +
          ud(C(p)) +
          ' firebaseui-id-idp-button" data-provider-id="' +
          ud(h.providerId) +
          '" style="background-color:'
        p = (p = f) || {}
        p = p.ga
        k =
          k +
          ud(
            Dd(
              C(
                p.ta
                  ? p.ta
                  : g.wa[p.providerId]
                    ? '' + g.wa[p.providerId]
                    : 0 == p.providerId.indexOf('saml.')
                      ? '' + g.wa.saml
                      : 0 == p.providerId.indexOf('oidc.')
                        ? '' + g.wa.oidc
                        : '' + g.wa.password
              )
            )
          ) +
          '"><span class="firebaseui-idp-icon-wrapper"><img class="firebaseui-idp-icon" alt="" src="'
        var t = f
        p = g
        t = t || {}
        t = t.ga
        p = rd(
          t.za
            ? zd(t.za)
            : p.xa[t.providerId]
              ? zd(p.xa[t.providerId])
              : 0 == t.providerId.indexOf('saml.')
                ? zd(p.xa.saml)
                : 0 == t.providerId.indexOf('oidc.')
                  ? zd(p.xa.oidc)
                  : zd(p.xa.password)
        )
        k = k + ud(zd(p)) + '"></span>'
        'password' == h.providerId
          ? ((k +=
              '<span class="firebaseui-idp-text firebaseui-idp-text-long">'),
            h.V
              ? (k += A(h.V))
              : h.S
                ? ((f =
                    'P\u0159ihl\u00e1\u0161en\u00ed p\u0159es ' + A(jk(f, g))),
                  (k += f))
                : (k += 'P\u0159ihl\u00e1\u0161en\u00ed pomoc\u00ed e-mailu'),
            (k +=
              '</span><span class="firebaseui-idp-text firebaseui-idp-text-short">'),
            (k = h.S ? k + A(h.S) : k + 'E-mail'),
            (k += '</span>'))
          : 'phone' == h.providerId
            ? ((k +=
                '<span class="firebaseui-idp-text firebaseui-idp-text-long">'),
              h.V
                ? (k += A(h.V))
                : h.S
                  ? ((f =
                      'P\u0159ihl\u00e1\u0161en\u00ed p\u0159es ' +
                      A(jk(f, g))),
                    (k += f))
                  : (k +=
                      'P\u0159ihl\u00e1\u0161en\u00ed pomoc\u00ed telefonu'),
              (k +=
                '</span><span class="firebaseui-idp-text firebaseui-idp-text-short">'),
              (k = h.S ? k + A(h.S) : k + 'Telefon'),
              (k += '</span>'))
            : 'anonymous' == h.providerId
              ? ((k +=
                  '<span class="firebaseui-idp-text firebaseui-idp-text-long">'),
                h.V
                  ? (k += A(h.V))
                  : h.S
                    ? ((f =
                        'P\u0159ihl\u00e1\u0161en\u00ed p\u0159es ' +
                        A(jk(f, g))),
                      (k += f))
                    : (k += 'Pokra\u010dovat jako host'),
                (k +=
                  '</span><span class="firebaseui-idp-text firebaseui-idp-text-short">'),
                (k = h.S ? k + A(h.S) : k + 'Host'),
                (k += '</span>'))
              : ((k +=
                  '<span class="firebaseui-idp-text firebaseui-idp-text-long">'),
                h.V
                  ? (k += A(h.V))
                  : ((p =
                      'P\u0159ihl\u00e1\u0161en\u00ed p\u0159es ' +
                      A(jk(f, g))),
                    (k += p)),
                (k +=
                  '</span><span class="firebaseui-idp-text firebaseui-idp-text-short">' +
                  (h.S ? A(h.S) : A(jk(f, g))) +
                  '</span>'))
        h = B(k + '</button>')
        d += '<li class="firebaseui-list-item">' + h + '</li>'
      }
      d +=
        '</ul></form></div><div class="firebaseui-card-footer firebaseui-provider-sign-in-footer">' +
        dk(c) +
        '</div></div>'
      return B(d)
    }
    gl.a = 'firebaseui.auth.soy2.page.providerSignIn'
    function hl(a, b, c) {
      a = a || {}
      var d = a.Gb,
        e = a.Va
      b = a.ia
      a = a || {}
      a = a.Aa
      a =
        '<div class="firebaseui-phone-number"><button class="firebaseui-id-country-selector firebaseui-country-selector mdl-button mdl-js-button"><span class="firebaseui-flag firebaseui-country-selector-flag firebaseui-id-country-selector-flag"></span><span class="firebaseui-id-country-selector-code"></span></button><div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label firebaseui-textfield firebaseui-phone-input-wrapper"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-phone-number-input">Telefonn\u00ed \u010d\u00edslo</label><input type="tel" name="phoneNumber" id="ui-sign-in-phone-number-input" class="mdl-textfield__input firebaseui-input firebaseui-id-phone-number" value="' +
        ud(null != a ? a : '') +
        '"></div></div><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error"></p></div>'
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-start"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Zad\u00e1n\u00ed telefonn\u00edho \u010d\u00edsla</h1></div><div class="firebaseui-card-content"><div class="firebaseui-relative-wrapper">' +
        B(a)
      var f
      d
        ? (f = B(
            '<div class="firebaseui-recaptcha-wrapper"><div class="firebaseui-recaptcha-container"></div><div class="firebaseui-error-wrapper firebaseui-recaptcha-error-wrapper"><p class="firebaseui-error firebaseui-hidden firebaseui-id-recaptcha-error"></p></div></div>'
          ))
        : (f = '')
      f =
        a +
        f +
        '</div></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        (e ? bk(null) : '') +
        Uj({ label: D('Ov\u011b\u0159it') }) +
        '</div></div><div class="firebaseui-card-footer">'
      b
        ? ((b = '<p class="firebaseui-tos firebaseui-phone-tos">'),
          (b =
            c.F && c.D
              ? b +
                'Klepnut\u00edm na tla\u010d\u00edtko Ov\u011b\u0159it vyjad\u0159ujete sv\u016fj souhlas s na\u0161imi <a href="javascript:void(0)" class="firebaseui-link firebaseui-tos-link" target="_blank">smluvn\u00edmi podm\u00ednkami</a> a <a href="javascript:void(0)" class="firebaseui-link firebaseui-pp-link" target="_blank">z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f</a>. M\u016f\u017ee b\u00fdt odesl\u00e1na SMS a mohou b\u00fdt \u00fa\u010dtov\u00e1ny poplatky za zpr\u00e1vy a data.'
              : b +
                'Po klepnut\u00ed na Ov\u011b\u0159it m\u016f\u017ee b\u00fdt odesl\u00e1na SMS. Mohou b\u00fdt \u00fa\u010dtov\u00e1ny poplatky za zpr\u00e1vy a data.'),
          (c = B(b + '</p>')))
        : (c =
            B(
              '<p class="firebaseui-tos firebaseui-phone-sms-notice">Po klepnut\u00ed na Ov\u011b\u0159it m\u016f\u017ee b\u00fdt odesl\u00e1na SMS. Mohou b\u00fdt \u00fa\u010dtov\u00e1ny poplatky za zpr\u00e1vy a data.</p>'
            ) + ck(c))
      return B(f + c + '</div></form></div>')
    }
    hl.a = 'firebaseui.auth.soy2.page.phoneSignInStart'
    function il(a, b, c) {
      a = a || {}
      b = a.phoneNumber
      var d = ''
      a =
        'Zadejte 6m\u00edstn\u00fd k\u00f3d, kter\u00fd jsme zaslali na \u010d\u00edslo <a class="firebaseui-link firebaseui-change-phone-number-link firebaseui-id-change-phone-number-link" href="javascript:void(0)">&lrm;' +
        (A(b) + '</a>')
      A(b)
      b = d
      d = B(
        '<div class="firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label"><label class="mdl-textfield__label firebaseui-label" for="ui-sign-in-phone-confirmation-code-input">\u0160estim\u00edstn\u00fd k\u00f3d</label><input type="number" name="phoneConfirmationCode" id="ui-sign-in-phone-confirmation-code-input" class="mdl-textfield__input firebaseui-input firebaseui-id-phone-confirmation-code"></div><div class="firebaseui-error-wrapper"><p class="firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-phone-confirmation-code-error"></p></div>'
      )
      c =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-finish"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Ov\u011b\u0159en\u00ed telefonn\u00edho \u010d\u00edsla</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">' +
        a +
        '</p>' +
        d +
        '</div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        bk(null) +
        Uj({ label: D('Pokra\u010dovat') }) +
        '</div></div><div class="firebaseui-card-footer">' +
        ck(c) +
        '</div></form>'
      a = B(
        '<div class="firebaseui-resend-container"><span class="firebaseui-id-resend-countdown"></span><a href="javascript:void(0)" class="firebaseui-id-resend-link firebaseui-hidden firebaseui-link">Znovu odeslat</a></div>'
      )
      return B(b + (c + a + '</div>'))
    }
    il.a = 'firebaseui.auth.soy2.page.phoneSignInFinish'
    function jl() {
      return B(
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-out"><div class="firebaseui-card-header"><h1 class="firebaseui-title">Odhl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><p class="firebaseui-text">Byli jste \u00fasp\u011b\u0161n\u011b odhl\u00e1\u0161eni.</p></div></div>'
      )
    }
    jl.a = 'firebaseui.auth.soy2.page.signOut'
    function kl(a, b, c) {
      var d =
        '<div class="firebaseui-container firebaseui-page-select-tenant firebaseui-id-page-select-tenant"><div class="firebaseui-card-content"><form onsubmit="return false;"><ul class="firebaseui-tenant-list">'
      a = a.ec
      b = a.length
      for (var e = 0; e < b; e++) {
        var f = a[e]
        var g = ''
        var h = A(f.displayName),
          k = f.tenantId ? f.tenantId : 'top-level-project'
        k = D(k)
        g +=
          '<button class="firebaseui-tenant-button mdl-button mdl-js-button mdl-button--raised firebaseui-tenant-selection-' +
          ud(k) +
          ' firebaseui-id-tenant-selection-button"' +
          (f.tenantId ? 'data-tenant-id="' + ud(f.tenantId) + '"' : '') +
          'style="background-color:' +
          ud(Dd(f.ta)) +
          '"><span class="firebaseui-idp-icon-wrapper"><img class="firebaseui-idp-icon" alt="" src="' +
          ud(zd(f.za)) +
          '"></span><span class="firebaseui-idp-text firebaseui-idp-text-long">'
        f.V
          ? (g += A(f.V))
          : ((f =
              'P\u0159ihl\u00e1sit se k \u00fa\u010dtu ' + A(f.displayName)),
            (g += f))
        g = B(
          g +
            ('</span><span class="firebaseui-idp-text firebaseui-idp-text-short">' +
              h +
              '</span></button>')
        )
        d += '<li class="firebaseui-list-item">' + g + '</li>'
      }
      d +=
        '</ul></form></div><div class="firebaseui-card-footer firebaseui-provider-sign-in-footer">' +
        dk(c) +
        '</div></div>'
      return B(d)
    }
    kl.a = 'firebaseui.auth.soy2.page.selectTenant'
    function ll(a, b, c) {
      a =
        '<div class="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-provider-match-by-email"><form onsubmit="return false;"><div class="firebaseui-card-header"><h1 class="firebaseui-title">P\u0159ihl\u00e1\u0161en\u00ed</h1></div><div class="firebaseui-card-content"><div class="firebaseui-relative-wrapper">' +
        Tj(null) +
        '</div></div><div class="firebaseui-card-actions"><div class="firebaseui-form-actions">' +
        Uj(null) +
        '</div></div><div class="firebaseui-card-footer">' +
        dk(c) +
        '</div></form></div>'
      return B(a)
    }
    ll.a = 'firebaseui.auth.soy2.page.providerMatchByEmail'
    function ml() {
      return M(this, 'firebaseui-id-submit')
    }
    function nl() {
      return M(this, 'firebaseui-id-secondary-link')
    }
    function ol(a, b) {
      O(this, ml.call(this), function (d) {
        a(d)
      })
      var c = nl.call(this)
      c &&
        b &&
        O(this, c, function (d) {
          b(d)
        })
    }
    function pl() {
      return M(this, 'firebaseui-id-password')
    }
    function ql() {
      return M(this, 'firebaseui-id-password-error')
    }
    function rl() {
      var a = pl.call(this),
        b = ql.call(this)
      Mj(this, a, function () {
        Sj(b) && (N(a, !0), Qj(b))
      })
    }
    function sl() {
      var a = pl.call(this)
      var b = ql.call(this)
      Zi(a)
        ? (N(a, !0), Qj(b), (b = !0))
        : (N(a, !1), Rj(b, C('Zadejte sv\u00e9 heslo').toString()), (b = !1))
      return b ? Zi(a) : null
    }
    function tl(a, b, c, d, e, f) {
      P.call(this, Mk, { email: a }, f, 'passwordLinking', { F: d, D: e })
      this.w = b
      this.H = c
    }
    m(tl, P)
    tl.prototype.v = function () {
      this.P()
      this.M(this.w, this.H)
      yk(this, this.i(), this.w)
      this.i().focus()
      P.prototype.v.call(this)
    }
    tl.prototype.o = function () {
      this.w = null
      P.prototype.o.call(this)
    }
    tl.prototype.j = function () {
      return Zi(M(this, 'firebaseui-id-email'))
    }
    u(tl.prototype, { i: pl, B: ql, P: rl, u: sl, ea: ml, ba: nl, M: ol })
    var ul =
      /^[+a-zA-Z0-9_.!#$%&'*\/=?^`{|}~-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,63}$/
    function vl() {
      return M(this, 'firebaseui-id-email')
    }
    function wl() {
      return M(this, 'firebaseui-id-email-error')
    }
    function xl(a) {
      var b = vl.call(this),
        c = wl.call(this)
      Mj(this, b, function () {
        Sj(c) && (N(b, !0), Qj(c))
      })
      a &&
        Nj(this, b, function () {
          a()
        })
    }
    function yl() {
      return Ua(Zi(vl.call(this)) || '')
    }
    function zl() {
      var a = vl.call(this)
      var b = wl.call(this)
      var c = Zi(a) || ''
      c
        ? ul.test(c)
          ? (N(a, !0), Qj(b), (b = !0))
          : (N(a, !1),
            Rj(
              b,
              C('E-mailov\u00e1 adresa nen\u00ed spr\u00e1vn\u00e1.').toString()
            ),
            (b = !1))
        : (N(a, !1),
          Rj(b, C('Nejprve zadejte svou e-mailovou adresu.').toString()),
          (b = !1))
      return b ? Ua(Zi(a)) : null
    }
    function Al(a, b, c, d, e, f, g) {
      P.call(this, Ak, { email: c, ia: !!f }, g, 'passwordSignIn', {
        F: d,
        D: e,
      })
      this.w = a
      this.H = b
    }
    m(Al, P)
    Al.prototype.v = function () {
      this.P()
      this.ea()
      this.ba(this.w, this.H)
      xk(this, this.l(), this.i())
      yk(this, this.i(), this.w)
      Zi(this.l()) ? this.i().focus() : this.l().focus()
      P.prototype.v.call(this)
    }
    Al.prototype.o = function () {
      this.H = this.w = null
      P.prototype.o.call(this)
    }
    u(Al.prototype, {
      l: vl,
      U: wl,
      P: xl,
      M: yl,
      j: zl,
      i: pl,
      B: ql,
      ea: rl,
      u: sl,
      ua: ml,
      pa: nl,
      ba: ol,
    })
    function R(a, b, c, d, e, f) {
      P.call(this, a, b, d, e || 'notice', f)
      this.i = c || null
    }
    w(R, P)
    R.prototype.v = function () {
      this.i && (this.u(this.i), this.l().focus())
      R.K.v.call(this)
    }
    R.prototype.o = function () {
      this.i = null
      R.K.o.call(this)
    }
    u(R.prototype, { l: ml, w: nl, u: ol })
    function Bl(a, b, c, d, e) {
      R.call(
        this,
        Dk,
        { email: a, G: !!b },
        b,
        e,
        'passwordRecoveryEmailSent',
        { F: c, D: d }
      )
    }
    w(Bl, R)
    function Cl(a, b) {
      R.call(this, Xk, { G: !!a }, a, b, 'emailVerificationSuccess')
    }
    w(Cl, R)
    function Dl(a, b) {
      R.call(this, Yk, { G: !!a }, a, b, 'emailVerificationFailure')
    }
    w(Dl, R)
    function El(a, b, c) {
      R.call(
        this,
        $k,
        { email: a, G: !!b },
        b,
        c,
        'verifyAndChangeEmailSuccess'
      )
    }
    w(El, R)
    function Fl(a, b) {
      R.call(this, al, { G: !!a }, a, b, 'verifyAndChangeEmailFailure')
    }
    w(Fl, R)
    function Gl(a, b) {
      R.call(this, cl, { G: !!a }, a, b, 'revertSecondFactorAdditionFailure')
    }
    w(Gl, R)
    function Hl(a) {
      R.call(this, jl, void 0, void 0, a, 'signOut')
    }
    w(Hl, R)
    function Il(a, b) {
      R.call(this, Tk, { G: !!a }, a, b, 'passwordResetSuccess')
    }
    w(Il, R)
    function Jl(a, b) {
      R.call(this, Uk, { G: !!a }, a, b, 'passwordResetFailure')
    }
    w(Jl, R)
    function Kl(a, b) {
      R.call(this, Wk, { G: !!a }, a, b, 'emailChangeRevokeFailure')
    }
    w(Kl, R)
    function Ll(a, b, c) {
      R.call(this, dl, { errorMessage: a, zb: !!b }, b, c, 'recoverableError')
    }
    w(Ll, R)
    function Ml(a, b) {
      R.call(this, el, { errorMessage: a }, void 0, b, 'unrecoverableError')
    }
    w(Ml, R)
    function Nl(a) {
      if (
        'auth/invalid-credential' === a.code &&
        a.message &&
        -1 !== a.message.indexOf('error=consent_required')
      )
        return { code: 'auth/user-cancelled' }
      if (
        a.message &&
        -1 !== a.message.indexOf('HTTP Cloud Function returned an error:')
      ) {
        var b = JSON.parse(
          a.message.substring(
            a.message.indexOf('{'),
            a.message.lastIndexOf('}') + 1
          )
        )
        return {
          code: a.code,
          message: (b && b.error && b.error.message) || a.message,
        }
      }
      return a
    }
    function Ol(a, b, c, d) {
      function e(g) {
        if (!g.name || 'cancel' != g.name) {
          a: {
            var h = g.message
            try {
              var k = ((JSON.parse(h).error || {}).message || '')
                .toLowerCase()
                .match(/invalid.+(access|id)_token/)
              if (k && k.length) {
                var p = !0
                break a
              }
            } catch (t) {}
            p = !1
          }
          if (p)
            (g = Q(b)),
              b.m(),
              S(
                a,
                g,
                void 0,
                C(
                  'Platnost relace p\u0159ihla\u0161ov\u00e1n\u00ed vypr\u0161ela. Zkuste to znovu.'
                ).toString()
              )
          else {
            p = (g && g.message) || ''
            if (g.code) {
              if (
                'auth/email-already-in-use' == g.code ||
                'auth/credential-already-in-use' == g.code
              )
                return
              p = T(g)
            }
            b.a(p)
          }
        }
      }
      Pl(a)
      if (d) return Ql(a, c), F()
      if (!c.credential) throw Error('No credential found!')
      if (!U(a).currentUser && !c.user) throw Error('User not logged in.')
      try {
        var f = Rl(a, c)
      } catch (g) {
        return rg(g.code || g.message, g), b.a(g.code || g.message), F()
      }
      c = f
        .then(function (g) {
          Ql(a, g)
        }, e)
        .then(void 0, e)
      V(a, f)
      return F(c)
    }
    function Ql(a, b) {
      if (!b.user) throw Error('No user found')
      var c = Pi(W(a))
      Ni(W(a)) &&
        c &&
        wg(
          'Both signInSuccess and signInSuccessWithAuthResult callbacks are provided. Only signInSuccessWithAuthResult callback will be invoked.'
        )
      if (c) {
        c = Pi(W(a))
        var d = Bh(X(a)) || void 0
        zh(vh, X(a))
        var e = !1
        if (tf()) {
          if (!c || c(b, d)) (e = !0), Nc(window.opener.location, Sl(a, d))
          c || window.close()
        } else if (!c || c(b, d)) (e = !0), Nc(window.location, Sl(a, d))
        e || a.reset()
      } else {
        c = b.user
        b = b.credential
        d = Ni(W(a))
        e = Bh(X(a)) || void 0
        zh(vh, X(a))
        var f = !1
        if (tf()) {
          if (!d || d(c, b, e)) (f = !0), Nc(window.opener.location, Sl(a, e))
          d || window.close()
        } else if (!d || d(c, b, e)) (f = !0), Nc(window.location, Sl(a, e))
        f || a.reset()
      }
    }
    function Sl(a, b) {
      a = b || W(a).a.get('signInSuccessUrl')
      if (!a)
        throw Error(
          'No redirect URL has been found. You must either specify a signInSuccessUrl in the configuration, pass in a redirect URL to the widget URL, or return false from the callback.'
        )
      return a
    }
    function T(a) {
      if (a.code && 'auth/password-does-not-meet-requirements' == a.code) {
        var b = a.message,
          c = '',
          d = '',
          e = (a = !1),
          f = !1,
          g = !1,
          h = !1,
          k = !1,
          p = b.match(/Password must contain at least (\d+)/)
        p && ((a = !0), (c = p[1]))
        if ((p = b.match(/Password may contain at most (\d+)/)))
          (e = !0), (d = p[1])
        b.includes('Password must contain a lower case character') && (f = !0)
        b.includes('Password must contain an upper case character') && (g = !0)
        b.includes('Password must contain a numeric character') && (h = !0)
        b.includes('Password must contain a non-alphanumeric character') &&
          (k = !0)
        b = c
        return C(
          Nd({ code: 'auth/password-does-not-meet-requirements' }) +
            ' [ ' +
            (a && null != b
              ? C('Password must contain at least ' + (b + ' characters')) +
                '. '
              : '') +
            (e && null != d
              ? C('Password may contain at most ' + (d + ' characters')) + '. '
              : '') +
            (f
              ? C('Password must contain a lower case character') + '. '
              : '') +
            (g
              ? C('Password must contain an upper case character') + '. '
              : '') +
            (h ? C('Password must contain a numeric character') + '. ' : '') +
            (k
              ? C('Password must contain a non-alphanumeric character') + '. '
              : '') +
            ']'
        ).toString()
      }
      if ((e = Nd({ code: a.code }).toString())) return e
      try {
        return (
          JSON.parse(a.message),
          rg('Internal error: ' + a.message, void 0),
          Ld().toString()
        )
      } catch (t) {
        return a.message
      }
    }
    function Tl(a, b, c) {
      var d =
        di[b] && firebase.auth[di[b]]
          ? new firebase.auth[di[b]]()
          : 0 == b.indexOf('saml.')
            ? new firebase.auth.SAMLAuthProvider(b)
            : new firebase.auth.OAuthProvider(b)
      if (!d) throw Error('Invalid Firebase Auth provider!')
      var e = zi(W(a), b)
      if (d.addScope) for (var f = 0; f < e.length; f++) d.addScope(e[f])
      e = Ai(W(a), b) || {}
      c &&
        (b == firebase.auth.GoogleAuthProvider.PROVIDER_ID
          ? (a = 'login_hint')
          : b == firebase.auth.GithubAuthProvider.PROVIDER_ID
            ? (a = 'login')
            : (a = (a = li(W(a), b)) && a.Ob),
        a && (e[a] = c))
      d.setCustomParameters && d.setCustomParameters(e)
      return d
    }
    function Ul(a, b, c, d) {
      function e() {
        Gh(new Gg(a.h.tenantId || null), X(a))
        V(
          a,
          b.I(
            r(a.dc, a),
            [k],
            function () {
              if ('file:' === (window.location && window.location.protocol))
                return V(
                  a,
                  Vl(a).then(function (p) {
                    b.m()
                    zh(uh, X(a))
                    L('callback', a, h, F(p))
                  }, f)
                )
            },
            g
          )
        )
      }
      function f(p) {
        zh(uh, X(a))
        if (!p.name || 'cancel' != p.name)
          switch (((p = Nl(p)), p.code)) {
            case 'auth/popup-blocked':
              e()
              break
            case 'auth/popup-closed-by-user':
            case 'auth/cancelled-popup-request':
              break
            case 'auth/credential-already-in-use':
              break
            case 'auth/network-request-failed':
            case 'auth/too-many-requests':
            case 'auth/user-cancelled':
              b.a(T(p))
              break
            case 'auth/admin-restricted-operation':
              b.m()
              si(W(a))
                ? L('handleUnauthorizedUser', a, h, null, c)
                : L('callback', a, h, gf(p))
              break
            default:
              b.m(), L('callback', a, h, gf(p))
          }
      }
      function g(p) {
        zh(uh, X(a))
        ;(p.name && 'cancel' == p.name) ||
          (rg('signInWithRedirect: ' + p.code, void 0),
          (p = T(p)),
          'blank' == b.Ga && Ji(W(a))
            ? (b.m(), L('providerSignIn', a, h, p))
            : b.a(p))
      }
      var h = Q(b),
        k = Tl(a, c, d)
      Ki(W(a)) == Li
        ? e()
        : V(
            a,
            Wl(a, k).then(function (p) {
              b.m()
              L('callback', a, h, F(p))
            }, f)
          )
    }
    function Xl(a, b) {
      V(
        a,
        b.I(
          r(a.$b, a),
          [],
          function (c) {
            b.m()
            return Ol(a, b, c, !0)
          },
          function (c) {
            ;(c.name && 'cancel' == c.name) ||
              (rg('ContinueAsGuest: ' + c.code, void 0), (c = T(c)), b.a(c))
          }
        )
      )
    }
    function Yl(a, b, c) {
      function d(f) {
        var g = !1
        f = b.I(
          r(a.ac, a),
          [f],
          function (h) {
            var k = Q(b)
            b.m()
            L('callback', a, k, F(h))
            g = !0
          },
          function (h) {
            if (!h.name || 'cancel' != h.name)
              if (!h || 'auth/credential-already-in-use' != h.code)
                if (
                  h &&
                  'auth/email-already-in-use' == h.code &&
                  h.email &&
                  h.credential
                ) {
                  var k = Q(b)
                  b.m()
                  L('callback', a, k, gf(h))
                } else
                  h && 'auth/admin-restricted-operation' == h.code && si(W(a))
                    ? ((h = Q(b)),
                      b.m(),
                      L(
                        'handleUnauthorizedUser',
                        a,
                        h,
                        null,
                        firebase.auth.GoogleAuthProvider.PROVIDER_ID
                      ))
                    : ((h = T(h)), b.a(h))
          }
        )
        V(a, f)
        return f.then(
          function () {
            return g
          },
          function () {
            return !1
          }
        )
      }
      if (c && c.credential && c.clientId === oi(W(a))) {
        if (zi(W(a), firebase.auth.GoogleAuthProvider.PROVIDER_ID).length) {
          try {
            var e = JSON.parse(atob(c.credential.split('.')[1])).email
          } catch (f) {}
          Ul(a, b, firebase.auth.GoogleAuthProvider.PROVIDER_ID, e)
          return F(!0)
        }
        return d(firebase.auth.GoogleAuthProvider.credential(c.credential))
      }
      c &&
        b.a(
          C(
            'Vybran\u00e9 p\u0159ihla\u0161ovac\u00ed \u00fadaje pro dan\u00e9ho poskytovatele slu\u017eeb ov\u011b\u0159en\u00ed nejsou podporov\u00e1ny.'
          ).toString()
        )
      return F(!1)
    }
    function Zl(a, b) {
      var c = b.j(),
        d = b.u()
      if (c)
        if (d) {
          var e = firebase.auth.EmailAuthProvider.credential(c, d)
          V(
            a,
            b.I(
              r(a.bc, a),
              [c, d],
              function (f) {
                return Ol(a, b, {
                  user: f.user,
                  credential: e,
                  operationType: f.operationType,
                  additionalUserInfo: f.additionalUserInfo,
                })
              },
              function (f) {
                if (!f.name || 'cancel' != f.name)
                  switch (f.code) {
                    case 'auth/email-already-in-use':
                      break
                    case 'auth/email-exists':
                      N(b.l(), !1)
                      Rj(b.U(), T(f))
                      break
                    case 'auth/too-many-requests':
                    case 'auth/wrong-password':
                      N(b.i(), !1)
                      Rj(b.B(), T(f))
                      break
                    default:
                      rg('verifyPassword: ' + f.message, void 0), b.a(T(f))
                  }
              }
            )
          )
        } else b.i().focus()
      else b.l().focus()
    }
    function $l(a) {
      a = ki(W(a))
      return (
        1 == a.length && a[0] == firebase.auth.EmailAuthProvider.PROVIDER_ID
      )
    }
    function am(a) {
      a = ki(W(a))
      return (
        1 == a.length && a[0] == firebase.auth.PhoneAuthProvider.PROVIDER_ID
      )
    }
    function S(a, b, c, d) {
      $l(a)
        ? d
          ? L('signIn', a, b, c, d)
          : bm(a, b, c)
        : a && am(a) && !d
          ? L('phoneSignInStart', a, b)
          : a && Ji(W(a)) && !d
            ? L('federatedRedirect', a, b, c)
            : L('providerSignIn', a, b, d, c)
    }
    function cm(a, b, c, d) {
      var e = Q(b)
      V(
        a,
        b.I(
          r(U(a).fetchSignInMethodsForEmail, U(a)),
          [c],
          function (f) {
            b.m()
            dm(a, e, f, c, d)
          },
          function (f) {
            f = T(f)
            b.a(f)
          }
        )
      )
    }
    function dm(a, b, c, d, e, f) {
      c.length || (Gi(W(a)) && !Gi(W(a)))
        ? Ma(c, firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)
          ? L('passwordSignIn', a, b, d, f)
          : 1 == c.length &&
              c[0] === firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
            ? Gi(W(a))
              ? L('sendEmailLinkForSignIn', a, b, d, function () {
                  L('signIn', a, b)
                })
              : L('unsupportedProvider', a, b, d)
            : (c = bi(c, ki(W(a))))
              ? (Eh(new Dg(d), X(a)), L('federatedSignIn', a, b, d, c, e))
              : L('unsupportedProvider', a, b, d)
        : ri(W(a))
          ? L(
              'handleUnauthorizedUser',
              a,
              b,
              d,
              firebase.auth.EmailAuthProvider.PROVIDER_ID
            )
          : Gi(W(a))
            ? L('sendEmailLinkForSignIn', a, b, d, function () {
                L('signIn', a, b)
              })
            : L('passwordSignUp', a, b, d, void 0, void 0, f)
    }
    function em(a, b, c, d, e, f) {
      var g = Q(b)
      V(
        a,
        b.I(
          r(a.Ib, a),
          [c, f],
          function () {
            b.m()
            L('emailLinkSignInSent', a, g, c, d, f)
          },
          e
        )
      )
    }
    function bm(a, b, c) {
      c ? L('prefilledEmailSignIn', a, b, c) : L('signIn', a, b)
    }
    function fm() {
      return tb(wf(), 'oobCode')
    }
    function gm() {
      var a = tb(wf(), 'continueUrl')
      return a
        ? function () {
            Nc(window.location, a)
          }
        : null
    }
    function hm(a, b) {
      P.call(this, Lk, void 0, b, 'anonymousUserMismatch')
      this.i = a
    }
    m(hm, P)
    hm.prototype.v = function () {
      var a = this
      O(this, this.l(), function () {
        a.i()
      })
      this.l().focus()
      P.prototype.v.call(this)
    }
    hm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(hm.prototype, { l: nl })
    K.anonymousUserMismatch = function (a, b) {
      var c = new hm(function () {
        c.m()
        S(a, b)
      })
      c.render(b)
      Y(a, c)
    }
    function im(a) {
      P.call(this, Ek, void 0, a, 'callback')
    }
    m(im, P)
    im.prototype.I = function (a, b, c, d) {
      return a.apply(null, b).then(c, d)
    }
    function jm(a, b, c) {
      if (c.user) {
        var d = {
            user: c.user,
            credential: c.credential,
            operationType: c.operationType,
            additionalUserInfo: c.additionalUserInfo,
          },
          e = Ch(X(a)),
          f = e && e.g
        if (f && !km(c.user, f)) lm(a, b, d)
        else {
          var g = e && e.a
          g
            ? V(
                a,
                c.user.linkWithCredential(g).then(
                  function (h) {
                    d = {
                      user: h.user,
                      credential: g,
                      operationType: h.operationType,
                      additionalUserInfo: h.additionalUserInfo,
                    }
                    mm(a, b, d)
                  },
                  function (h) {
                    nm(a, b, h)
                  }
                )
              )
            : mm(a, b, d)
        }
      } else (c = Q(b)), b.m(), Dh(X(a)), S(a, c)
    }
    function mm(a, b, c) {
      Dh(X(a))
      Ol(a, b, c)
    }
    function nm(a, b, c) {
      var d = Q(b)
      Dh(X(a))
      c = T(c)
      b.m()
      S(a, d, void 0, c)
    }
    function om(a, b, c, d) {
      var e = Q(b)
      V(
        a,
        U(a)
          .fetchSignInMethodsForEmail(c)
          .then(
            function (f) {
              b.m()
              f.length
                ? Ma(
                    f,
                    firebase.auth.EmailAuthProvider
                      .EMAIL_PASSWORD_SIGN_IN_METHOD
                  )
                  ? L('passwordLinking', a, e, c)
                  : 1 == f.length &&
                      f[0] ===
                        firebase.auth.EmailAuthProvider
                          .EMAIL_LINK_SIGN_IN_METHOD
                    ? L('emailLinkSignInLinking', a, e, c)
                    : (f = bi(f, ki(W(a))))
                      ? L('federatedLinking', a, e, c, f, d)
                      : (Dh(X(a)), L('unsupportedProvider', a, e, c))
                : (Dh(X(a)),
                  L('passwordRecovery', a, e, c, !1, Md().toString()))
            },
            function (f) {
              nm(a, b, f)
            }
          )
      )
    }
    function lm(a, b, c) {
      var d = Q(b)
      V(
        a,
        pm(a).then(
          function () {
            b.m()
            L('emailMismatch', a, d, c)
          },
          function (e) {
            ;(e.name && 'cancel' == e.name) || ((e = T(e.code)), b.a(e))
          }
        )
      )
    }
    function km(a, b) {
      if (b == a.email) return !0
      if (a.providerData)
        for (var c = 0; c < a.providerData.length; c++)
          if (b == a.providerData[c].email) return !0
      return !1
    }
    K.callback = function (a, b, c) {
      var d = new im()
      d.render(b)
      Y(a, d)
      c = c || Vl(a)
      V(
        a,
        c.then(
          function (e) {
            jm(a, d, e)
          },
          function (e) {
            if (
              (e = Nl(e)) &&
              ('auth/account-exists-with-different-credential' == e.code ||
                'auth/email-already-in-use' == e.code) &&
              e.email &&
              e.credential
            )
              Eh(new Dg(e.email, e.credential), X(a)), om(a, d, e.email)
            else if (e && 'auth/user-cancelled' == e.code) {
              var f = Ch(X(a)),
                g = T(e)
              f && f.a ? om(a, d, f.g, g) : f ? cm(a, d, f.g, g) : nm(a, d, e)
            } else
              (e && 'auth/credential-already-in-use' == e.code) ||
                (e &&
                'auth/operation-not-supported-in-this-environment' == e.code &&
                $l(a)
                  ? jm(a, d, { user: null, credential: null })
                  : e && 'auth/admin-restricted-operation' == e.code && si(W(a))
                    ? (d.m(),
                      Dh(X(a)),
                      L('handleUnauthorizedUser', a, b, null, null))
                    : nm(a, d, e))
          }
        )
      )
    }
    function qm(a, b) {
      P.call(this, Kk, void 0, b, 'differentDeviceError')
      this.i = a
    }
    m(qm, P)
    qm.prototype.v = function () {
      var a = this
      O(this, this.l(), function () {
        a.i()
      })
      this.l().focus()
      P.prototype.v.call(this)
    }
    qm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(qm.prototype, { l: nl })
    K.differentDeviceError = function (a, b) {
      var c = new qm(function () {
        c.m()
        S(a, b)
      })
      c.render(b)
      Y(a, c)
    }
    function rm(a, b, c, d) {
      P.call(this, Vk, { email: a, G: !!c }, d, 'emailChangeRevoke')
      this.l = b
      this.i = c || null
    }
    m(rm, P)
    rm.prototype.v = function () {
      var a = this
      O(this, M(this, 'firebaseui-id-reset-password-link'), function () {
        a.l()
      })
      this.i && (this.w(this.i), this.u().focus())
      P.prototype.v.call(this)
    }
    rm.prototype.o = function () {
      this.l = this.i = null
      P.prototype.o.call(this)
    }
    u(rm.prototype, { u: ml, B: nl, w: ol })
    function sm() {
      return M(this, 'firebaseui-id-new-password')
    }
    function tm() {
      return M(this, 'firebaseui-id-password-toggle')
    }
    function um() {
      this.Ra = !this.Ra
      var a = tm.call(this),
        b = sm.call(this)
      this.Ra
        ? ((b.type = 'text'),
          Xi(a, 'firebaseui-input-toggle-off'),
          Yi(a, 'firebaseui-input-toggle-on'))
        : ((b.type = 'password'),
          Xi(a, 'firebaseui-input-toggle-on'),
          Yi(a, 'firebaseui-input-toggle-off'))
      b.focus()
    }
    function vm() {
      return M(this, 'firebaseui-id-new-password-error')
    }
    function wm() {
      this.Ra = !1
      var a = sm.call(this)
      a.type = 'password'
      var b = vm.call(this)
      Mj(this, a, function () {
        Sj(b) && (N(a, !0), Qj(b))
      })
      var c = tm.call(this)
      Xi(c, 'firebaseui-input-toggle-on')
      Yi(c, 'firebaseui-input-toggle-off')
      Oj(this, a, function () {
        Xi(c, 'firebaseui-input-toggle-focus')
        Yi(c, 'firebaseui-input-toggle-blur')
      })
      Pj(this, a, function () {
        Xi(c, 'firebaseui-input-toggle-blur')
        Yi(c, 'firebaseui-input-toggle-focus')
      })
      O(this, c, r(um, this))
    }
    function xm() {
      var a = sm.call(this)
      var b = vm.call(this)
      Zi(a)
        ? (N(a, !0), Qj(b), (b = !0))
        : (N(a, !1), Rj(b, C('Zadejte sv\u00e9 heslo').toString()), (b = !1))
      return b ? Zi(a) : null
    }
    function ym(a, b, c) {
      P.call(this, Sk, { email: a }, c, 'passwordReset')
      this.l = b
    }
    m(ym, P)
    ym.prototype.v = function () {
      this.H()
      this.B(this.l)
      yk(this, this.i(), this.l)
      this.i().focus()
      P.prototype.v.call(this)
    }
    ym.prototype.o = function () {
      this.l = null
      P.prototype.o.call(this)
    }
    u(ym.prototype, { i: sm, u: vm, M: tm, H: wm, w: xm, U: ml, P: nl, B: ol })
    function zm(a, b, c, d, e) {
      P.call(
        this,
        bl,
        { factorId: a, phoneNumber: c || null, G: !!d },
        e,
        'revertSecondFactorAdditionSuccess'
      )
      this.l = b
      this.i = d || null
    }
    m(zm, P)
    zm.prototype.v = function () {
      var a = this
      O(this, M(this, 'firebaseui-id-reset-password-link'), function () {
        a.l()
      })
      this.i && (this.w(this.i), this.u().focus())
      P.prototype.v.call(this)
    }
    zm.prototype.o = function () {
      this.l = this.i = null
      P.prototype.o.call(this)
    }
    u(zm.prototype, { u: ml, B: nl, w: ol })
    function Am(a, b, c, d, e) {
      var f = c.w()
      f &&
        V(
          a,
          c.I(
            r(U(a).confirmPasswordReset, U(a)),
            [d, f],
            function () {
              c.m()
              var g = new Il(e)
              g.render(b)
              Y(a, g)
            },
            function (g) {
              Bm(a, b, c, g)
            }
          )
        )
    }
    function Bm(a, b, c, d) {
      var e = d && d.code
      'auth/weak-password' === e
        ? ((a = T(d)), N(c.i(), !1), Rj(c.u(), a), c.i().focus())
        : 'auth/password-does-not-meet-requirements' === e
          ? ((a = T(d)), N(c.i(), !1), Rj(c.u(), a), c.i().focus())
          : (c && c.m(), (c = new Jl()), c.render(b), Y(a, c))
    }
    function Cm(a, b, c) {
      var d = new rm(c, function () {
        V(
          a,
          d.I(
            r(U(a).sendPasswordResetEmail, U(a)),
            [c],
            function () {
              d.m()
              d = new Bl(c, void 0, H(W(a)), I(W(a)))
              d.render(b)
              Y(a, d)
            },
            function () {
              d.a(Kd().toString())
            }
          )
        )
      })
      d.render(b)
      Y(a, d)
    }
    function Dm(a, b, c, d) {
      var e = new zm(
        d.factorId,
        function () {
          e.I(
            r(U(a).sendPasswordResetEmail, U(a)),
            [c],
            function () {
              e.m()
              e = new Bl(c, void 0, H(W(a)), I(W(a)))
              e.render(b)
              Y(a, e)
            },
            function () {
              e.a(Kd().toString())
            }
          )
        },
        d.phoneNumber
      )
      e.render(b)
      Y(a, e)
    }
    K.passwordReset = function (a, b, c, d) {
      V(
        a,
        U(a)
          .verifyPasswordResetCode(c)
          .then(
            function (e) {
              var f = new ym(e, function () {
                Am(a, b, f, c, d)
              })
              f.render(b)
              Y(a, f)
            },
            function () {
              Bm(a, b)
            }
          )
      )
    }
    K.emailChangeRevocation = function (a, b, c) {
      var d = null
      V(
        a,
        U(a)
          .checkActionCode(c)
          .then(function (e) {
            d = e.data.email
            return U(a).applyActionCode(c)
          })
          .then(
            function () {
              Cm(a, b, d)
            },
            function () {
              var e = new Kl()
              e.render(b)
              Y(a, e)
            }
          )
      )
    }
    K.emailVerification = function (a, b, c, d) {
      V(
        a,
        U(a)
          .applyActionCode(c)
          .then(
            function () {
              var e = new Cl(d)
              e.render(b)
              Y(a, e)
            },
            function () {
              var e = new Dl()
              e.render(b)
              Y(a, e)
            }
          )
      )
    }
    K.revertSecondFactorAddition = function (a, b, c) {
      var d = null,
        e = null
      V(
        a,
        U(a)
          .checkActionCode(c)
          .then(function (f) {
            d = f.data.email
            e = f.data.multiFactorInfo
            return U(a).applyActionCode(c)
          })
          .then(
            function () {
              Dm(a, b, d, e)
            },
            function () {
              var f = new Gl()
              f.render(b)
              Y(a, f)
            }
          )
      )
    }
    K.verifyAndChangeEmail = function (a, b, c, d) {
      var e = null
      V(
        a,
        U(a)
          .checkActionCode(c)
          .then(function (f) {
            e = f.data.email
            return U(a).applyActionCode(c)
          })
          .then(
            function () {
              var f = new El(e, d)
              f.render(b)
              Y(a, f)
            },
            function () {
              var f = new Fl()
              f.render(b)
              Y(a, f)
            }
          )
      )
    }
    function Em(a, b) {
      try {
        var c = 'number' == typeof a.selectionStart
      } catch (d) {
        c = !1
      }
      c
        ? ((a.selectionStart = b), (a.selectionEnd = b))
        : z &&
          !mc('9') &&
          ('textarea' == a.type &&
            (b = a.value.substring(0, b).replace(/(\r\n|\r|\n)/g, '\n').length),
          (a = a.createTextRange()),
          a.collapse(!0),
          a.move('character', b),
          a.select())
    }
    function Fm(a, b, c, d, e, f) {
      P.call(this, Jk, { email: c }, f, 'emailLinkSignInConfirmation', {
        F: d,
        D: e,
      })
      this.l = a
      this.u = b
    }
    m(Fm, P)
    Fm.prototype.v = function () {
      this.w(this.l)
      this.B(this.l, this.u)
      this.i().focus()
      Em(this.i(), (this.i().value || '').length)
      P.prototype.v.call(this)
    }
    Fm.prototype.o = function () {
      this.u = this.l = null
      P.prototype.o.call(this)
    }
    u(Fm.prototype, { i: vl, M: wl, w: xl, H: yl, j: zl, U: ml, P: nl, B: ol })
    K.emailLinkConfirmation = function (a, b, c, d, e, f) {
      var g = new Fm(
        function () {
          var h = g.j()
          h ? (g.m(), d(a, b, h, c)) : g.i().focus()
        },
        function () {
          g.m()
          S(a, b, e || void 0)
        },
        e || void 0,
        H(W(a)),
        I(W(a))
      )
      g.render(b)
      Y(a, g)
      f && g.a(f)
    }
    function Gm(a, b, c, d, e) {
      P.call(this, Ok, { ga: a }, e, 'emailLinkSignInLinkingDifferentDevice', {
        F: c,
        D: d,
      })
      this.i = b
    }
    m(Gm, P)
    Gm.prototype.v = function () {
      this.u(this.i)
      this.l().focus()
      P.prototype.v.call(this)
    }
    Gm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(Gm.prototype, { l: ml, u: ol })
    K.emailLinkNewDeviceLinking = function (a, b, c, d) {
      var e = new Pb(c)
      c = e.a.a.get(x.PROVIDER_ID) || null
      Tb(e, null)
      if (c) {
        var f = new Gm(
          li(W(a), c),
          function () {
            f.m()
            d(a, b, e.toString())
          },
          H(W(a)),
          I(W(a))
        )
        f.render(b)
        Y(a, f)
      } else S(a, b)
    }
    function Hm(a) {
      P.call(this, Gk, void 0, a, 'blank')
    }
    m(Hm, P)
    function Im(a, b, c, d, e) {
      var f = new Hm(),
        g = new Pb(c),
        h = g.a.a.get(x.$a) || '',
        k = g.a.a.get(x.Sa) || '',
        p = '1' === g.a.a.get(x.Qa),
        t = Sb(g),
        J = g.a.a.get(x.PROVIDER_ID) || null
      g = g.a.a.get(x.vb) || null
      Jm(a, g)
      var Ca = !yh(wh, X(a)),
        Zk = d || Hh(k, X(a)),
        ld = (d = Ih(k, X(a))) && d.a
      J && ld && ld.providerId !== J && (ld = null)
      f.render(b)
      Y(a, f)
      V(
        a,
        f.I(
          function () {
            var ya = F(null)
            ya =
              (t && Ca) || (Ca && p)
                ? gf(Error('anonymous-user-not-found'))
                : Km(a, c).then(function (xg) {
                    if (J && !ld) throw Error('pending-credential-not-found')
                    return xg
                  })
            var md = null
            return ya
              .then(function (xg) {
                md = xg
                return e ? null : U(a).checkActionCode(h)
              })
              .then(function () {
                return md
              })
          },
          [],
          function (ya) {
            Zk
              ? Lm(a, f, Zk, c, ld, ya)
              : p
                ? (f.m(), L('differentDeviceError', a, b))
                : (f.m(), L('emailLinkConfirmation', a, b, c, Mm))
          },
          function (ya) {
            var md = void 0
            if (!ya || !ya.name || 'cancel' != ya.name)
              switch ((f.m(), ya && ya.message)) {
                case 'anonymous-user-not-found':
                  L('differentDeviceError', a, b)
                  break
                case 'anonymous-user-mismatch':
                  L('anonymousUserMismatch', a, b)
                  break
                case 'pending-credential-not-found':
                  L('emailLinkNewDeviceLinking', a, b, c, Nm)
                  break
                default:
                  ya && (md = T(ya)), S(a, b, void 0, md)
              }
          }
        )
      )
    }
    function Mm(a, b, c, d) {
      Im(a, b, d, c, !0)
    }
    function Nm(a, b, c) {
      Im(a, b, c)
    }
    function Lm(a, b, c, d, e, f) {
      var g = Q(b)
      b.$(
        'mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon',
        C('P\u0159ihla\u0161ov\u00e1n\u00ed\u2026').toString()
      )
      var h = null
      e = (f ? Om(a, f, c, d, e) : Pm(a, c, d, e)).then(
        function (k) {
          zh(xh, X(a))
          zh(wh, X(a))
          b.h()
          b.$(
            'firebaseui-icon-done',
            C('Jste p\u0159ihl\u00e1\u0161eni!').toString()
          )
          h = setTimeout(function () {
            b.h()
            Ol(a, b, k, !0)
          }, 1e3)
          V(a, function () {
            b && (b.h(), b.m())
            clearTimeout(h)
          })
        },
        function (k) {
          b.h()
          b.m()
          if (!k.name || 'cancel' != k.name) {
            k = Nl(k)
            var p = T(k)
            'auth/email-already-in-use' == k.code ||
            'auth/credential-already-in-use' == k.code
              ? (zh(xh, X(a)), zh(wh, X(a)))
              : 'auth/invalid-email' == k.code
                ? ((p = C(
                    'Poskytnut\u00fd e-mail neodpov\u00edd\u00e1 aktu\u00e1ln\u00ed p\u0159ihla\u0161ovac\u00ed relaci.'
                  ).toString()),
                  L('emailLinkConfirmation', a, g, d, Mm, null, p))
                : S(a, g, c, p)
          }
        }
      )
      V(a, e)
    }
    K.emailLinkSignInCallback = Im
    function Qm(a, b, c, d, e, f) {
      P.call(this, Nk, { email: a, ga: b }, f, 'emailLinkSignInLinking', {
        F: d,
        D: e,
      })
      this.i = c
    }
    m(Qm, P)
    Qm.prototype.v = function () {
      this.u(this.i)
      this.l().focus()
      P.prototype.v.call(this)
    }
    Qm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(Qm.prototype, { l: ml, u: ol })
    function Rm(a, b, c, d) {
      var e = Q(b)
      em(
        a,
        b,
        c,
        function () {
          S(a, e, c)
        },
        function (f) {
          if (!f.name || 'cancel' != f.name) {
            var g = T(f)
            f && 'auth/network-request-failed' == f.code
              ? b.a(g)
              : (b.m(), S(a, e, c, g))
          }
        },
        d
      )
    }
    K.emailLinkSignInLinking = function (a, b, c) {
      var d = Ch(X(a))
      Dh(X(a))
      if (d) {
        var e = d.a.providerId,
          f = new Qm(
            c,
            li(W(a), e),
            function () {
              Rm(a, f, c, d)
            },
            H(W(a)),
            I(W(a))
          )
        f.render(b)
        Y(a, f)
      } else S(a, b)
    }
    function Sm(a, b, c, d, e, f) {
      P.call(this, Hk, { email: a }, f, 'emailLinkSignInSent', { F: d, D: e })
      this.u = b
      this.i = c
    }
    m(Sm, P)
    Sm.prototype.v = function () {
      var a = this
      O(this, this.l(), function () {
        a.i()
      })
      O(this, M(this, 'firebaseui-id-trouble-getting-email-link'), function () {
        a.u()
      })
      this.l().focus()
      P.prototype.v.call(this)
    }
    Sm.prototype.o = function () {
      this.i = this.u = null
      P.prototype.o.call(this)
    }
    u(Sm.prototype, { l: nl })
    K.emailLinkSignInSent = function (a, b, c, d, e) {
      var f = new Sm(
        c,
        function () {
          f.m()
          L('emailNotReceived', a, b, c, d, e)
        },
        function () {
          f.m()
          d()
        },
        H(W(a)),
        I(W(a))
      )
      f.render(b)
      Y(a, f)
    }
    function Tm(a, b, c, d, e, f, g) {
      P.call(this, fl, { jc: a, Qb: b }, g, 'emailMismatch', { F: e, D: f })
      this.l = c
      this.i = d
    }
    m(Tm, P)
    Tm.prototype.v = function () {
      this.w(this.l, this.i)
      this.u().focus()
      P.prototype.v.call(this)
    }
    Tm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(Tm.prototype, { u: ml, B: nl, w: ol })
    K.emailMismatch = function (a, b, c) {
      var d = Ch(X(a))
      if (d) {
        var e = new Tm(
          c.user.email,
          d.g,
          function () {
            var f = e
            Dh(X(a))
            Ol(a, f, c)
          },
          function () {
            var f = c.credential.providerId,
              g = Q(e)
            e.m()
            d.a
              ? L('federatedLinking', a, g, d.g, f)
              : L('federatedSignIn', a, g, d.g, f)
          },
          H(W(a)),
          I(W(a))
        )
        e.render(b)
        Y(a, e)
      } else S(a, b)
    }
    function Um(a, b, c, d, e) {
      P.call(this, Ik, void 0, e, 'emailNotReceived', { F: c, D: d })
      this.l = a
      this.i = b
    }
    m(Um, P)
    Um.prototype.v = function () {
      var a = this
      O(this, this.u(), function () {
        a.i()
      })
      O(this, this.Da(), function () {
        a.l()
      })
      this.u().focus()
      P.prototype.v.call(this)
    }
    Um.prototype.Da = function () {
      return M(this, 'firebaseui-id-resend-email-link')
    }
    Um.prototype.o = function () {
      this.i = this.l = null
      P.prototype.o.call(this)
    }
    u(Um.prototype, { u: nl })
    K.emailNotReceived = function (a, b, c, d, e) {
      var f = new Um(
        function () {
          em(
            a,
            f,
            c,
            d,
            function (g) {
              g = T(g)
              f.a(g)
            },
            e
          )
        },
        function () {
          f.m()
          S(a, b, c)
        },
        H(W(a)),
        I(W(a))
      )
      f.render(b)
      Y(a, f)
    }
    function Vm(a, b, c, d, e, f) {
      P.call(this, Pk, { email: a, ga: b }, f, 'federatedLinking', {
        F: d,
        D: e,
      })
      this.i = c
    }
    m(Vm, P)
    Vm.prototype.v = function () {
      this.u(this.i)
      this.l().focus()
      P.prototype.v.call(this)
    }
    Vm.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(Vm.prototype, { l: ml, u: ol })
    K.federatedLinking = function (a, b, c, d, e) {
      var f = Ch(X(a))
      if (f && f.a) {
        var g = new Vm(
          c,
          li(W(a), d),
          function () {
            Ul(a, g, d, c)
          },
          H(W(a)),
          I(W(a))
        )
        g.render(b)
        Y(a, g)
        e && g.a(e)
      } else S(a, b)
    }
    K.federatedRedirect = function (a, b, c) {
      var d = new Hm()
      d.render(b)
      Y(a, d)
      b = ki(W(a))[0]
      Ul(a, d, b, c)
    }
    K.federatedSignIn = function (a, b, c, d, e) {
      var f = new Vm(
        c,
        li(W(a), d),
        function () {
          Ul(a, f, d, c)
        },
        H(W(a)),
        I(W(a))
      )
      f.render(b)
      Y(a, f)
      e && f.a(e)
    }
    function Wm(a, b, c, d) {
      var e = b.u()
      e
        ? V(
            a,
            b.I(
              r(a.Xb, a),
              [c, e],
              function (f) {
                f = f.user.linkWithCredential(d).then(function (g) {
                  return Ol(a, b, {
                    user: g.user,
                    credential: d,
                    operationType: g.operationType,
                    additionalUserInfo: g.additionalUserInfo,
                  })
                })
                V(a, f)
                return f
              },
              function (f) {
                if (!f.name || 'cancel' != f.name)
                  switch (f.code) {
                    case 'auth/wrong-password':
                      N(b.i(), !1)
                      Rj(b.B(), T(f))
                      break
                    case 'auth/too-many-requests':
                      b.a(T(f))
                      break
                    default:
                      rg('signInWithEmailAndPassword: ' + f.message, void 0),
                        b.a(T(f))
                  }
              }
            )
          )
        : b.i().focus()
    }
    K.passwordLinking = function (a, b, c) {
      var d = Ch(X(a))
      Dh(X(a))
      var e = d && d.a
      if (e) {
        var f = new tl(
          c,
          function () {
            Wm(a, f, c, e)
          },
          function () {
            f.m()
            L('passwordRecovery', a, b, c)
          },
          H(W(a)),
          I(W(a))
        )
        f.render(b)
        Y(a, f)
      } else S(a, b)
    }
    function Xm(a, b, c, d, e, f) {
      P.call(this, Ck, { email: c, Ta: !!b }, f, 'passwordRecovery', {
        F: d,
        D: e,
      })
      this.l = a
      this.u = b
    }
    m(Xm, P)
    Xm.prototype.v = function () {
      this.B()
      this.H(this.l, this.u)
      Zi(this.i()) || this.i().focus()
      yk(this, this.i(), this.l)
      P.prototype.v.call(this)
    }
    Xm.prototype.o = function () {
      this.u = this.l = null
      P.prototype.o.call(this)
    }
    u(Xm.prototype, { i: vl, w: wl, B: xl, M: yl, j: zl, U: ml, P: nl, H: ol })
    function Ym(a, b) {
      var c = b.j()
      if (c) {
        var d = Q(b)
        V(
          a,
          b.I(
            r(U(a).sendPasswordResetEmail, U(a)),
            [c],
            function () {
              b.m()
              var e = new Bl(
                c,
                function () {
                  e.m()
                  S(a, d)
                },
                H(W(a)),
                I(W(a))
              )
              e.render(d)
              Y(a, e)
            },
            function (e) {
              N(b.i(), !1)
              Rj(b.w(), T(e))
            }
          )
        )
      } else b.i().focus()
    }
    K.passwordRecovery = function (a, b, c, d, e) {
      var f = new Xm(
        function () {
          Ym(a, f)
        },
        d
          ? void 0
          : function () {
              f.m()
              S(a, b)
            },
        c,
        H(W(a)),
        I(W(a))
      )
      f.render(b)
      Y(a, f)
      e && f.a(e)
    }
    K.passwordSignIn = function (a, b, c, d) {
      var e = new Al(
        function () {
          Zl(a, e)
        },
        function () {
          var f = e.M()
          e.m()
          L('passwordRecovery', a, b, f)
        },
        c,
        H(W(a)),
        I(W(a)),
        d
      )
      e.render(b)
      Y(a, e)
    }
    function Zm() {
      return M(this, 'firebaseui-id-name')
    }
    function $m() {
      return M(this, 'firebaseui-id-name-error')
    }
    function an(a, b, c, d, e, f, g, h, k) {
      P.call(
        this,
        Bk,
        { email: d, Tb: a, name: e, Ta: !!c, ia: !!h },
        k,
        'passwordSignUp',
        { F: f, D: g }
      )
      this.w = b
      this.H = c
      this.B = a
    }
    m(an, P)
    an.prototype.v = function () {
      this.ea()
      this.B && this.Ja()
      this.ua()
      this.pa(this.w, this.H)
      this.B
        ? (xk(this, this.i(), this.u()), xk(this, this.u(), this.l()))
        : xk(this, this.i(), this.l())
      this.w && yk(this, this.l(), this.w)
      Zi(this.i())
        ? this.B && !Zi(this.u())
          ? this.u().focus()
          : this.l().focus()
        : this.i().focus()
      P.prototype.v.call(this)
    }
    an.prototype.o = function () {
      this.H = this.w = null
      P.prototype.o.call(this)
    }
    u(an.prototype, {
      i: vl,
      U: wl,
      ea: xl,
      jb: yl,
      j: zl,
      u: Zm,
      Bc: $m,
      Ja: function () {
        var a = Zm.call(this),
          b = $m.call(this)
        Mj(this, a, function () {
          Sj(b) && (N(a, !0), Qj(b))
        })
      },
      M: function () {
        var a = Zm.call(this)
        var b = $m.call(this)
        var c = Zi(a)
        c = !/^[\s\xa0]*$/.test(null == c ? '' : String(c))
        N(a, c)
        c
          ? (Qj(b), (b = !0))
          : (Rj(b, C('Zadejte n\u00e1zev \u00fa\u010dtu.').toString()),
            (b = !1))
        return b ? Ua(Zi(a)) : null
      },
      l: sm,
      ba: vm,
      lb: tm,
      ua: wm,
      P: xm,
      Nb: ml,
      Mb: nl,
      pa: ol,
    })
    function bn(a, b) {
      var c = Fi(W(a)),
        d = b.j(),
        e = null
      c && (e = b.M())
      var f = b.P()
      if (d) {
        if (c)
          if (e) e = cb(e)
          else {
            b.u().focus()
            return
          }
        if (f) {
          var g = firebase.auth.EmailAuthProvider.credential(d, f)
          V(
            a,
            b.I(
              r(a.Yb, a),
              [d, f],
              function (h) {
                var k = {
                  user: h.user,
                  credential: g,
                  operationType: h.operationType,
                  additionalUserInfo: h.additionalUserInfo,
                }
                return c
                  ? ((h = h.user
                      .updateProfile({ displayName: e })
                      .then(function () {
                        return Ol(a, b, k)
                      })),
                    V(a, h),
                    h)
                  : Ol(a, b, k)
              },
              function (h) {
                if (!h.name || 'cancel' != h.name) {
                  var k = Nl(h)
                  h = T(k)
                  switch (k.code) {
                    case 'auth/email-already-in-use':
                      return cn(a, b, d, k)
                    case 'auth/too-many-requests':
                      h = C(
                        'Z va\u0161\u00ed adresy IP p\u0159ich\u00e1z\u00ed p\u0159\u00edli\u0161 mnoho po\u017eadavk\u016f na \u00fa\u010det. Zkuste to znovu za n\u011bkolik minut.'
                      ).toString()
                    case 'auth/operation-not-allowed':
                    case 'auth/weak-password':
                      N(b.l(), !1)
                      Rj(b.ba(), h)
                      break
                    case 'auth/admin-restricted-operation':
                      si(W(a))
                        ? ((h = Q(b)),
                          b.m(),
                          L(
                            'handleUnauthorizedUser',
                            a,
                            h,
                            d,
                            firebase.auth.EmailAuthProvider.PROVIDER_ID
                          ))
                        : b.a(h)
                      break
                    default:
                      ;(k = 'setAccountInfo: ' + dh(k)), rg(k, void 0), b.a(h)
                  }
                }
              }
            )
          )
        } else b.l().focus()
      } else b.i().focus()
    }
    function cn(a, b, c, d) {
      function e() {
        var g = T(d)
        N(b.i(), !1)
        Rj(b.U(), g)
        b.i().focus()
      }
      var f = U(a)
        .fetchSignInMethodsForEmail(c)
        .then(
          function (g) {
            g.length
              ? e()
              : ((g = Q(b)),
                b.m(),
                L('passwordRecovery', a, g, c, !1, Md().toString()))
          },
          function () {
            e()
          }
        )
      V(a, f)
      return f
    }
    K.passwordSignUp = function (a, b, c, d, e, f) {
      function g() {
        h.m()
        S(a, b)
      }
      var h = new an(
        Fi(W(a)),
        function () {
          bn(a, h)
        },
        e ? void 0 : g,
        c,
        d,
        H(W(a)),
        I(W(a)),
        f
      )
      h.render(b)
      Y(a, h)
    }
    function dn() {
      return M(this, 'firebaseui-id-phone-confirmation-code')
    }
    function en() {
      return M(this, 'firebaseui-id-phone-confirmation-code-error')
    }
    function fn() {
      return M(this, 'firebaseui-id-resend-countdown')
    }
    function gn(a, b, c, d, e, f, g, h, k) {
      P.call(this, il, { phoneNumber: e }, k, 'phoneSignInFinish', {
        F: g,
        D: h,
      })
      this.jb = f
      this.i = new kj(1e3)
      this.B = f
      this.P = a
      this.l = b
      this.H = c
      this.M = d
    }
    m(gn, P)
    gn.prototype.v = function () {
      var a = this
      this.U(this.jb)
      ne(this.i, 'tick', this.w, !1, this)
      this.i.start()
      O(this, M(this, 'firebaseui-id-change-phone-number-link'), function () {
        a.P()
      })
      O(this, this.Da(), function () {
        a.M()
      })
      this.Ja(this.l)
      this.ea(this.l, this.H)
      this.u().focus()
      P.prototype.v.call(this)
    }
    gn.prototype.o = function () {
      this.M = this.H = this.l = this.P = null
      lj(this.i)
      ve(this.i, 'tick', this.w)
      this.i = null
      P.prototype.o.call(this)
    }
    gn.prototype.w = function () {
      --this.B
      0 < this.B
        ? this.U(this.B)
        : (lj(this.i), ve(this.i, 'tick', this.w), this.ua(), this.lb())
    }
    u(gn.prototype, {
      u: dn,
      pa: en,
      Ja: function (a) {
        var b = dn.call(this),
          c = en.call(this)
        Mj(this, b, function () {
          Sj(c) && (N(b, !0), Qj(c))
        })
        a &&
          Nj(this, b, function () {
            a()
          })
      },
      ba: function () {
        var a = Ua(Zi(dn.call(this)) || '')
        return /^\d{6}$/.test(a) ? a : null
      },
      Fb: fn,
      U: function (a) {
        $c(
          fn.call(this),
          C(
            'Opakovan\u00e9 odesl\u00e1n\u00ed k\u00f3du mo\u017en\u00e9 za ' +
              ((9 < a ? '0:' : '0:0') + a)
          ).toString()
        )
      },
      ua: function () {
        Qj(this.Fb())
      },
      Da: function () {
        return M(this, 'firebaseui-id-resend-link')
      },
      lb: function () {
        Rj(this.Da())
      },
      Nb: ml,
      Mb: nl,
      ea: ol,
    })
    function hn(a, b, c, d) {
      function e(g) {
        b.u().focus()
        N(b.u(), !1)
        Rj(b.pa(), g)
      }
      var f = b.ba()
      f
        ? (b.$(
            'mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon',
            Hd().toString()
          ),
          V(
            a,
            b.I(
              r(d.confirm, d),
              [f],
              function (g) {
                b.h()
                b.$('firebaseui-icon-done', C('Ov\u011b\u0159eno!').toString())
                var h = setTimeout(function () {
                  b.h()
                  b.m()
                  var k = {
                    user: jn(a).currentUser,
                    credential: null,
                    operationType: g.operationType,
                    additionalUserInfo: g.additionalUserInfo,
                  }
                  Ol(a, b, k, !0)
                }, 1e3)
                V(a, function () {
                  b && b.h()
                  clearTimeout(h)
                })
              },
              function (g) {
                if (g.name && 'cancel' == g.name) b.h()
                else {
                  var h = Nl(g)
                  g = T(h)
                  switch (h.code) {
                    case 'auth/credential-already-in-use':
                      b.h()
                      break
                    case 'auth/code-expired':
                      h = Q(b)
                      b.h()
                      b.m()
                      L('phoneSignInStart', a, h, c, g)
                      break
                    case 'auth/missing-verification-code':
                    case 'auth/invalid-verification-code':
                      b.h()
                      e(g)
                      break
                    default:
                      b.h(), b.a(g)
                  }
                }
              }
            )
          ))
        : e(Jd().toString())
    }
    K.phoneSignInFinish = function (a, b, c, d, e, f) {
      var g = new gn(
        function () {
          g.m()
          L('phoneSignInStart', a, b, c)
        },
        function () {
          hn(a, g, c, e)
        },
        function () {
          g.m()
          S(a, b)
        },
        function () {
          g.m()
          L('phoneSignInStart', a, b, c)
        },
        ai(c),
        d,
        H(W(a)),
        I(W(a))
      )
      g.render(b)
      Y(a, g)
      f && g.a(f)
    }
    var kn =
      !z &&
      !(
        y('Safari') &&
        !(
          Xb() ||
          y('Coast') ||
          y('Opera') ||
          y('Edge') ||
          y('Firefox') ||
          y('FxiOS') ||
          y('Silk') ||
          y('Android')
        )
      )
    function ln(a, b) {
      if (/-[a-z]/.test(b)) return null
      if (kn && a.dataset) {
        if (
          !(
            !y('Android') ||
            Xb() ||
            y('Firefox') ||
            y('FxiOS') ||
            y('Opera') ||
            y('Silk') ||
            b in a.dataset
          )
        )
          return null
        a = a.dataset[b]
        return void 0 === a ? null : a
      }
      return a.getAttribute(
        'data-' +
          String(b)
            .replace(/([A-Z])/g, '-$1')
            .toLowerCase()
      )
    }
    function mn(a, b, c) {
      var d = this
      a = hd(hk, { items: a }, null, this.s)
      ok.call(this, a, !0, !0)
      c && (c = nn(a, c)) && (c.focus(), Ej(c, a))
      O(this, a, function (e) {
        if (
          (e =
            (e = ad(e.target, 'firebaseui-id-list-box-dialog-button')) &&
            ln(e, 'listboxid'))
        )
          pk.call(d), b(e)
      })
    }
    function nn(a, b) {
      a = (a || document).getElementsByTagName('BUTTON')
      for (var c = 0; c < a.length; c++)
        if (ln(a[c], 'listboxid') === b) return a[c]
      return null
    }
    function on() {
      return M(this, 'firebaseui-id-phone-number')
    }
    function pn() {
      return M(this, 'firebaseui-id-country-selector')
    }
    function qn() {
      return M(this, 'firebaseui-id-phone-number-error')
    }
    function rn(a, b) {
      var c = a.a,
        d = sn('1-US-0', c),
        e = null
      b && sn(b, c)
        ? (e = b)
        : d
          ? (e = '1-US-0')
          : (e = 0 < c.length ? c[0].c : null)
      if (!e) throw Error('No available default country')
      tn.call(this, e, a)
    }
    function sn(a, b) {
      a = Th(a)
      return !(!a || !Ma(b, a))
    }
    function un(a) {
      return a.map(function (b) {
        return {
          id: b.c,
          Ma: 'firebaseui-flag ' + vn(b),
          label: b.name + ' ' + ('\u200e+' + b.b),
        }
      })
    }
    function vn(a) {
      return 'firebaseui-flag-' + a.f
    }
    function wn(a) {
      var b = this
      mn.call(
        this,
        un(a.a),
        function (c) {
          tn.call(b, c, a, !0)
          b.O().focus()
        },
        this.Ba
      )
    }
    function tn(a, b, c) {
      var d = Th(a)
      d &&
        (c &&
          ((c = Ua(Zi(on.call(this)) || '')),
          (b = Sh(b, c)),
          b.length &&
            b[0].b != d.b &&
            ((c = '+' + d.b + c.substr(b[0].b.length + 1)),
            $i(on.call(this), c))),
        (b = Th(this.Ba)),
        (this.Ba = a),
        (a = M(this, 'firebaseui-id-country-selector-flag')),
        b && Yi(a, vn(b)),
        Xi(a, vn(d)),
        $c(M(this, 'firebaseui-id-country-selector-code'), '\u200e+' + d.b))
    }
    function xn(a, b, c, d, e, f, g, h, k, p) {
      P.call(
        this,
        hl,
        { Gb: b, Aa: k || null, Va: !!c, ia: !!f },
        p,
        'phoneSignInStart',
        { F: d, D: e }
      )
      this.H = h || null
      this.M = b
      this.l = a
      this.w = c || null
      this.pa = g || null
    }
    m(xn, P)
    xn.prototype.v = function () {
      this.ea(this.pa, this.H)
      this.P(this.l, this.w || void 0)
      this.M || xk(this, this.O(), this.i())
      yk(this, this.i(), this.l)
      this.O().focus()
      Em(this.O(), (this.O().value || '').length)
      P.prototype.v.call(this)
    }
    xn.prototype.o = function () {
      this.w = this.l = null
      P.prototype.o.call(this)
    }
    u(xn.prototype, {
      Cb: qk,
      O: on,
      B: qn,
      ea: function (a, b, c) {
        var d = this,
          e = on.call(this),
          f = pn.call(this),
          g = qn.call(this),
          h = a || Yh,
          k = h.a
        if (0 == k.length) throw Error('No available countries provided.')
        rn.call(d, h, b)
        O(this, f, function () {
          wn.call(d, h)
        })
        Mj(this, e, function () {
          Sj(g) && (N(e, !0), Qj(g))
          var p = Ua(Zi(e) || ''),
            t = Th(this.Ba),
            J = Sh(h, p)
          p = sn('1-US-0', k)
          J.length &&
            J[0].b != t.b &&
            ((t = J[0]), tn.call(d, '1' == t.b && p ? '1-US-0' : t.c, h))
        })
        c &&
          Nj(this, e, function () {
            c()
          })
      },
      U: function (a) {
        var b = Ua(Zi(on.call(this)) || '')
        a = a || Yh
        var c = a.a,
          d = Sh(Yh, b)
        if (d.length && !Ma(c, d[0]))
          throw (
            ($i(on.call(this)),
            on.call(this).focus(),
            Rj(
              qn.call(this),
              C(
                'Zadan\u00fd k\u00f3d zem\u011b nen\u00ed podporov\u00e1n.'
              ).toString()
            ),
            Error('The country code provided is not supported.'))
          )
        c = Th(this.Ba)
        d.length && d[0].b != c.b && tn.call(this, d[0].c, a)
        d.length && (b = b.substr(d[0].b.length + 1))
        return b ? new Zh(this.Ba, b) : null
      },
      Ja: pn,
      ba: function () {
        return M(this, 'firebaseui-recaptcha-container')
      },
      u: function () {
        return M(this, 'firebaseui-id-recaptcha-error')
      },
      i: ml,
      ua: nl,
      P: ol,
    })
    function yn(a, b, c, d) {
      try {
        var e = b.U(Ti)
      } catch (f) {
        return
      }
      e
        ? Ri
          ? (b.$(
              'mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon',
              Hd().toString()
            ),
            V(
              a,
              b.I(
                r(a.cc, a),
                [ai(e), c],
                function (f) {
                  var g = Q(b)
                  b.$(
                    'firebaseui-icon-done',
                    C('K\u00f3d odesl\u00e1n!').toString()
                  )
                  var h = setTimeout(function () {
                    b.h()
                    b.m()
                    L('phoneSignInFinish', a, g, e, 15, f)
                  }, 1e3)
                  V(a, function () {
                    b && b.h()
                    clearTimeout(h)
                  })
                },
                function (f) {
                  b.h()
                  if (!f.name || 'cancel' != f.name) {
                    grecaptcha.reset(Ui)
                    Ri = null
                    var g = (f && f.message) || ''
                    if (f.code)
                      switch (f.code) {
                        case 'auth/too-many-requests':
                          g = C(
                            'Toto telefonn\u00ed \u010d\u00edslo ji\u017e bylo pou\u017eito p\u0159\u00edli\u0161 mnohokr\u00e1t'
                          ).toString()
                          break
                        case 'auth/invalid-phone-number':
                        case 'auth/missing-phone-number':
                          b.O().focus()
                          Rj(b.B(), Id().toString())
                          return
                        case 'auth/admin-restricted-operation':
                          if (si(W(a))) {
                            f = Q(b)
                            b.m()
                            L(
                              'handleUnauthorizedUser',
                              a,
                              f,
                              ai(e),
                              firebase.auth.PhoneAuthProvider.PROVIDER_ID
                            )
                            return
                          }
                          g = T(f)
                          break
                        default:
                          g = T(f)
                      }
                    b.a(g)
                  }
                }
              )
            ))
          : Si
            ? Rj(
                b.u(),
                C('Potvr\u010fte ov\u011b\u0159en\u00ed reCAPTCHA.').toString()
              )
            : !Si && d && b.i().click()
        : (b.O().focus(), Rj(b.B(), Id().toString()))
    }
    K.phoneSignInStart = function (a, b, c, d) {
      var e = ti(W(a)) || {}
      Ri = null
      Si = !(e && 'invisible' === e.size)
      var f = am(a),
        g = Ci(W(a)),
        h = f ? Bi(W(a)) : null
      g = (c && c.a) || (g && g.c) || null
      c = (c && c.Aa) || h
      ;(h = Di(W(a))) && Xh(h)
      Ti = h ? new Rh(Di(W(a))) : Yh
      var k = new xn(
        function (t) {
          yn(a, k, p, !(!t || !t.keyCode))
        },
        Si,
        f
          ? null
          : function () {
              p.clear()
              k.m()
              S(a, b)
            },
        H(W(a)),
        I(W(a)),
        f,
        Ti,
        g,
        c
      )
      k.render(b)
      Y(a, k)
      d && k.a(d)
      e.callback = function (t) {
        k.u() && Qj(k.u())
        Ri = t
        Si || yn(a, k, p)
      }
      e['expired-callback'] = function () {
        Ri = null
      }
      var p = new firebase.auth.RecaptchaVerifier(
        Si ? k.ba() : k.i(),
        e,
        jn(a).app
      )
      V(
        a,
        k.I(
          r(p.render, p),
          [],
          function (t) {
            Ui = t
          },
          function (t) {
            ;(t.name && 'cancel' == t.name) ||
              ((t = T(t)), k.m(), S(a, b, void 0, t))
          }
        )
      )
    }
    K.prefilledEmailSignIn = function (a, b, c) {
      var d = new Hm()
      d.render(b)
      Y(a, d)
      V(
        a,
        d.I(
          r(U(a).fetchSignInMethodsForEmail, U(a)),
          [c],
          function (e) {
            d.m()
            var f = !(!$l(a) || !zn(a))
            dm(a, b, e, c, void 0, f)
          },
          function (e) {
            e = T(e)
            d.m()
            L('signIn', a, b, c, e)
          }
        )
      )
    }
    function An(a, b, c, d, e) {
      P.call(this, gl, { Sb: b }, e, 'providerSignIn', { F: c, D: d })
      this.i = a
    }
    m(An, P)
    An.prototype.v = function () {
      this.l(this.i)
      P.prototype.v.call(this)
    }
    An.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(An.prototype, {
      l: function (a) {
        function b(g) {
          a(g)
        }
        for (
          var c = this.g
              ? Tc('firebaseui-id-idp-button', this.g || this.s.a)
              : [],
            d = 0;
          d < c.length;
          d++
        ) {
          var e = c[d],
            f = ln(e, 'providerId')
          O(this, e, za(b, f))
        }
      },
    })
    K.providerSignIn = function (a, b, c, d) {
      var e = new An(
        function (f) {
          f == firebase.auth.EmailAuthProvider.PROVIDER_ID
            ? (e.m(), bm(a, b, d))
            : f == firebase.auth.PhoneAuthProvider.PROVIDER_ID
              ? (e.m(), L('phoneSignInStart', a, b))
              : 'anonymous' == f
                ? Xl(a, e)
                : Ul(a, e, f, d)
          Z(a)
          a.l.cancel()
        },
        mi(W(a)),
        H(W(a)),
        I(W(a))
      )
      e.render(b)
      Y(a, e)
      c && e.a(c)
      Bn(a)
    }
    K.sendEmailLinkForSignIn = function (a, b, c, d) {
      var e = new im()
      e.render(b)
      Y(a, e)
      em(a, e, c, d, function (f) {
        e.m()
        f && 'auth/admin-restricted-operation' == f.code && si(W(a))
          ? L(
              'handleUnauthorizedUser',
              a,
              b,
              c,
              firebase.auth.EmailAuthProvider.PROVIDER_ID
            )
          : ((f = T(f)), L('signIn', a, b, c, f))
      })
    }
    function Cn(a, b, c, d, e, f, g) {
      P.call(this, zk, { email: c, Va: !!b, ia: !!f }, g, 'signIn', {
        F: d,
        D: e,
      })
      this.i = a
      this.u = b
    }
    m(Cn, P)
    Cn.prototype.v = function () {
      this.w(this.i)
      this.B(this.i, this.u || void 0)
      this.l().focus()
      Em(this.l(), (this.l().value || '').length)
      P.prototype.v.call(this)
    }
    Cn.prototype.o = function () {
      this.u = this.i = null
      P.prototype.o.call(this)
    }
    u(Cn.prototype, { l: vl, M: wl, w: xl, H: yl, j: zl, U: ml, P: nl, B: ol })
    K.signIn = function (a, b, c, d) {
      var e = $l(a),
        f = new Cn(
          function () {
            var g = f,
              h = g.j() || ''
            h && cm(a, g, h)
          },
          e
            ? null
            : function () {
                f.m()
                S(a, b, c)
              },
          c,
          H(W(a)),
          I(W(a)),
          e
        )
      f.render(b)
      Y(a, f)
      d && f.a(d)
    }
    function Dn(a, b, c, d, e, f, g) {
      P.call(this, Qk, { kc: a, yb: c, Eb: !!d }, g, 'unauthorizedUser', {
        F: e,
        D: f,
      })
      this.l = b
      this.i = d
    }
    m(Dn, P)
    Dn.prototype.v = function () {
      var a = this,
        b = M(this, 'firebaseui-id-unauthorized-user-help-link')
      this.i &&
        b &&
        O(this, b, function () {
          a.i()
        })
      O(this, this.u(), function () {
        a.l()
      })
      this.u().focus()
      P.prototype.v.call(this)
    }
    Dn.prototype.o = function () {
      this.i = this.l = null
      P.prototype.o.call(this)
    }
    u(Dn.prototype, { u: nl })
    K.handleUnauthorizedUser = function (a, b, c, d) {
      function e() {
        S(a, b)
      }
      d === firebase.auth.EmailAuthProvider.PROVIDER_ID
        ? (e = function () {
            bm(a, b)
          })
        : d === firebase.auth.PhoneAuthProvider.PROVIDER_ID &&
          (e = function () {
            L('phoneSignInStart', a, b)
          })
      var f = null,
        g = null
      d === firebase.auth.EmailAuthProvider.PROVIDER_ID && ri(W(a))
        ? ((f = xi(W(a))), (g = yi(W(a))))
        : si(W(a)) && ((f = vi(W(a))), (g = wi(W(a))))
      var h = new Dn(
        c,
        function () {
          h.m()
          e()
        },
        f,
        g,
        H(W(a)),
        I(W(a))
      )
      h.render(b)
      Y(a, h)
    }
    function En(a, b, c, d, e, f) {
      P.call(this, Rk, { email: a }, f, 'unsupportedProvider', { F: d, D: e })
      this.l = b
      this.i = c
    }
    m(En, P)
    En.prototype.v = function () {
      this.w(this.l, this.i)
      this.u().focus()
      P.prototype.v.call(this)
    }
    En.prototype.o = function () {
      this.i = this.l = null
      P.prototype.o.call(this)
    }
    u(En.prototype, { u: ml, B: nl, w: ol })
    K.unsupportedProvider = function (a, b, c) {
      var d = new En(
        c,
        function () {
          d.m()
          L('passwordRecovery', a, b, c)
        },
        function () {
          d.m()
          S(a, b, c)
        },
        H(W(a)),
        I(W(a))
      )
      d.render(b)
      Y(a, d)
    }
    function Fn(a, b) {
      this.$ = !1
      var c = Gn(b)
      if (Hn[c])
        throw Error('An AuthUI instance already exists for the key "' + c + '"')
      Hn[c] = this
      this.a = a
      this.u = null
      this.Y = !1
      In(this.a)
      this.h = firebase
        .initializeApp(
          {
            apiKey: a.app.options.apiKey,
            authDomain: a.app.options.authDomain,
          },
          a.app.name + '-firebaseui-temp'
        )
        .auth()
      if ((a = a.emulatorConfig))
        (c = a.port),
          this.h.useEmulator(
            a.protocol + '://' + a.host + (null === c ? '' : ':' + c),
            a.options
          )
      In(this.h)
      this.h.setPersistence &&
        this.h.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      this.oa = b
      this.ca = new ei()
      this.g = this.T = this.i = this.J = this.O = null
      this.s = []
      this.Z = !1
      this.l = Sf.Xa()
      this.j = this.C = null
      this.da = this.A = !1
    }
    function In(a) {
      a &&
        a.INTERNAL &&
        a.INTERNAL.logFramework &&
        a.INTERNAL.logFramework('FirebaseUI-web')
    }
    var Hn = {}
    function Gn(a) {
      return a || '[DEFAULT]'
    }
    function Vl(a) {
      Z(a)
      a.i ||
        (a.i = Jn(a, function (b) {
          return b && !Ch(X(a))
            ? F(
                jn(a)
                  .getRedirectResult()
                  .then(
                    function (c) {
                      return c
                    },
                    function (c) {
                      if (
                        c &&
                        'auth/email-already-in-use' == c.code &&
                        c.email &&
                        c.credential
                      )
                        throw c
                      return Kn(a, c)
                    }
                  )
              )
            : F(
                U(a)
                  .getRedirectResult()
                  .then(function (c) {
                    return gi(W(a)) && !c.user && a.j && !a.j.isAnonymous
                      ? jn(a).getRedirectResult()
                      : c
                  })
              )
        }))
      return a.i
    }
    function Y(a, b) {
      Z(a)
      a.g = b
    }
    var Ln = null
    function U(a) {
      Z(a)
      return a.h
    }
    function jn(a) {
      Z(a)
      return a.a
    }
    function X(a) {
      Z(a)
      return a.oa
    }
    function zn(a) {
      Z(a)
      return a.O ? a.O.emailHint : void 0
    }
    l = Fn.prototype
    l.nb = function () {
      Z(this)
      return !!Fh(X(this)) || Mn(wf())
    }
    function Mn(a) {
      a = new Pb(a)
      return 'signIn' === (a.a.a.get(x.ub) || null) && !!a.a.a.get(x.$a)
    }
    l.start = function (a, b) {
      Nn(this, a, b)
    }
    function Nn(a, b, c, d) {
      Z(a)
      'undefined' !== typeof a.a.languageCode && (a.u = a.a.languageCode)
      var e = 'cs'.replace(/_/g, '-')
      a.a.languageCode = e
      a.h.languageCode = e
      a.Y = !0
      'undefined' !== typeof a.a.tenantId && (a.h.tenantId = a.a.tenantId)
      a.ib(c)
      a.O = d || null
      var f = n.document
      a.C
        ? a.C.then(function () {
            'complete' == f.readyState
              ? On(a, b)
              : oe(window, 'load', function () {
                  On(a, b)
                })
          })
        : 'complete' == f.readyState
          ? On(a, b)
          : oe(window, 'load', function () {
              On(a, b)
            })
    }
    function On(a, b) {
      var c = vf(b, 'Could not find the FirebaseUI widget element on the page.')
      c.setAttribute('lang', 'cs'.replace(/_/g, '-'))
      if (Ln) {
        var d = Ln
        Z(d)
        Ch(X(d)) &&
          wg(
            'UI Widget is already rendered on the page and is pending some user interaction. Only one widget instance can be rendered per page. The previous instance has been automatically reset.'
          )
        Ln.reset()
      }
      Ln = a
      a.T = c
      Pn(a, c)
      if (mh(new nh()) && mh(new oh())) {
        b = vf(b, 'Could not find the FirebaseUI widget element on the page.')
        c = wf()
        d = Mh(W(a).a, 'queryParameterForSignInSuccessUrl')
        c = (c = tb(c, d)) ? zc(Bc(c)).toString() : null
        a: {
          d = wf()
          var e = Ei(W(a))
          d = tb(d, e) || ''
          for (f in Qi)
            if (Qi[f].toLowerCase() == d.toLowerCase()) {
              var f = Qi[f]
              break a
            }
          f = 'callback'
        }
        switch (f) {
          case 'callback':
            c && ((f = X(a)), Ah(vh, c, f))
            a.nb() ? L('callback', a, b) : S(a, b, zn(a))
            break
          case 'resetPassword':
            L('passwordReset', a, b, fm(), gm())
            break
          case 'recoverEmail':
            L('emailChangeRevocation', a, b, fm())
            break
          case 'revertSecondFactorAddition':
            L('revertSecondFactorAddition', a, b, fm())
            break
          case 'verifyEmail':
            L('emailVerification', a, b, fm(), gm())
            break
          case 'verifyAndChangeEmail':
            L('verifyAndChangeEmail', a, b, fm(), gm())
            break
          case 'signIn':
            L('emailLinkSignInCallback', a, b, wf())
            Qn()
            break
          case 'select':
            c && ((f = X(a)), Ah(vh, c, f))
            S(a, b)
            break
          default:
            throw Error('Unhandled widget operation.')
        }
        b = W(a)
        ;(b = Oi(b).uiShown || null) && b()
      } else
        (b = vf(
          b,
          'Could not find the FirebaseUI widget element on the page.'
        )),
          (f = new Ml(
            C(
              'V\u00e1\u0161 prohl\u00ed\u017ee\u010d nepodporuje webov\u00e9 \u00falo\u017ei\u0161t\u011b. Zkuste pou\u017e\u00edt jin\u00fd prohl\u00ed\u017ee\u010d.'
            ).toString()
          )),
          f.render(b),
          Y(a, f)
      b = a.g && 'blank' == a.g.Ga && Ji(W(a))
      Fh(X(a)) && !b && ((b = Fh(X(a))), Jm(a, b.a), zh(uh, X(a)))
    }
    function Jn(a, b) {
      if (a.A) return b(Rn(a))
      V(a, function () {
        a.A = !1
      })
      if (gi(W(a))) {
        var c = new Ye(function (d) {
          V(
            a,
            a.a.onAuthStateChanged(function (e) {
              a.j = e
              a.A || ((a.A = !0), d(b(Rn(a))))
            })
          )
        })
        V(a, c)
        return c
      }
      a.A = !0
      return b(null)
    }
    function Rn(a) {
      Z(a)
      return gi(W(a)) && a.j && a.j.isAnonymous ? a.j : null
    }
    function V(a, b) {
      Z(a)
      if (b) {
        a.s.push(b)
        var c = function () {
          Qa(a.s, function (d) {
            return d == b
          })
        }
        'function' != typeof b && b.then(c, c)
      }
    }
    l.Db = function () {
      Z(this)
      this.Z = !0
    }
    function Sn(a) {
      Z(a)
      var b
      ;(b = a.Z) ||
        ((a = W(a)),
        (a = Ai(a, firebase.auth.GoogleAuthProvider.PROVIDER_ID)),
        (b = !(!a || 'select_account' !== a.prompt)))
      return b
    }
    function Pl(a) {
      'undefined' !== typeof a.a.languageCode &&
        a.Y &&
        ((a.Y = !1), (a.a.languageCode = a.u))
    }
    function Jm(a, b) {
      a.a.tenantId = b
      a.h.tenantId = b
    }
    l.reset = function () {
      Z(this)
      var a = this
      this.T && this.T.removeAttribute('lang')
      this.J && Ge(this.J)
      Pl(this)
      this.O = null
      Qn()
      zh(uh, X(this))
      Z(this)
      this.l.cancel()
      this.i = F({ user: null, credential: null })
      Ln == this && (Ln = null)
      this.T = null
      for (var b = 0; b < this.s.length; b++)
        if ('function' == typeof this.s[b]) this.s[b]()
        else this.s[b].cancel && this.s[b].cancel()
      this.s = []
      Dh(X(this))
      this.g && (this.g.m(), (this.g = null))
      this.L = null
      this.h &&
        (this.C = pm(this).then(
          function () {
            a.C = null
          },
          function () {
            a.C = null
          }
        ))
    }
    function Pn(a, b) {
      a.L = null
      a.J = new He(b)
      a.J.register()
      ne(a.J, 'pageEnter', function (c) {
        c = c && c.pageId
        if (a.L != c) {
          var d = W(a)
          ;(d = Oi(d).uiChanged || null) && d(a.L, c)
          a.L = c
        }
      })
    }
    l.ib = function (a) {
      Z(this)
      var b = this.ca,
        c
      for (c in a)
        try {
          Lh(b.a, c, a[c])
        } catch (d) {
          rg('Invalid config: "' + c + '"', void 0)
        }
      fc && Lh(b.a, 'popupMode', !1)
      Di(b)
      !this.da &&
        Ni(W(this)) &&
        (wg(
          'signInSuccess callback is deprecated. Please use signInSuccessWithAuthResult callback instead.'
        ),
        (this.da = !0))
    }
    function W(a) {
      Z(a)
      return a.ca
    }
    l.Wb = function () {
      Z(this)
      var a = W(this),
        b = Mh(a.a, 'widgetUrl')
      a = Ei(a)
      var c = b.search(sb)
      for (var d = 0, e, f = []; 0 <= (e = rb(b, d, a, c)); )
        f.push(b.substring(d, e)), (d = Math.min(b.indexOf('&', e) + 1 || c, c))
      f.push(b.substr(d))
      b = f.join('').replace(ub, '$1')
      c = '=' + encodeURIComponent('select')
      ;(a += c)
        ? ((c = b.indexOf('#')),
          0 > c && (c = b.length),
          (d = b.indexOf('?')),
          0 > d || d > c ? ((d = c), (e = '')) : (e = b.substring(d + 1, c)),
          (b = [b.substr(0, d), e, b.substr(c)]),
          (c = b[1]),
          (b[1] = a ? (c ? c + '&' + a : a) : c),
          (c = b[0] + (b[1] ? '?' + b[1] : '') + b[2]))
        : (c = b)
      W(this).a.get('popupMode')
        ? ((a = (window.screen.availHeight - 600) / 2),
          (b = (window.screen.availWidth - 500) / 2),
          (c = c || 'about:blank'),
          (a = {
            width: 500,
            height: 600,
            top: 0 < a ? a : 0,
            left: 0 < b ? b : 0,
            location: !0,
            resizable: !0,
            statusbar: !0,
            toolbar: !1,
          }),
          (a.target = a.target || c.target || 'google_popup'),
          (a.width = a.width || 690),
          (a.height = a.height || 500),
          (a = sf(c, a)) && a.focus())
        : Nc(window.location, c)
    }
    function Z(a) {
      if (a.$) throw Error('AuthUI instance is deleted!')
    }
    l.Wa = function () {
      var a = this
      Z(this)
      return this.h.app.delete().then(function () {
        var b = Gn(X(a))
        delete Hn[b]
        a.reset()
        a.$ = !0
      })
    }
    function Bn(a) {
      Z(a)
      try {
        Tf(a.l, oi(W(a)), Sn(a)).then(function (b) {
          return a.g ? Yl(a, a.g, b) : !1
        })
      } catch (b) {}
    }
    l.Ib = function (a, b) {
      Z(this)
      var c = this,
        d = yf()
      if (!Gi(W(this)))
        return gf(
          Error(
            'Email link sign-in should be enabled to trigger email sending.'
          )
        )
      var e = Ii(W(this)),
        f = new Pb(e.url)
      Qb(f, d)
      b && b.a && (Jh(d, b, X(this)), Tb(f, b.a.providerId))
      Rb(f, Hi(W(this)))
      return Jn(this, function (g) {
        g && ((g = g.uid) ? f.a.a.set(x.Pa, g) : Nb(f.a.a, x.Pa))
        e.url = f.toString()
        return U(c).sendSignInLinkToEmail(a, e)
      }).then(
        function () {
          var g = X(c),
            h = {}
          h.email = a
          Ah(wh, ah(d, JSON.stringify(h)), g)
        },
        function (g) {
          zh(xh, X(c))
          zh(wh, X(c))
          throw g
        }
      )
    }
    function Km(a, b) {
      var c = Sb(new Pb(b))
      if (!c) return F(null)
      b = new Ye(function (d, e) {
        var f = jn(a).onAuthStateChanged(function (g) {
          f()
          g && g.isAnonymous && g.uid === c
            ? d(g)
            : g && g.isAnonymous && g.uid !== c
              ? e(Error('anonymous-user-mismatch'))
              : e(Error('anonymous-user-not-found'))
        })
        V(a, f)
      })
      V(a, b)
      return b
    }
    function Om(a, b, c, d, e) {
      Z(a)
      var f = e || null,
        g = firebase.auth.EmailAuthProvider.credentialWithLink(c, d)
      c = f
        ? U(a)
            .signInWithEmailLink(c, d)
            .then(function (h) {
              return h.user.linkWithCredential(f)
            })
            .then(function () {
              return pm(a)
            })
            .then(function () {
              return Kn(a, { code: 'auth/email-already-in-use' }, f)
            })
        : U(a)
            .fetchSignInMethodsForEmail(c)
            .then(function (h) {
              return h.length
                ? Kn(a, { code: 'auth/email-already-in-use' }, g)
                : b.linkWithCredential(g)
            })
      V(a, c)
      return c
    }
    function Pm(a, b, c, d) {
      Z(a)
      var e = d || null,
        f
      b = U(a)
        .signInWithEmailLink(b, c)
        .then(function (g) {
          f = {
            user: g.user,
            credential: null,
            operationType: g.operationType,
            additionalUserInfo: g.additionalUserInfo,
          }
          if (e)
            return g.user.linkWithCredential(e).then(function (h) {
              f = {
                user: h.user,
                credential: e,
                operationType: f.operationType,
                additionalUserInfo: h.additionalUserInfo,
              }
            })
        })
        .then(function () {
          pm(a)
        })
        .then(function () {
          return jn(a).updateCurrentUser(f.user)
        })
        .then(function () {
          f.user = jn(a).currentUser
          return f
        })
      V(a, b)
      return b
    }
    function Qn() {
      var a = wf()
      if (Mn(a)) {
        a = new Pb(a)
        for (var b in x) x.hasOwnProperty(b) && Nb(a.a.a, x[b])
        b = { state: 'signIn', mode: 'emailLink', operation: 'clear' }
        var c = n.document.title
        n.history &&
          n.history.replaceState &&
          n.history.replaceState(b, c, a.toString())
      }
    }
    l.bc = function (a, b) {
      Z(this)
      var c = this
      return U(this)
        .signInWithEmailAndPassword(a, b)
        .then(function (d) {
          return Jn(c, function (e) {
            return e
              ? pm(c).then(function () {
                  return Kn(
                    c,
                    { code: 'auth/email-already-in-use' },
                    firebase.auth.EmailAuthProvider.credential(a, b)
                  )
                })
              : d
          })
        })
    }
    l.Yb = function (a, b) {
      Z(this)
      var c = this
      return Jn(this, function (d) {
        if (d) {
          var e = firebase.auth.EmailAuthProvider.credential(a, b)
          return d.linkWithCredential(e)
        }
        return U(c).createUserWithEmailAndPassword(a, b)
      })
    }
    l.ac = function (a) {
      Z(this)
      var b = this
      return Jn(this, function (c) {
        return c
          ? c.linkWithCredential(a).then(
              function (d) {
                return d
              },
              function (d) {
                if (
                  d &&
                  'auth/email-already-in-use' == d.code &&
                  d.email &&
                  d.credential
                )
                  throw d
                return Kn(b, d, a)
              }
            )
          : U(b).signInWithCredential(a)
      })
    }
    function Wl(a, b) {
      Z(a)
      return Jn(a, function (c) {
        return c && !Ch(X(a))
          ? c.linkWithPopup(b).then(
              function (d) {
                return d
              },
              function (d) {
                if (
                  d &&
                  'auth/email-already-in-use' == d.code &&
                  d.email &&
                  d.credential
                )
                  throw d
                return Kn(a, d)
              }
            )
          : U(a).signInWithPopup(b)
      })
    }
    l.dc = function (a) {
      Z(this)
      var b = this,
        c = this.i
      this.i = null
      return Jn(this, function (d) {
        return d && !Ch(X(b))
          ? d.linkWithRedirect(a)
          : U(b).signInWithRedirect(a)
      }).then(
        function () {},
        function (d) {
          b.i = c
          throw d
        }
      )
    }
    l.cc = function (a, b) {
      Z(this)
      var c = this
      return Jn(this, function (d) {
        return d
          ? d.linkWithPhoneNumber(a, b).then(function (e) {
              return new Vf(e, function (f) {
                if ('auth/credential-already-in-use' == f.code) return Kn(c, f)
                throw f
              })
            })
          : jn(c)
              .signInWithPhoneNumber(a, b)
              .then(function (e) {
                return new Vf(e)
              })
      })
    }
    l.$b = function () {
      Z(this)
      return jn(this).signInAnonymously()
    }
    function Rl(a, b) {
      Z(a)
      return Jn(a, function (c) {
        if (a.j && !a.j.isAnonymous && gi(W(a)) && !U(a).currentUser)
          return pm(a).then(function () {
            'password' == b.credential.providerId && (b.credential = null)
            return b
          })
        if (c)
          return pm(a)
            .then(function () {
              return c.linkWithCredential(b.credential)
            })
            .then(
              function (d) {
                b.user = d.user
                b.credential = d.credential
                b.operationType = d.operationType
                b.additionalUserInfo = d.additionalUserInfo
                return b
              },
              function (d) {
                if (
                  d &&
                  'auth/email-already-in-use' == d.code &&
                  d.email &&
                  d.credential
                )
                  throw d
                return Kn(a, d, b.credential)
              }
            )
        if (!b.user)
          throw Error(
            'Internal error: An incompatible or outdated version of "firebase.js" may be used.'
          )
        return pm(a)
          .then(function () {
            return jn(a).updateCurrentUser(b.user)
          })
          .then(function () {
            b.user = jn(a).currentUser
            b.operationType = 'signIn'
            b.credential &&
              b.credential.providerId &&
              'password' == b.credential.providerId &&
              (b.credential = null)
            return b
          })
      })
    }
    l.Xb = function (a, b) {
      Z(this)
      return U(this).signInWithEmailAndPassword(a, b)
    }
    function pm(a) {
      Z(a)
      return U(a).signOut()
    }
    function Kn(a, b, c) {
      Z(a)
      if (
        b &&
        b.code &&
        ('auth/email-already-in-use' == b.code ||
          'auth/credential-already-in-use' == b.code)
      ) {
        var d = hi(W(a))
        return F()
          .then(function () {
            return d(
              new Qd(
                'anonymous-upgrade-merge-conflict',
                null,
                c || b.credential
              )
            )
          })
          .then(function () {
            a.g && (a.g.m(), (a.g = null))
            throw b
          })
      }
      return gf(b)
    }
    function Tn(a, b, c, d) {
      P.call(this, ll, void 0, d, 'providerMatchByEmail', { F: b, D: c })
      this.i = a
    }
    m(Tn, P)
    Tn.prototype.v = function () {
      this.u(this.i)
      this.w(this.i)
      this.l().focus()
      Em(this.l(), (this.l().value || '').length)
      P.prototype.v.call(this)
    }
    Tn.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    u(Tn.prototype, { l: vl, H: wl, u: xl, B: yl, j: zl, M: ml, w: ol })
    function Un(a, b, c, d, e) {
      P.call(this, kl, { ec: b }, e, 'selectTenant', { F: c, D: d })
      this.i = a
    }
    m(Un, P)
    Un.prototype.v = function () {
      Vn(this, this.i)
      P.prototype.v.call(this)
    }
    Un.prototype.o = function () {
      this.i = null
      P.prototype.o.call(this)
    }
    function Vn(a, b) {
      function c(h) {
        b(h)
      }
      for (
        var d = a.g
            ? Tc('firebaseui-id-tenant-selection-button', a.g || a.s.a)
            : [],
          e = 0;
        e < d.length;
        e++
      ) {
        var f = d[e],
          g = ln(f, 'tenantId')
        O(a, f, za(c, g))
      }
    }
    function Wn(a) {
      P.call(this, Fk, void 0, a, 'spinner')
    }
    m(Wn, P)
    function Xn(a) {
      this.a = new Kh()
      G(this.a, 'authDomain')
      G(this.a, 'displayMode', Yn)
      G(this.a, 'tenants')
      G(this.a, 'callbacks')
      G(this.a, 'tosUrl')
      G(this.a, 'privacyPolicyUrl')
      for (var b in a)
        if (a.hasOwnProperty(b))
          try {
            Lh(this.a, b, a[b])
          } catch (c) {
            rg('Invalid config: "' + b + '"', void 0)
          }
    }
    function Zn(a) {
      a = a.a.get('displayMode')
      for (var b in $n) if ($n[b] === a) return $n[b]
      return Yn
    }
    function ao(a) {
      return a.a.get('callbacks') || {}
    }
    function bo(a) {
      var b = a.a.get('tosUrl') || null
      a = a.a.get('privacyPolicyUrl') || null
      b &&
        !a &&
        wg('Privacy Policy URL is missing, the link will not be displayed.')
      if (b && a) {
        if ('function' === typeof b) return b
        if ('string' === typeof b)
          return function () {
            uf(b)
          }
      }
      return null
    }
    function co(a) {
      var b = a.a.get('tosUrl') || null,
        c = a.a.get('privacyPolicyUrl') || null
      c &&
        !b &&
        wg('Terms of Service URL is missing, the link will not be displayed.')
      if (b && c) {
        if ('function' === typeof c) return c
        if ('string' === typeof c)
          return function () {
            uf(c)
          }
      }
      return null
    }
    function eo(a, b) {
      a = a.a.get('tenants')
      if (!a || (!a.hasOwnProperty(b) && !a.hasOwnProperty(fo)))
        throw Error('Invalid tenant configuration!')
    }
    function go(a, b, c) {
      a = a.a.get('tenants')
      if (!a) throw Error('Invalid tenant configuration!')
      var d = []
      a = a[b] || a[fo]
      if (!a)
        return (
          rg(
            'Invalid tenant configuration: ' + (b + ' is not configured!'),
            void 0
          ),
          d
        )
      b = a.signInOptions
      if (!b)
        throw Error('Invalid tenant configuration: signInOptions are invalid!')
      b.forEach(function (e) {
        if ('string' === typeof e) d.push(e)
        else if ('string' === typeof e.provider) {
          var f = e.hd
          f && c
            ? (f instanceof RegExp
                ? f
                : new RegExp('@' + f.replace('.', '\\.') + '$')
              ).test(c) && d.push(e.provider)
            : d.push(e.provider)
        } else
          (e =
            'Invalid tenant configuration: signInOption ' +
            (JSON.stringify(e) + ' is invalid!')),
            rg(e, void 0)
      })
      return d
    }
    function ho(a, b, c) {
      a = io(a, b)
      ;(b = a.signInOptions) &&
        c &&
        ((b = b.filter(function (d) {
          return 'string' === typeof d ? c.includes(d) : c.includes(d.provider)
        })),
        (a.signInOptions = b))
      return a
    }
    function io(a, b) {
      var c = jo
      var d = void 0 === d ? {} : d
      eo(a, b)
      a = a.a.get('tenants')
      return zf(a[b] || a[fo], c, d)
    }
    var jo = [
        'immediateFederatedRedirect',
        'privacyPolicyUrl',
        'signInFlow',
        'signInOptions',
        'tosUrl',
      ],
      Yn = 'optionFirst',
      $n = { pc: Yn, oc: 'identifierFirst' },
      fo = '*'
    function ko(a, b) {
      var c = this
      this.s = vf(a)
      this.a = {}
      Object.keys(b).forEach(function (d) {
        c.a[d] = new Xn(b[d])
      })
      this.ob = this.g = this.A = this.h = this.i = this.j = null
      Object.defineProperty(this, 'languageCode', {
        get: function () {
          return this.ob
        },
        set: function (d) {
          this.ob = d || null
        },
        enumerable: !1,
      })
    }
    l = ko.prototype
    l.Ub = function (a, b) {
      var c = this
      lo(this)
      var d = a.apiKey
      return new Ye(function (e, f) {
        if (c.a.hasOwnProperty(d)) {
          var g = ao(c.a[d]).selectTenantUiHidden || null
          if (Zn(c.a[d]) === Yn) {
            var h = []
            b.forEach(function (t) {
              t = t || '_'
              var J = c.a[d].a.get('tenants')
              if (!J) throw Error('Invalid tenant configuration!')
              ;(J = J[t] || J[fo])
                ? (t = {
                    tenantId: '_' !== t ? t : null,
                    V: J.fullLabel || null,
                    displayName: J.displayName,
                    za: J.iconUrl,
                    ta: J.buttonColor,
                  })
                : (rg(
                    'Invalid tenant configuration: ' +
                      (t + ' is not configured!'),
                    void 0
                  ),
                  (t = null))
              t && h.push(t)
            })
            var k = function (t) {
              t = { tenantId: t, providerIds: go(c.a[d], t || '_') }
              e(t)
            }
            if (1 === h.length) {
              k(h[0].tenantId)
              return
            }
            c.g = new Un(
              function (t) {
                lo(c)
                g && g()
                k(t)
              },
              h,
              bo(c.a[d]),
              co(c.a[d])
            )
          } else
            c.g = new Tn(
              function () {
                var t = c.g.j()
                if (t) {
                  for (var J = 0; J < b.length; J++) {
                    var Ca = go(c.a[d], b[J] || '_', t)
                    if (0 !== Ca.length) {
                      t = { tenantId: b[J], providerIds: Ca, email: t }
                      lo(c)
                      g && g()
                      e(t)
                      return
                    }
                  }
                  c.g.a(Pd({ code: 'no-matching-tenant-for-email' }).toString())
                }
              },
              bo(c.a[d]),
              co(c.a[d])
            )
          c.g.render(c.s)
          ;(f = ao(c.a[d]).selectTenantUiShown || null) && f()
        } else {
          var p = Error('Invalid project configuration: API key is invalid!')
          p.code = 'invalid-configuration'
          c.pb(p)
          f(p)
        }
      })
    }
    l.Pb = function (a, b) {
      if (!this.a.hasOwnProperty(a))
        throw Error('Invalid project configuration: API key is invalid!')
      var c = b || void 0
      eo(this.a[a], b || '_')
      try {
        this.i = firebase.app(c).auth()
      } catch (e) {
        var d = this.a[a].a.get('authDomain')
        if (!d)
          throw Error('Invalid project configuration: authDomain is required!')
        a = firebase.initializeApp({ apiKey: a, authDomain: d }, c)
        a.auth().tenantId = b
        this.i = a.auth()
      }
      return this.i
    }
    l.Zb = function (a, b) {
      var c = this
      return new Ye(function (d, e) {
        function f(J, Ca) {
          c.j = new Fn(a)
          Nn(c.j, c.s, J, Ca)
        }
        var g = a.app.options.apiKey
        c.a.hasOwnProperty(g) ||
          e(Error('Invalid project configuration: API key is invalid!'))
        var h = ho(c.a[g], a.tenantId || '_', b && b.providerIds)
        lo(c)
        e = {
          signInSuccessWithAuthResult: function (J) {
            d(J)
            return !1
          },
        }
        var k = ao(c.a[g]).signInUiShown || null,
          p = !1
        e.uiChanged = function (J, Ca) {
          null === J && 'callback' === Ca
            ? ((J = Vc('firebaseui-id-page-callback', c.s)) && Qj(J),
              (c.h = new Wn()),
              c.h.render(c.s))
            : p ||
              (null === J && 'spinner' === Ca) ||
              'blank' === Ca ||
              (c.h && (c.h.m(), (c.h = null)), (p = !0), k && k(a.tenantId))
        }
        h.callbacks = e
        h.credentialHelper = 'none'
        var t
        b && b.email && (t = { emailHint: b.email })
        c.j
          ? c.j.Wa().then(function () {
              f(h, t)
            })
          : f(h, t)
      })
    }
    l.reset = function () {
      var a = this
      return F()
        .then(function () {
          a.j && a.j.Wa()
        })
        .then(function () {
          a.j = null
          lo(a)
        })
    }
    l.Vb = function () {
      var a = this
      this.h ||
        this.A ||
        (this.A = window.setTimeout(function () {
          lo(a)
          a.h = new Wn()
          a.g = a.h
          a.h.render(a.s)
          a.A = null
        }, 500))
    }
    l.mb = function () {
      window.clearTimeout(this.A)
      this.A = null
      this.h && (this.h.m(), (this.h = null))
    }
    l.Bb = function () {
      lo(this)
      this.g = new Hl()
      this.g.render(this.s)
      return F()
    }
    function lo(a) {
      a.j && a.j.reset()
      a.mb()
      a.g && a.g.m()
    }
    l.pb = function (a) {
      var b = this,
        c = Pd({ code: a.code }).toString() || a.message
      lo(this)
      var d
      a.retry &&
        'function' === typeof a.retry &&
        (d = function () {
          b.reset()
          a.retry()
        })
      this.g = new Ll(c, d)
      this.g.render(this.s)
    }
    l.Rb = function (a) {
      var b = this
      return F()
        .then(function () {
          var c = b.i && b.i.app.options.apiKey
          if (!b.a.hasOwnProperty(c))
            throw Error('Invalid project configuration: API key is invalid!')
          eo(b.a[c], a.tenantId || '_')
          if (!b.i.currentUser || b.i.currentUser.uid !== a.uid)
            throw Error(
              'The user being processed does not match the signed in user!'
            )
          return (c = ao(b.a[c]).beforeSignInSuccess || null) ? c(a) : a
        })
        .then(function (c) {
          if (c.uid !== a.uid)
            throw Error('User with mismatching UID returned.')
          return c
        })
    }
    v('firebaseui.auth.FirebaseUiHandler', ko)
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.selectTenant',
      ko.prototype.Ub
    )
    v('firebaseui.auth.FirebaseUiHandler.prototype.getAuth', ko.prototype.Pb)
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.startSignIn',
      ko.prototype.Zb
    )
    v('firebaseui.auth.FirebaseUiHandler.prototype.reset', ko.prototype.reset)
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.showProgressBar',
      ko.prototype.Vb
    )
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.hideProgressBar',
      ko.prototype.mb
    )
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.completeSignOut',
      ko.prototype.Bb
    )
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.handleError',
      ko.prototype.pb
    )
    v(
      'firebaseui.auth.FirebaseUiHandler.prototype.processUser',
      ko.prototype.Rb
    )
    v('firebaseui.auth.AuthUI', Fn)
    v('firebaseui.auth.AuthUI.getInstance', function (a) {
      a = Gn(a)
      return Hn[a] ? Hn[a] : null
    })
    v('firebaseui.auth.AuthUI.prototype.disableAutoSignIn', Fn.prototype.Db)
    v('firebaseui.auth.AuthUI.prototype.start', Fn.prototype.start)
    v('firebaseui.auth.AuthUI.prototype.setConfig', Fn.prototype.ib)
    v('firebaseui.auth.AuthUI.prototype.signIn', Fn.prototype.Wb)
    v('firebaseui.auth.AuthUI.prototype.reset', Fn.prototype.reset)
    v('firebaseui.auth.AuthUI.prototype.delete', Fn.prototype.Wa)
    v('firebaseui.auth.AuthUI.prototype.isPendingRedirect', Fn.prototype.nb)
    v('firebaseui.auth.AuthUIError', Qd)
    v('firebaseui.auth.AuthUIError.prototype.toJSON', Qd.prototype.toJSON)
    v('firebaseui.auth.CredentialHelper.GOOGLE_YOLO', qi)
    v('firebaseui.auth.CredentialHelper.NONE', fi)
    v('firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID', 'anonymous')
    Ye.prototype['catch'] = Ye.prototype.Ca
    Ye.prototype['finally'] = Ye.prototype.fc
  }).apply(
    typeof global !== 'undefined'
      ? global
      : typeof self !== 'undefined'
        ? self
        : window
  )
}).apply(
  typeof global !== 'undefined'
    ? global
    : typeof self !== 'undefined'
      ? self
      : window
)
if (typeof window !== 'undefined') {
  window.dialogPolyfill = dialogPolyfill
}
var auth = firebaseui.auth
export { auth }
