<script setup lang="ts">
const props = defineProps<{
  type?: 'primary' | 'secondary' | 'accent' | 'remove' | 'text'
  outline?: boolean
  disabled?: boolean
  size?: 'sm' | 'md' | 'lg'
  class?: string
  loading?: boolean
  isSubmit?: boolean
}>()

const base =
  'whitespace-nowrap flex justify-center items-center uppercase w-max shadow-2xl '
const text = 'hover:underline !bg-none text-base uppercase !p-0 '
const primary = 'bg-black text-white hover:text-primary font-medium '
const secondary =
  'bg-white !text-black hover:bg-secondary/80 active:bg-secondary/60 font-medium border-black border border-[1px] '
const accent =
  'hover:bg-neutral-100 active:bg-neutral-200 font-medium dark:hover:bg-neutral-800 '
const remove = 'bg-opacity-100 hover:bg-red '

const types = {
  primary: primary,
  text: text,
  secondary: secondary,
  accent: accent,
  remove: remove,
}

const disabledClasses = computed(() => {
  if (props.disabled) {
    let base = 'pointer-events-none'
    if (props.type === 'text') {
      base += ' text-gray-400'
    } else {
      base += ' bg-gray-600'
    }
    return base
  } else {
    return ''
  }
})

const sizes = {
  sm: 'px-2 py-1 text-sm gap-1 ',
  md: 'px-5 py-2 lg:text-base gap-2 ',
  lg: 'px-10 py-4 text-lg gap-3 ',
}

const cls = computed(
  () => `${base}${types[props.type || 'primary']}${sizes[props.size || 'md']}`
)
</script>

<template>
  <button
    :class="[cn(cls, props.class), disabledClasses]"
    :type="props.isSubmit ? 'submit' : 'button'"
    :disabled="props.disabled || props.loading"
  >
    <Icon
      v-if="props.loading"
      name="ri:loader-4-fill"
      class="animate-spin"
      size="25"
    />
    <slot v-if="!props.loading" />
  </button>
</template>
