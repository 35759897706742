export type Notification = {
  id?: string
  title: string
  description?: string
  time?: number
  color?: 'red' | 'orange' | '#FDBF03' | '#2c2'
}

export function useNotifications() {
  const notifications = useState<Notification[]>('notifications', () => [])

  const addInfoNotification = (description: string, title?: string) => {
    if (
      notifications.value.every(
        (existingNotification) =>
          existingNotification.description !== description
      )
    ) {
      addNotification({
        description,
        title,
        color: '#FDBF03',
      } as Notification)
    }
  }

  const addErrorNotification = (description: string, title?: string) => {
    if (
      notifications.value.every(
        (existingNotification) =>
          existingNotification.description !== description
      )
    ) {
      addNotification({
        description,
        title,
        color: 'red',
      } as Notification)
    }
  }

  const addSuccessNotification = (description: string, title?: string) => {
    addNotification({
      description,
      title,
      color: '#2c2',
      time: 4000,
    } as Notification)
  }

  const addNotification = (notification: Notification) => {
    notification.id =
      Date.now().toString(36) + Math.random().toString(36).substring(2)
    notifications.value.push(notification)
  }

  const removeNotification = (id: string | undefined) => {
    notifications.value = notifications.value.filter(
      (n: Notification) => n.id !== id
    )
  }

  return {
    notifications,
    removeNotification,
    addErrorNotification,
    addInfoNotification,
    addNotification,
    addSuccessNotification,
  }
}
