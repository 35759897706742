import Vue3Sanitize from 'vue-3-sanitize'
export default defineNuxtPlugin({
  name: 'vue-3-sanitize',
  async setup(nuxtApp) {
    const Options = {
      allowedAttributes: false,
      allowedTags: Vue3Sanitize.defaults.allowedTags.concat(['style', 'img']),
      allowVulnerableTags: true,
    }
    nuxtApp.vueApp.use(Vue3Sanitize, Options)
  },
})
