export type ApiError = {
  details: string
  message?: string
  status?: number
}

export function useErrors() {
  const { addErrorNotification } = useNotifications()
  const { t, te } = useI18n()

  /**
   * @param error = error object
   * @param langPath = path to translation
   * @param fallbackPath = fallback if no translation is found
   * @param regex = regex to match error message in needed
   * @param regexError = error to show in case of regex match
   */
  function apiError(
    error?: ApiError,
    langPath?: string,
    fallbackPath?: string,
    regex?: RegExp,
    regexError?: string
  ) {
    // App is overloaded
    if (error?.status === 429) {
      addErrorNotification(t('error.429.text'))
      return
    }

    if (error?.details?.endsWith('.')) {
      error.details = error.details.slice(0, -1)
    }
    if (error && error.details && te(langPath + error.details)) {
      addErrorNotification(t(langPath + error.details))
    } else if (regex && error?.details?.match(regex)) {
      addErrorNotification(t(regexError ?? 'error.somethingWentWrong'))
    } else {
      addErrorNotification(t(fallbackPath ?? 'error.somethingWentWrong'))
    }
  }
  return {
    apiError,
  }
}
