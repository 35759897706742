
// @ts-nocheck


export const localeCodes =  [
  "cs"
]

export const localeLoaders = {
  "cs": [{ key: "../lang/translations/cs.json", load: () => import("../lang/translations/cs.json" /* webpackChunkName: "locale__builds_dotykacka_designex_group_dg_client_zone_lang_translations_cs_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../lang/i18n.config.ts?hash=980b4b1d&config=1" /* webpackChunkName: "__lang_i18n_config_ts_980b4b1d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./lang/i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "name": "Čeština",
      "files": [
        "lang/translations/cs.json"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./lang/translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "admin": {
      "cs": "/admin",
      "en": "/admin"
    },
    "admin/news/index": {
      "cs": "/admin/novinky",
      "en": "/admin/news"
    },
    "admin/news/create": {
      "cs": "/admin/novinky/pridat",
      "en": "/admin/news/create"
    },
    "admin/news/[id]": {
      "cs": "/admin/novinky/[id]",
      "en": "/admin/news/[id]"
    },
    "admin/incomingTransactions/index": {
      "cs": "/admin/prichozi-transakce",
      "en": "/admin/incoming-transactions"
    },
    "admin/incomingTransactions/[id]": {
      "cs": "/admin/prichozi-transakce/[id]",
      "en": "/admin/incoming-transactions/[id]"
    },
    "admin/users/index": {
      "cs": "/admin/uzivatele",
      "en": "/admin/users"
    },
    "admin/clouds": {
      "cs": "/admin/cloudy",
      "en": "/admin/clouds"
    },
    "admin/users/[id]": {
      "cs": "/admin/uzivatele/[id]",
      "en": "/admin/users/[id]"
    },
    "admin/flyer": {
      "cs": "/admin/vyhody-karty",
      "en": "/admin/flyer"
    },
    "login": {
      "cs": "/prihlaseni",
      "en": "/login"
    },
    "topUp": {
      "cs": "/dobit-kartu",
      "en": "/topup"
    },
    "settings/index": {
      "cs": "/nastaveni-uctu",
      "en": "/settings"
    },
    "flyer": {
      "cs": "/vyhody-karty",
      "en": "/flyer"
    },
    "register": {
      "cs": "/registrace",
      "en": "/register"
    },
    "register/verification": {
      "cs": "/registrace/verifikace",
      "en": "/register/verification"
    },
    "news/index": {
      "cs": "/novinky",
      "en": "/news"
    },
    "news/[id]": {
      "cs": "/novinky/[id]",
      "en": "/news/[id]"
    },
    "user/profile/[profileId]": {
      "cs": "/ucet/profil/[profileId]",
      "en": "/user/profile/[profileId]"
    },
    "user/profile/create": {
      "cs": "/ucet/profil/vytvorit",
      "en": "/user/profile/create"
    },
    "transactionHistory": {
      "cs": "/historie-transakci",
      "en": "/transaction-history"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "Čeština",
    "files": [
      {
        "path": "lang/translations/cs.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
