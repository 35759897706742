import * as firebaseui from '~/firebaseui'
import { getAuth } from 'firebase/auth'
import type { FirebaseApp } from '@firebase/app'

export default defineNuxtPlugin({
  name: 'auth-ui',
  dependsOn: ['firestore-app'],
  async setup(nuxtApp) {
    const ui = new firebaseui.auth.AuthUI(
      getAuth(nuxtApp.$firebaseApp as FirebaseApp)
    )
    return {
      provide: {
        ui,
      },
    }
  },
})
