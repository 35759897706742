import QRCodeStyling from 'qr-code-styling'
import type { Options } from 'qr-code-styling'

export default defineNuxtPlugin({
  name: 'qrcode-styling',
  async setup() {
    return {
      provide: {
        newQrCodeStyling: (options: Partial<Options>): QRCodeStyling => {
          return new QRCodeStyling(options)
        },
      },
    }
  },
})
