import type {
  BaseApiPagedResponse,
  BaseApiPostResponse,
  BaseApiResponse,
} from '~/types/api'
import type { User, UserDetail } from '~/types/user'

export const usersRepository = () => {
  return {
    async getAll() {
      return callFunction<BaseApiPagedResponse<User>>('get-users')
    },
    async getSelf() {
      return callFunction<BaseApiResponse<UserDetail>>('get-self')
    },
    async registerUser(data: Partial<User>) {
      return callFunction<BaseApiPostResponse>('register-user', data)
    },
    async updateUser(data: Partial<User>) {
      return callFunction<BaseApiPostResponse>('update-user', data)
    },
  }
}
