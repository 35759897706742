import { getFunctions } from '@firebase/functions'
import type { FirebaseApp } from '@firebase/app'

export default defineNuxtPlugin({
  name: 'functions',
  dependsOn: ['auth-ui'],
  async setup(nuxtApp) {
    const functions = getFunctions(
      nuxtApp.$firebaseApp as FirebaseApp,
      'europe-west3'
    )
    return {
      provide: {
        functions,
      },
    }
  },
})
