<script setup lang="ts">
const _props = defineProps<{
  name: string
  forceTooltip?: boolean
  size: string
  classes?: string
  translatedTooltip?: string
  background?: boolean
  bgColor?: string
  warn?: boolean
}>()

onDeactivated(() => {})
</script>

<template>
  <div>
    <UiTooltip
      class="normal-case"
      :class="{ 'hidden md:block': !forceTooltip }"
      :label="translatedTooltip ?? ''"
    >
      <div
        class="relative !z-10"
        :class="[
          {
            'rounded-full bg-gray-100 p-2 transition-all duration-200 hover:bg-gray-300':
              background,
          },
          bgColor ?? '',
        ]"
      >
        <Icon
          class="cursor-pointer"
          :class="classes"
          :size="size"
          :name="name"
        />
        <Icon
          v-if="warn"
          class="absolute bottom-0 right-0 !z-10 text-primary"
          name="oui:dot"
          :size="15"
        />
      </div>
    </UiTooltip>
  </div>
</template>

<style scoped></style>
