<script lang="ts" setup>
const error = useError()
const { t, te } = useI18n()
const { updateUser } = useUser()
const loading = ref(false)

useServerSeoMeta({
  title: t('pages.errorPage.meta.title'),
  description: t('pages.errorPage.meta.description'),
})

const errorTitle = computed(() => {
  if (te('error.' + error.value.statusCode + '.title')) {
    return t('error.' + error.value.statusCode.toString() + '.title')
  }
  return t('error.somethingWentWrong')
})

const errorMessage = computed(() => {
  if (te('error.' + error.value.statusCode + '.text')) {
    return t('error.' + error.value.statusCode + '.text')
  }
  return ''
})

async function handleError() {
  loading.value = true
  updateUser()
    .catch(() => {
      clearError({
        redirect: '/',
      })
    })
    .then(() => {
      clearError({
        redirect: '/',
      })
    })
}
</script>

<template>
  <UiHeader />
  <div class="h-fit overflow-hidden !bg-white p-5 lg:p-10">
    <div class="mx-auto w-fit pt-10 lg:pt-20">
      <h1 class="text-center text-4xl font-extralight md:text-5xl">
        {{ error.statusCode }} - {{ errorTitle }}
      </h1>
      <div class="my-8">
        <p class="max-w-6xl text-center text-gray-500 md:text-lg">
          {{ errorMessage }}
        </p>
      </div>
      <UiButton
        class="mx-auto my-20 text-lg"
        :loading="loading"
        @click="handleError"
      >
        {{ $t('error.backToHomepage') }}
      </UiButton>
    </div>
  </div>
</template>
