import 'firebaseui/dist/firebaseui.css'
import { initializeApp } from 'firebase/app'
import type { FirebaseOptions } from '@firebase/app-types'

export default defineNuxtPlugin({
  name: 'firestore-app',
  async setup(_) {
    const config = useRuntimeConfig()
    const firebaseApp = initializeApp({
      apiKey: config.public.firebaseApiKey,
      authDomain: config.public.firebaseAuthDomain,
      projectId: config.public.firebaseProjectId,
      storageBucket: config.public.firebaseStorageBucket,
      messagingSenderId: config.public.firebaseMessageSender,
      appId: config.public.firebaseAppId,
    } as FirebaseOptions)
    return {
      provide: {
        firebaseApp,
      },
    }
  },
})
